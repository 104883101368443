import http from '@/api/http';
import {AccountType} from '@/api/types';

export default async (username: string): Promise<void> => {
    const endpoint = '/account';
    await http.delete(endpoint, {
        data: {
            username
        }
    });
};
