import graphClient from '@/api/graph';
import getStudentInfoById from '@/api/graphql/queries/getStudentInfoById';
import {StudentEnrollment, StudentInfo} from '@/api/types';
import {simulateCompletedEnrollments} from '@/utils/enrollmentUtils';
import {
    formatScribedCourse,
    isAnyWritingIntensive, isRange,
    normalizeDegreeBlockValue, parseAttributeValue,
    parseDegreeInfo, requiresAttribute, wildcardMatch
} from '@/degrees/audit/utils';
import {summarizeStats} from '@/utils/degreeUtils';
import {RequirementBlock} from '@/degrees/types';
import {getRequirementBlock, queryRequirementBlock} from '@/api/graphql/queries/getRequirementBlock';
import {
    AttributeCoursesMap,
    AuditedRequirementBlock,
    StudentInfoWithDegree
} from '@/degrees/audit/types';
import _ from 'lodash';
import auditRequirementBlock from '@/degrees/audit/auditRequirementBlock';
import {searchStudentInfo} from '@/api/graphql/queries/searchStudentInfo';
import {BlockType} from '@/degrees/common';
import {parseLatestAcademicProgramFromStudentInfo} from '@/api/transformers';
import getTransferCourses from '@/api/getTransferCourses';
import {
    CourseCoverage, CoverageInfo,
    findMinimumCoursesFromRules,
} from '@/degrees/audit/findMinimumCourses';
import {
    CourseExtractWithRequisites, getCoursesByScribedFormat,
    getCoursesWithAllRequisites, prefetchCourseCache
} from '@/api/graphql/queries/getCourses';
import wricCoursesByInstitution from '@/constants/wricCoursesByInstitution';
import requirementDesignationCourses from '@/constants/requirementDesignationCourses';
import {
    auditPrerequisiteChains,
    PathStep
} from '@/degrees/audit/auditPathRequisite';
import {ScribedCourse} from '@/degrees/rules';
import {RequirementDesignationName} from '@/constants/requirementDesignations';
import {parseMinCreditsHeaders} from '@/degrees/audit/parseMinCreditsHeaders';
import {calculateRemainingSemesters} from '@/degrees/audit/calculateRemainingSemesters';
import {serializeStudentMilestone} from '@/degrees/audit/milestoneUtils';
import {serializeStudentPlacement} from '@/degrees/audit/placementUtils';
import AuditTracker from '@/degrees/audit/AuditTracker';


export interface AuditStudentResponse {
    studentInfo: StudentInfoWithDegree;
    auditedBlock: AuditedRequirementBlock | null;
    errors: string[];
}
export async function auditStudent(studentId: string): Promise<AuditStudentResponse> {
    await prefetchCourseCache();

    const results = await graphClient.query({
        query: getStudentInfoById,
        variables: {
            studentId
        }
    });

    let errors: string[] = [];

    let studentInfo = results.data.items[0];

    if (!studentInfo) {
        console.warn('student was not found in ps_names. proceeding to search all tables anyways...');
        studentInfo = await searchStudentInfo(studentId);
    }

    if (studentInfo) {
        const simulatedInfo: StudentInfo = {
            ...studentInfo,
            enrollments: simulateCompletedEnrollments(studentInfo.enrollments),
        };

        const withDegreeInfo = parseDegreeInfo(simulatedInfo);
        const enrollmentSummary = summarizeStats(simulatedInfo.enrollments);
        console.log('simulatedInfo.enrollments: ', simulatedInfo.enrollments);
        console.log('enrollmentSummary: ', enrollmentSummary);

        const studentInfoWithDegree: StudentInfoWithDegree = {
            ...simulatedInfo,
            ...withDegreeInfo,
            ...enrollmentSummary
        };

        console.log('studentInfoWithDegree: ', studentInfoWithDegree);


        // === fetching transfer courses. this should mutate existing courses with additional transfer props if applicable ===
        const destinationInstitution = withDegreeInfo.institution;

        const sendingEnrollments: StudentEnrollment[] = simulatedInfo.enrollments.filter(o => {
            return (o.institution !== destinationInstitution);
        });
        const sendingCourseIds = sendingEnrollments.map(o => o.class.courseId);

        const transferredCourses = await getTransferCourses(sendingCourseIds, destinationInstitution);

        console.log('transferredCourses: ', transferredCourses);

        if (sendingEnrollments.length !== transferredCourses.length) {
            console.warn('sendingEnrollments.length !== transferredCourses.length');
        }

        // iterate through enrollments because getTransferCourses
        sendingEnrollments.forEach(o => {

        });

        transferredCourses.forEach(transferredCourse => {
            const enrollment = sendingEnrollments.find(o => {
                return o.class.courseId === transferredCourse.sendingCourseId;
            });
            if (!enrollment) {
                console.error('Unable to locate sending enrollment');
            } else {
                const t = {
                    courseId: transferredCourse.courseId,
                    subject: transferredCourse.subject,
                    courseNumber: transferredCourse.courseNumber,
                };
                if (enrollment.transferredAs) {
                    enrollment.transferredAs.push(t);
                } else {
                    enrollment.transferredAs = [ t ];
                }
            }
        });
        // ============

        console.log('auditStudent props: ', studentInfoWithDegree);

        const onlyEarnedCreditNoLabs = _.filter(simulatedInfo.enrollments, o => {
            return o.class.courseComponent !== 'LAB' &&
                o.earnCredit === 'Y';
        });
        console.log('onlyEarnedCreditNoLabs: ', onlyEarnedCreditNoLabs);

        let auditedDegreeBlock: AuditedRequirementBlock | null = null;

        if (studentInfoWithDegree.degree) {
            const degreeBlockQueryProps = {
                institution: studentInfoWithDegree.institution,
                blockType: 'DEGREE' as BlockType,
                blockValue: normalizeDegreeBlockValue(studentInfoWithDegree.degree)
            };
            console.log('degreeBlockQueryProps: ', degreeBlockQueryProps);

            let degreeBlock: RequirementBlock;

            if (studentInfoWithDegree.institution === 'MEC01') {
                console.log('fetching MEC01 path...');
                degreeBlock = await queryRequirementBlock({
                    ...degreeBlockQueryProps,
                    major1: studentInfoWithDegree.latestDeclaredPlan?.academicPlan
                });
                console.log('block: ', degreeBlock);
                if (!degreeBlock) {
                    console.log('MEC01 did not return, switching to regular path');
                    degreeBlock = await getRequirementBlock(degreeBlockQueryProps);
                }

            } else {
                degreeBlock = await getRequirementBlock(degreeBlockQueryProps);
            }



            console.log('auditStudent degree block: ', degreeBlock);

            if (!degreeBlock) {
                console.warn('Did not find degree block using institution from academic plan. Attempting to look up institution in latest academic program instead...');
                const program = parseLatestAcademicProgramFromStudentInfo(studentInfo);
                if (program) {
                    studentInfoWithDegree.institution = program.institution;
                }
                degreeBlock = await getRequirementBlock({
                    ...degreeBlockQueryProps,
                    institution: program?.institution || ''
                });
            }

            if (!degreeBlock) {
                throw new Error(`Unable to find degree block: ${degreeBlockQueryProps.blockValue} from ${degreeBlockQueryProps.institution}`);
            }

            const devBlock = _.cloneDeep(degreeBlock);
            // devBlock.parseTree.body_list = devBlock.parseTree.body_list.slice(7, 8);
            // devBlock.parseTree.body_list = [
            //     {"class_credit": {"label": {"label_str": "English Composition 1", "label_tag": "2"}, "conjunction": "OR", "course_list": {"list_type": "OR", "institution": "", "except_courses": [], "requirement_id": "", "include_courses": [], "scribed_courses": [ [ [ "ENGL", "110M", null ], [ "RCEC", "1000M", null ], [ "ENGL", "110", "(WITH ATTRIBUTE=RECR)" ], [ "ENGL", "110H", "(WITH ATTRIBUTE=RECR)" ], [ "ENGL", "110", "(WITH ATTRIBUTE=RECC)" ] ] ]}, "max_classes": 1, "max_credits": null, "min_classes": 1, "min_credits": null, "allow_classes": null, "allow_credits": null}}
            // ];
            // devBlock.parseTree.header_list = [];

            auditedDegreeBlock = await auditRequirementBlock(devBlock, {
                ...studentInfoWithDegree,
                enrollments: onlyEarnedCreditNoLabs
            });


            const missingClassCreditRules = auditedDegreeBlock.requiredClassCreditRules.filter(o => !o.completed);

            for (const rule of missingClassCreditRules) {
                const populatedAttributeCourses: AttributeCoursesMap = {};

                for (const courses of rule.rule.course_list.scribed_courses) {

                    const knownCourses: {
                        [key: string]: boolean
                    } = {};

                    for (let i = courses.length - 1; i >= 0; i--) {
                        const s: ScribedCourse = courses[i];

                        if (studentInfoWithDegree.institution === 'BKL01' && s[0] === 'CISC') {
                            s[0] = 'CISC.';
                        }

                        // prune requirement designation placeholders as they don't seem to actually exist not even on the college websites.
                        // e.g. RCEC 1000M (best guess is these are used as "course aliases")
                        if (RequirementDesignationName[s[0]]) {
                            courses.splice(i, 1);
                            continue;
                        }


                        // prune duplicate courses from requirement blocks (usually due to duplicate Hide tags
                        const courseName = formatScribedCourse(s);
                        if (knownCourses[courseName]) {
                            courses.splice(i, 1);
                            continue;

                        } else {
                            knownCourses[courseName] = true;
                        }

                        if (isRange(s[1])) {
                            console.log('fetching coursesByScribedFormat: ', formatScribedCourse(s));
                            const expandedRangeCourses = await getCoursesByScribedFormat([ formatScribedCourse(s) ], studentInfoWithDegree.institution);

                            console.log(`replacing range courses: ${s[1]}\n with: ${expandedRangeCourses}`);
                            const replaceWith: ScribedCourse[] = expandedRangeCourses.map(o => [ o.subject, o.courseNumber, null ]);
                            courses.splice(i, 1, ...replaceWith);
                        } else if (isAnyWritingIntensive(s)) {
                            courses.splice(i, 1);
                            populatedAttributeCourses.wric = wricCoursesByInstitution[studentInfoWithDegree.institution];
                        } else if (requiresAttribute(s)) {

                            const expression = s[2]!;
                            const value = parseAttributeValue(expression);

                            const designationCourses: string[] = requirementDesignationCourses[studentInfoWithDegree.institution]?.[value] || [];

                            if (s[0] === '@' && s[1] === '@') {
                                populatedAttributeCourses[value] = designationCourses;
                                courses.splice(i, 1);

                            } else if (s[0] !== '@' && s[1] === '@') {
                                populatedAttributeCourses[value] = designationCourses.filter(course => course.split(' ')[0] === s[0]);
                                courses.splice(i, 1);
                            } else if (s[0] !== '@' && s[1] !== '@') {
                                courses[i] = [ s[0], s[1], null ];
                            } else {
                                console.warn('scribedCourse requires attribute but expression is unhandled. scribedCourse: ', s);
                            }
                        }
                    }
                }

                rule.populatedAttributeCourses = populatedAttributeCourses;
            }

            const completedCourseNames = [];

            for (const enrollment of onlyEarnedCreditNoLabs) {
                const names = [ `${enrollment.class.subject} ${enrollment.class.courseNumber}` ];

                enrollment.transferredAs?.map(t => {
                    names.push(`${t.subject} ${t.courseNumber}`);
                });

                completedCourseNames.push(...names);
            }

            for (const milestone of studentInfoWithDegree.milestones) {
                completedCourseNames.push(serializeStudentMilestone(milestone));
            }

            for (const placement of studentInfoWithDegree.placements) {
                completedCourseNames.push(serializeStudentPlacement(placement));
            }

            console.log('completedCourseNames: ', completedCourseNames);


            const minCreditsHeaders = parseMinCreditsHeaders(auditedDegreeBlock.parseTree.header_list, studentInfoWithDegree);
            auditedDegreeBlock.minCreditsHeaders = minCreditsHeaders;




            const minimumCoursesFromRemainingRequirements: CoverageInfo = await findMinimumCoursesFromRules(missingClassCreditRules, destinationInstitution, completedCourseNames);
            console.log('minimumCoursesFromRemainingRequirements: ', minimumCoursesFromRemainingRequirements);

            const missingCourseNames = minimumCoursesFromRemainingRequirements.courses.map(o => o.course);
            console.log('missingCourseNames: ', missingCourseNames, 'destinationInstitution: ', destinationInstitution, 'completedCourseNames: ', completedCourseNames);

            const primaryRequisites: CourseExtractWithRequisites[] = await getCoursesWithAllRequisites(missingCourseNames, destinationInstitution, completedCourseNames);

            console.log('primaryRequisites: ', primaryRequisites);

            const courseCoverage: CourseCoverage[] = minimumCoursesFromRemainingRequirements.courses;
            console.log('courseCoverage: ', courseCoverage);

            let shortestPathTotalUnits = 0;

            let longestChainLength = 0;
            let longestChain: PathStep[] = [];


            const requisiteCoverageMap: {
                [course: string]: CourseCoverage
            } = {};


            for (const coverage of courseCoverage) {
                let extract: CourseExtractWithRequisites | null;
                extract = primaryRequisites.find(o => {
                    return wildcardMatch(`${o.subject} ${o.courseNumber}`, coverage.course);
                }) || null;

                if (!extract) {
                    console.warn('coverage extract not found in initial primaryRequisites fetch. trying one more time...');
                    [ extract ] = await getCoursesWithAllRequisites([ coverage.course ], destinationInstitution, completedCourseNames);
                    console.warn('extract: ', extract);
                }


                if (extract) {
                    coverage.courseId = extract.courseId || '';
                    coverage.units = extract.units || 0;

                    coverage.requisites = extract.requisites;

                    coverage.chainInfo = auditPrerequisiteChains(coverage as any);
                    if (longestChainLength < coverage.chainInfo.longestLength) {
                        longestChainLength = coverage.chainInfo.longestLength;
                        longestChain = coverage.chainInfo.chains.find(o => o.length === longestChainLength)!;
                    }

                    requisiteCoverageMap[coverage.course] = coverage;

                    // coverage.totalUnits = coverage.chainInfo.totalUnits;

                } else {
                    coverage.courseId = '';
                    coverage.units = 0;
                    coverage.requisites = [];
                }

                // shortestPathTotalUnits += coverage.totalUnits || 0;
            }


            courseCoverage.forEach((coverage: CourseCoverage) => {
                try {
                    coverage.chainInfo?.chains.forEach((chain: PathStep[]) => {
                        chain.forEach((step: PathStep, index: number) => {
                            try {
                                if (step.course === coverage.course) return;

                                const requiredFor = chain[index - 1];

                                if (!requisiteCoverageMap[step.course]) {
                                    requisiteCoverageMap[step.course] = {
                                        course: step.course,
                                        units: step.units,
                                        satisfies: [],
                                        chainInfo: {
                                            chains: [ chain ],
                                            longestLength: 0,
                                            allCourses: [],
                                            totalUnits: 0,
                                        },
                                        requiredFor: requiredFor.course,
                                    };
                                } else {
                                    requisiteCoverageMap[step.course].chainInfo!.chains.push(chain);
                                    requisiteCoverageMap[step.course].requiredFor = requiredFor?.course;
                                }
                            } catch (e) {
                                console.error('step.course: ', step.course);
                                console.error('coverage.course: ', coverage.course);
                                console.error('coverage: ', coverage);
                                console.error('index: ', index);
                                console.error('step: ', step);
                                console.error('chain: ', chain);
                                throw e;
                            }

                        });
                    });
                } catch (err) {
                    console.error('coverage: ', coverage);
                    throw err;
                }


            });

            console.log('requisiteCoverageMap: ', requisiteCoverageMap);
            const shortestPathWithRequisites = Object.entries(requisiteCoverageMap).map(([ _course, coverage ]) => {
                shortestPathTotalUnits += coverage.units || 0;
                return coverage;
            });
            const shortestPathWithRequisitesSorted = shortestPathWithRequisites.sort((a, b) => {
                // Prioritize items where satisfies.length > 0
                const aHasSatisfies = a.satisfies.length > 0 ? -1 : 1;
                const bHasSatisfies = b.satisfies.length > 0 ? -1 : 1;

                if (aHasSatisfies !== bHasSatisfies) {
                    return aHasSatisfies - bHasSatisfies;
                }

                // If both have satisfies or both don't, sort alphabetically by courseName
                return a.course.localeCompare(b.course);
            });


            auditedDegreeBlock.missingClassCreditRules = missingClassCreditRules;
            auditedDegreeBlock.shortestPathRemaining = {
                count: shortestPathWithRequisitesSorted.length,
                courses: shortestPathWithRequisitesSorted
            };

            console.log('auditedBlock.shortestPathRemaining: ', auditedDegreeBlock.shortestPathRemaining);

            auditedDegreeBlock.shortestPathTotalUnits = shortestPathTotalUnits;
            auditedDegreeBlock.longestSequence = longestChainLength;
            auditedDegreeBlock.longestChain = longestChain;

            console.log('auditedDegreeBlock.minCreditsHeaders: ', auditedDegreeBlock.minCreditsHeaders);

            auditedDegreeBlock.remaining = calculateRemainingSemesters(auditedDegreeBlock, studentInfoWithDegree);

            AuditTracker.logSatisfiedConditions();


        } else {

            errors.push('No academic plans were found for this student.');
        }

        return {
            studentInfo: studentInfoWithDegree,
            auditedBlock: auditedDegreeBlock,
            errors
        };

    } else {
        throw new Error('Unable to find studentId: ' + studentId);
    }
}
