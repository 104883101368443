import _ from 'lodash';
import {AuditedRequirementBlock, AuditedRuleEntry, AuditStatus, StudentInfoWithDegree} from '@/degrees/audit/types';
import {RequirementBlock} from '@/degrees/types';
import auditRuleEntry from '@/degrees/audit/auditRuleEntry';
import {RuleEntry} from '@/degrees/rules';

export default async function auditBlockRule(requirementBlock: RequirementBlock, studentInfo: StudentInfoWithDegree): Promise<AuditedRequirementBlock> {
    const { parseTree: { header_list, body_list } } = requirementBlock;


    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        requiredClasses: 0,
        requiredCredits: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };

    const auditedBodyList: AuditedRuleEntry[] = [];
    for (let i = 0; i < body_list.length; i++) {

        let audited: AuditedRuleEntry<RuleEntry>;
        try {
            audited = await auditRuleEntry(body_list[i], studentInfo);
        } catch (e) {
            console.error('requirementBlock: ', requirementBlock);
            throw e;
        }

        if ('block' in audited && !audited.block.populatedBlock) {
            console.error('audited block entry but did not include populated block');
            throw new Error('audited block entry but did not include populated block');
        }

        if (audited.requiredClasses || audited.requiredCredits) {
            auditStatus.requiredClasses += audited.requiredClasses;
            auditStatus.requiredCredits += audited.requiredCredits;

            auditStatus.completedClasses += audited.completedClasses;
            auditStatus.completedCredits += audited.completedCredits;

            auditStatus.requiredClassCreditRules = auditStatus.requiredClassCreditRules.concat(audited.requiredClassCreditRules);


            if ('conditional' in audited) {
                audited.conditional.auditedBranch?.forEach(auditedBranchEntry => {
                    auditedBodyList.push(auditedBranchEntry);
                });

            } else {
                auditedBodyList.push(audited);
            }



            if (!audited.completed) {
                auditStatus.completed = false;
                auditStatus.message = 'One or more rules unmet';
            }
        }

    }

    const auditedBlock = _.cloneDeep(requirementBlock);

    auditedBlock.parseTree.body_list = auditedBodyList;

    console.log(`auditBlockRule: ${requirementBlock.requirementId}`);

    return {
        minCreditsHeaders: {
            largest: undefined,
            minCreditsHeaders: []
        },
        longestChain: [],
        longestSequence: 0,
        missingClassCreditRules: [],
        shortestPathRemaining: undefined,
        shortestPathTotalUnits: 0,
        ...auditedBlock,
        ...auditStatus,
        errors: []
    };
}
