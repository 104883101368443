import { gql } from '@apollo/client';
import * as fragments from '@/api/graphql/fragments';

export default gql`
fragment requirementGroupDetails on custom_rq_grp_detl_tbl {
    requirementGroup: rqrmnt_group
    requirementId: requirement
    requisiteType: requisite_type
    groupLineType: rq_grp_line_type
    groupLineNumber: rq_grp_line_nbr
    lineKeyNumber: rq_line_key_nbr
    connect: rq_connect
    parenthesis
    description: descr
    
    testId: test_id
    testComponent: test_component
    score
    conditionData: condition_data
    conditionOperator: condition_operator
    conditionCode: condition_code
    planDescription: plan_descr
    subplanDescription: subplan_descr
    institution
    
    conditionSpec: condition_spec {
        ...conditionSpec
    }    
    conditions {
        ...conditionLine
    }
    conditionDetails: condition_details {
        ...conditionLineDetails
    }
    
    milestones
    conditionConnectType: condition_connect_type
    
    gpaRequired: gpa_required
    minGradePoints: grade_points_min
    minUnits: units_minimum
    minUnitsRequired: min_units_reqd
    minCoursesRequired: min_crses_reqd
    
    includeEquivalent: include_equivalent
    
    course {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        equivalentCourses: equivalent_courses {
            courseId: crse_id
            subject
            courseNumber: catalog_nbr
            institution
        }
    }
    courseName: course_name
    
    academicGroup: acad_group
    subject
    catalogNumberPattern: catalog_nbr
    
    requirements(order_by: {line_number: asc}) {
        ...requirementLine
    }
    
    entityGroup:entity_group
    entityGroupDetails:entity_group_details {
      ...entityGroupDetails
    }
}
${fragments.requirementLine}
${fragments.conditionLine}
${fragments.conditionLineDetails}
${fragments.conditionSpec}
${fragments.entityGroupDetails}
`;
