import http from '@/api/http';

export default async (courseNames: string[], institution: string): Promise<{
    institution: string,
    subject: string,
    courseNumber: string,
    course: string,
    units: number,
}[]> => {
    if (!courseNames.length) {
        return [];
    }

    const endpoint = '/ttd/zero-requisites';

    const { data } = await http.post(endpoint, { courses: courseNames, institution });

    const zeroRequisiteCourses: {
        institution: string,
        subject: string,
        courseNumber: string,
        course: string,
        units: number,
    }[] = data.courses.map((o: any) => ({
        institution: o.institution,
        subject: o.subject,
        courseNumber: o.catalog_nbr,
        course: `${o.subject} ${o.catalog_nbr}`,
        units: o.units
    }));

    return zeroRequisiteCourses;
};
