import { ConditionMilestone, Milestone, RequirementCondition} from '@/api/types';
import {StudentInfoWithDegree} from '@/degrees/audit/types';
import _ from 'lodash';

export const MILESTONE_PREFIX = 'MILESTONE';
export const MILESTONE_COMPOSITE_KEY_DELIMITER = '__';

export function serializeMilestoneCondition(milestone: ConditionMilestone, includeSeq: boolean = false): string {
    return [ MILESTONE_PREFIX, milestone.milestone, normalizeMilestoneLevel(milestone.milestone_level), includeSeq ? milestone.cond_ln_detl_seq : '' ].join(MILESTONE_COMPOSITE_KEY_DELIMITER);
}
export function deserializeMilestoneCondition(s: string): ConditionMilestone {
    const [
        _milestonePrefix,
        milestone,
        milestone_level,
        cond_ln_detl_seq,
    ] = s.split(MILESTONE_COMPOSITE_KEY_DELIMITER);

    const cm: ConditionMilestone = {
        milestone,
        milestone_level,
        cond_ln_detl_seq,
    };
    return cm;
}

export function serializeStudentMilestone(milestone: Milestone): string {
    // adding empty string to match with cond_ln_delt_seq
    return [ MILESTONE_PREFIX, milestone.milestoneName, normalizeMilestoneLevel(milestone.level), '' ].join(MILESTONE_COMPOSITE_KEY_DELIMITER);
}

export function isRequirementConditionMilestone(rc: RequirementCondition): boolean {
    return !!(rc.conditionCode === 'TBL' && rc.milestones.length);
}


// condition milestones always have an "equals" (EQ) `condition_operator`
function getQualifyingMilestones(condition: ConditionMilestone, studentMilestones: Milestone[]) {
    const conditionLevel = normalizeMilestoneLevel(condition.milestone_level);

    const qualifyingMilestones: Milestone[] = _.filter(studentMilestones, o => {
        const studentLevel = normalizeMilestoneLevel(o.level);
        return o.milestoneName === condition.milestone && conditionLevel === studentLevel;
    });

    return qualifyingMilestones;
}

function normalizeMilestoneLevel(input: string = ''): string {
    const cleaned = input.replace(/[\s_]+/g, '');
    return cleaned;
}

export function completedMilestone(condition: RequirementCondition, studentInfo: StudentInfoWithDegree): boolean {
    if (condition.conditionCode !== 'TBL') {
        throw new Error(`completedMilestone: invalid conditionCode: ${condition.conditionCode}`);
    }

    const studentMilestones = studentInfo.milestones;

    const conditionConnect = condition.conditionConnect;

    for (const cm of condition.milestones) {
        if (getQualifyingMilestones(cm, studentMilestones).length) {

            // if OR and at least 1 is met then immediately PASS
            if (conditionConnect === 'OR') {
                return true;
            }

        // if AND and at least 1 is NOT met then immediately FAIL
        } else if (conditionConnect === 'AND') {
            return false;
        }
    }

    // if we iterated through all the conditions...

    // if OR then that means nothing was met so FAIL
    if (conditionConnect === 'OR') {
        return false;
    }

    // if AND then that means nothing failed so we PASS
    return conditionConnect === 'AND';

}
