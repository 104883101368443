import { gql, useMutation, useQuery } from '@apollo/client';
import graphClient from '@/api/graph';
import { SimulationData } from '@/store/simulation/types';
import { ClassSearchLog, PaymentInfo, SelectedCourse } from '@/api/types';
import { convertToSnakeCase } from '@/api/graphUtils';
import { useCallback } from 'react';
import { useMe } from '@/api/AuthService';
import { formatISO } from 'date-fns';
import { SurveyAnswers } from '@/components/survey/surveyQuestions';
import { ScreenerAnswers } from '@/components/screener/screenerQuestions';
import config from '@/config';
import {AvailableTimesSelection} from '@/components/preferences/types';

export type SimulationStats = {
    firstSearch?: ClassSearchLog;
    lastSearch?: ClassSearchLog;
};

export type SimulationResultsRaw = {
    cohort: string;
    student_id: string;
    display_name: string;
    created_at?: string; // firstLoggedIn
    first_logged_in?: string; // alias
    last_logged_in: string;
    available_times: AvailableTimesSelection[];
    available_times_submitted_ts: string;
    consent_ts: string;
    payment_info: PaymentInfo;
    payment_info_submitted_ts: string;
    shopping_cart: SelectedCourse[];
    data: SimulationData;
    survey: SurveyAnswers;

    screener: ScreenerAnswers;

    is_tester?: boolean;
    is_staff?: boolean;

    stats: SimulationStats;
    last_interaction?: string; // timestamp__column
};
export type SimulationResults = {
    studentId: string;
    cohort: string;
    displayName: string;
    firstLoggedIn?: string;
    lastLoggedIn: string;
    availableTimes: AvailableTimesSelection[];
    availableTimesSubmittedTs: string;
    consentTs: string;
    paymentInfo: PaymentInfo;
    paymentInfoSubmittedTs: string;
    shoppingCart: SelectedCourse[];
    data: SimulationData;
    survey: SurveyAnswers;

    screener: ScreenerAnswers;

    isTester?: boolean;
    isStaff?: boolean;

    stats: SimulationStats;
};

export const simResultsFields = gql`
fragment simulationFields on simulation_results {
    cohort: cohort
    studentId: student_id
    displayName: display_name
    lastLoggedIn: last_logged_in
    availableTimes: available_times
    availableTimesSubmittedTs: available_times_submitted_ts
    consentTs: consent_ts
    paymentInfo: payment_info
    paymentInfoSubmittedTs: payment_info_submitted_ts
    shoppingCart: shopping_cart
    data: data
    survey: survey
    screener: screener
    stats: stats
}
`;

export const GET_SIM_RESULTS_BY_PK = gql`
    query getSimResults($id: String!) {
        results:simulation_results_by_pk(student_id: $id) {
            ...simulationFields
        }
    }
    ${simResultsFields}
`;


// Data object only requires providing display_name
const CREATE_SIM_RESULTS = gql`
mutation createSimResults($data:simulation_results_insert_input!) {
    insert_simulation_results_one(object: $data) {
          ...simulationFields
    }
}
    ${simResultsFields}
`;

export const UPDATE_SIM_RESULTS = gql`
mutation updateSimResults($id: String!, $data: simulation_results_set_input!) {
  update_simulation_results_by_pk(pk_columns: {student_id: $id}, _set: $data) {
    ...simulationFields
  }
}
${simResultsFields}
`;

const UPDATE_SIMULATION_DATA = gql`
mutation updateSimulationData($id:String!, $data: jsonb) {
      update_simulation_results_by_pk(
              pk_columns:{student_id:$id},
              _append: {data: $data}
          ) {
          ...simulationFields,
      }
  }
  ${simResultsFields}
`;

const UPDATE_SURVEY = gql`
mutation updateSurvey($id:String!, $data: jsonb) {
      update_simulation_results_by_pk(
              pk_columns:{student_id:$id},
              _append: {survey: $data}
          ) {
          ...simulationFields,
      }
  }
  ${simResultsFields}
`;

const updateLastLoggedIn = async (studentId: string) => {
    return graphClient.mutate({
        mutation: UPDATE_SIM_RESULTS,
        variables: {
            id: studentId,
            data: {
                last_logged_in: formatISO(new Date()),
            }
        }
    });
};

// Ensures or creates a row for the student in the simulation_results table
// We don't actually need studentId when creating results since there is a  hasura preset for id column
// from X-Hasura-User-Id. However for updating the lastLoggedIn stamp this is a quick hack to avoid
// having to refactor everything.
export const initializeStudentEntry = async (studentId: string, displayName = '') => {
    console.log('initializeStudentEntry studentId: ', studentId);
    let req = await graphClient.query({
        query: GET_SIM_RESULTS_BY_PK,
        variables: {
            id: studentId
        },
        fetchPolicy: 'network-only',
    });
    console.log('req.data.results: ', req.data.results);
    if (!req.data.results) {
        await graphClient.mutate({
            mutation: CREATE_SIM_RESULTS,
            variables: {
                data: {
                    display_name: displayName
                }
            },
            refetchQueries: [ { query: GET_SIM_RESULTS_BY_PK, variables: {id: studentId} } ]
        });

        req = await graphClient.query({
            query: GET_SIM_RESULTS_BY_PK,
            variables: {
                id: studentId
            },
            fetchPolicy: 'network-only',
        });

    } else {
        if (!config.local && studentId !== '74747474') {
            await updateLastLoggedIn(studentId);
        }
    }
    return req.data.results;
};

// this should be called AFTER initializeStudentEntry to ensure there is a valid record
export const useGetSimResults = (): SimulationResults => {
    const account = useMe();

    const { data } = useQuery(GET_SIM_RESULTS_BY_PK, {
        variables: {
            id: account!.studentId
        },
        fetchPolicy: 'cache-and-network',
    });
    return data?.results || {};
};

export const useGetSurveyAnswers = (): SurveyAnswers => {
    const simResults = useGetSimResults();
    return simResults.survey;
};

export const useUpdateSimResults = (): (props: Partial<SimulationResults>) => Promise<any> => {
    const account = useMe();

    const [ mutation ] = useMutation(UPDATE_SIM_RESULTS, {
        refetchQueries: [ { query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId} } ],
        awaitRefetchQueries: true
    });

    return useCallback((props: Partial<SimulationResults>) => {
        const cache = graphClient.readQuery({query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId}}) || {};

        graphClient.writeQuery({
            query: GET_SIM_RESULTS_BY_PK,
            variables: {
                id: account!.studentId
            },
            data: {
                ...cache,
                ...props,
            }
        });

        return mutation({
            variables: {
                id: account!.studentId,
                data: convertToSnakeCase(props)
            },
        });
    }, []);
};

// Union-style update props to the "data" column of the results table
// Don't transform to snake case here since we're dealing with nested json props and not sql columns
export const useUpdateSimulationData = () => {
    const account = useMe();

    const [ mutation ] = useMutation(UPDATE_SIMULATION_DATA, {
        refetchQueries: [ { query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId} } ]
    });

    return useCallback((props: Partial<SimulationData>) => {
        // const cache = graphClient.readQuery({query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId}});

        return mutation({
            variables: {
                id: account!.studentId,
                data: props
            },
        });
    }, []);
};

// Union-style update props to the "survey" column of the results table
// Don't transform to snake case here since we're dealing with nested json props and not sql columns
// TODO: make sure to add a loader and fully await all the updateX calls before moving to next screen
export const useUpdateSurvey = () => {
    const account = useMe();

    const [ mutate ] = useMutation(UPDATE_SURVEY, {
        refetchQueries: [ { query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId} } ]
    });

    return useCallback((props: Partial<SurveyAnswers>) => {
        // const cache = graphClient.readQuery({query: GET_SIM_RESULTS_BY_PK, variables: {id: account!.studentId}});

        return mutate({
            variables: {
                id: account!.studentId,
                data: props
            },
        });
    }, []);
};
