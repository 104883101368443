export const AVG_COURSE_UNITS = 4;
export const AVG_COURSE_LOAD = 8;

export enum AuditFlagEnum {
    ignored = 'I'
}

export enum ScribeCode {
    PriorDegree = 'PDEG',
    PriorDegreeBA = 'PDBA',
    PriorDegreeBS = 'PDBS',
    Major = 'MAJOR',
    Concentration = 'CONC',
    AUDITACTION = 'AUDITACTION'
}

export enum ConditionOperator {
    Equal = '=',
    GreaterThan = '>',
    LessThan = '<',
    GreaterOrEqual = '>=',
    LessOrEqual = '<=',
    NotEqual = '<>',
    Is = 'is',
    Isnt = 'isnt',
    Was = 'was',
    Wasnt = 'wasnt',
}
