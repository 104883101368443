import http from '@/api/http';
import {AccountType} from '@/api/types';


export type UpdateAccountProps = {
    username: string;
    password?: string;
    accountType?: AccountType
};

export default async (props: UpdateAccountProps): Promise<void> => {
    const endpoint = '/account';
    await http.put(endpoint, props);
};
