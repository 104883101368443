import {ScribedCourse} from '@/degrees/rules';
import {AVG_COURSE_UNITS} from '@/degrees/audit/constants';
import {expandWildcardCourses, formatScribedCourse, isIdentifiable} from '@/degrees/audit/utils';
import {AuditedClassCreditRule} from '@/degrees/audit/types';
import {AuditedCourseRequirement, RequisiteChainInfo} from '@/degrees/audit/auditPathRequisite';
import {
    CourseExtractWithRequisites,
    getCoursesWithAllRequisites,
    setDevBreakpointTimestamp
} from '@/api/graphql/queries/getCourses';
import _ from 'lodash';
import getCoursesWithZeroRequisites from '@/api/getCoursesWithZeroRequisites';
import {findMinimalHeapSelection} from '@/degrees/audit/findMinimalHeapSelection';
import config from '@/config';

// Standard combination logic
function getCombinations(arr: string[], numRequired: number) {
    const results: string[][] = [];

    function backtrack(start: number, combo: string[]) {
        if (combo.length === numRequired) {
            results.push([ ...combo ]);
            return;
        }
        for (let i = start; i < arr.length; i++) {
            combo.push(arr[i]);
            backtrack(i + 1, combo);
            combo.pop();
        }
    }

    backtrack(0, []);

    return results
        .map(c => c.slice().sort())
        .sort((a, b) => {
            for (let i = 0; i < Math.min(a.length, b.length); i++) {
                const cmp = a[i].localeCompare(b[i]);
                if (cmp !== 0) return cmp;
            }
            return a.length - b.length;
        });
}


const LARGE_COMBO_THRESHOLD = 500;


export async function findMinimumCoursesFromRules(rules: AuditedClassCreditRule[], institution: string, completedCourseNames: string[]): Promise<CoverageInfo> {
    if (!rules.length) {
        return {
            count: 0,
            courses: []
        };
    }
    console.time('findMinimumCoursesFromRules');
    const requirements = parseAuditedClassCreditRules(rules);
    console.log('requirements: ', requirements);

    const results = findMinimumCourses(requirements, institution, completedCourseNames);
    console.timeEnd('findMinimumCoursesFromRules');
    return results;
}

type FrequencyInfo = {
    occurrences: number,
    requirements: {
        requirementId: string;
        numRequired: number;
    }[]
};
type CourseFrequency = {
    course: string;
} & FrequencyInfo;

type RequirementComboInfo = {
    requirementId: string,
    originalNumRequired: number,
    numRequired: number,
    uniqueCourses: string[],
    combos: string[][],
    selected: string[],
};

function convertFrequencyMapToArray(fMap: {
    [key: string]: FrequencyInfo
}): CourseFrequency[] {
    return Object.entries(fMap).map(([ key, value ]) => {
        return {
            course: key,
            ...value
        };
    })
        .sort((a, b) =>
            b.occurrences - a.occurrences || a.course.localeCompare(b.course)
        );
}

const ENABLE_VERBOSE = config.local;
function logVerbose(...args: any[]): void {
    if (!ENABLE_VERBOSE) return;
    console.log(...args);
}

export async function findMinimumCourses(requirements: ClassCreditRequirement[], institution: string, completedCourseNames: string[]): Promise<CoverageInfo> {

    const courseFrequencyMap: {
        [key: string]: FrequencyInfo
    } = {};
    let mostFrequentCourse: CourseFrequency | null = null;

    function addToFrequencyMap(c: string, requirement: {requirementId: string, numRequired: number}) {
        const r = {
            requirementId: requirement.requirementId,
            numRequired: requirement.numRequired
        };

        if (courseFrequencyMap[c]) {
            courseFrequencyMap[c].occurrences++;
            courseFrequencyMap[c].requirements.push(r);
        } else {
            courseFrequencyMap[c] = {
                occurrences: 1,
                requirements: [ r ]
            };
        }

        if (!mostFrequentCourse) {
            mostFrequentCourse = {
                course: c,
                requirements: [ r ],
                occurrences: 1,
            };
        } else if (courseFrequencyMap[c].occurrences > mostFrequentCourse.occurrences) {
            mostFrequentCourse = {
                course: c,
                ..._.cloneDeep(courseFrequencyMap[c])
            };
        }
    }


    // Gather all distinct "normal" (non-wildcard) courses from all requirements
    const allKnownCourses: Set<string> = new Set();
    requirements.forEach(requirement => {
        requirement.courses.forEach(c => {
            if (isIdentifiable(c)) {
                allKnownCourses.add(c);

                addToFrequencyMap(c, requirement);
            }
        });
    });

    logVerbose('courseFrequencyMap: ', courseFrequencyMap);
    // logVerbose('mostFrequentCourse: ', _.cloneDeep(mostFrequentCourse));

    const allCoursesArr: CourseFrequency[] = convertFrequencyMapToArray(courseFrequencyMap);
    logVerbose('allCoursesArr: ', allCoursesArr);

    // For each requirement, expand wildcard courses into a flattened array of actual matches
    // Then generate all combos
    const requirementsWithCombos: RequirementComboInfo[] = requirements.map((r: ClassCreditRequirement) => {

        const expandedCourses = expandWildcardCourses(r.courses, [ ...allKnownCourses ]);

        expandedCourses.forEach(c => {
            addToFrequencyMap(c, r);
        });

        const uniqueCourses = [ ...new Set(expandedCourses) ];
        const combos = getCombinations(uniqueCourses, r.numRequired);

        return {
            requirementId: r.requirementId,
            originalNumRequired: r.numRequired,
            numRequired: r.numRequired,
            uniqueCourses,
            combos,
            selected: [],
        };
    });

    const initialTotalCombinations = calculateTotalCombinations(requirementsWithCombos);
    console.log('initialTotalCombinations: ', initialTotalCombinations);

    requirementsWithCombos.sort((a, b) => a.combos.length - b.combos.length);

    logVerbose('original requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));

    let autoCompletedRequirements: RequirementComboInfo[] = [];

    function refreshPendingRequirements() {
        _.remove(requirementsWithCombos, o => {
            return !!(autoCompletedRequirements.find(ac => ac.requirementId === o.requirementId));
        });

        const hasStaleCombos = _.filter(requirementsWithCombos, o => {
            return !!o.selected.length;
        });

        hasStaleCombos.forEach(r => {
            const combos = getCombinations(r.uniqueCourses, r.numRequired);
            r.combos = combos;
        });
    }

    // ======================================================
    // heuristics #1: if requirement says choose 1 from only 1, then there's no other choice but to accept it, in which case
    // we can process it as well as update any shared requirements

    const onlyOneChoiceRequirements: RequirementComboInfo[] = _.remove(requirementsWithCombos, r => {
        return r.numRequired === 1 && r.uniqueCourses.length === 1 && isIdentifiable(r.uniqueCourses[0]);
    });

    for (const onlyChoiceReq of onlyOneChoiceRequirements) {
        const course = onlyChoiceReq.uniqueCourses[0];

        onlyChoiceReq.selected.push(course);

        const frequency = courseFrequencyMap[course];

        // process any shared requirements
        if (frequency.occurrences > 1) {

            frequency.requirements.forEach(seenInReq => {

                const shared = requirementsWithCombos.find(rwc => {
                    return seenInReq.requirementId === rwc.requirementId;
                });

                if (shared) {
                    shared.numRequired = shared.numRequired - 1;
                    shared.selected.push(course);
                    _.remove(shared.uniqueCourses, c => {
                        return c === course;
                    });

                    if (shared.numRequired === 0) {
                        autoCompletedRequirements.push(shared);
                        _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                    }
                }
            });
        }
    }

    refreshPendingRequirements();
    // ======================================================

    // heuristics #2: if requirement says choose up to 4 from 4 or less, all of which do not occur in any other requirements
    // then go ahead and select first combo (item) since those are guaranteed to be sorted

    const noSharedRequirements: RequirementComboInfo[] = _.remove(requirementsWithCombos, r => {
        for (const course of r.uniqueCourses) {
            // if course appears anywhere else then abort

            if (courseFrequencyMap[course].occurrences > 1) {
                return false;
            }
        }
        try {
            isIdentifiable(r.uniqueCourses[0]);
        } catch (e) {
            console.error('r.uniqueCourses: ', r.uniqueCourses);
            console.error('r: ', r);
            throw e;
        }

        return r.numRequired <= 4 && isIdentifiable(r.uniqueCourses[0]);
    });
    for (const noSharedReq of noSharedRequirements) {
        // since only requires 1, then no need to update itself
        noSharedReq.selected.push(...noSharedReq.combos[0].slice(0, noSharedReq.numRequired));
    }


    // logVerbose('after unique heuristics, requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));

    // ======================================================

    // heuristics #3: if the most frequent courses are still available then select
    const HIGH_THRESHOLD = 3;
    if (allCoursesArr[0].occurrences >= HIGH_THRESHOLD) {
        const mostFrequentCourses = allCoursesArr.filter(o => o.occurrences >= HIGH_THRESHOLD);

        let hasChanges = false;
        for (const frequency of mostFrequentCourses) {
            const course = frequency.course;

            frequency.requirements.forEach(seenInReq => {
                const shared = requirementsWithCombos.find(rwc => {
                    return seenInReq.requirementId === rwc.requirementId;
                });

                // make sure it hasn't been added yet
                if (shared && !shared.selected.includes(course)) {
                    hasChanges = true;

                    shared.numRequired = shared.numRequired - 1;
                    shared.selected.push(course);
                    _.remove(shared.uniqueCourses, c => {
                        return c === course;
                    });

                    if (shared.numRequired === 0) {
                        autoCompletedRequirements.push(shared);
                        _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                    }
                }
            });
        }
        if (hasChanges) {
            console.log('HIGH_THRESHOLD hasChanges!');
            refreshPendingRequirements();
        }
    }
    // ======================================================


    // heuristics #4: medium threshold verison of the above. except verifies whether requirements are still available otherwise skips
    const TWO_SHARED = 2;
    const mostFrequentCourses = allCoursesArr.filter(o => o.occurrences === TWO_SHARED);
    console.log('mostFrequentCourses: ', mostFrequentCourses);

    if (mostFrequentCourses) {
        let hasChanges = false;
        for (const frequency of mostFrequentCourses) {
            const course = frequency.course;

            frequency.requirements.forEach(seenInReq => {

                const allShared: RequirementComboInfo[] = requirementsWithCombos.filter(rwc => {
                    return seenInReq.requirementId === rwc.requirementId;
                });

                if (allShared.length !== TWO_SHARED) return;

                let stillNeededCount = 0;
                allShared.forEach(shared => {
                    if (!shared.selected.includes(course)) {
                        stillNeededCount++;
                    }
                });

                if (stillNeededCount !== TWO_SHARED) return;

                hasChanges = true;

                allShared.forEach(shared => {
                    shared.numRequired = shared.numRequired - 1;
                    shared.selected.push(course);
                    _.remove(shared.uniqueCourses, c => {
                        return c === course;
                    });

                    if (shared.numRequired === 0) {
                        autoCompletedRequirements.push(shared);
                        _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                    }
                });
            });
        }
        if (hasChanges) {
            console.log('TWO_SHARED hasChanges!');
            refreshPendingRequirements();
        }
    }

    // ======================================================

    const remainingTotalCombinations = calculateTotalCombinations(requirementsWithCombos);
    logVerbose('remainingTotalCombinations: ', remainingTotalCombinations);
    // logVerbose('after high frequency heuristics requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));

    // heuristics #5: fetch direct prerequisites and select any overlap among remaining requirements
    if (remainingTotalCombinations > 10000) {
        const selectedCourses = [ ...onlyOneChoiceRequirements, ...noSharedRequirements, ...autoCompletedRequirements ].flatMap(r => r.selected);
        // logVerbose('selectedCourses so far: ', _.cloneDeep(selectedCourses));

        const extracts = await getCoursesWithAllRequisites(selectedCourses, institution, completedCourseNames);

        const prerequisites = extracts.flatMap(o => o.requisites || []);

        let hasChanges = false;
        for (const prereq of prerequisites) {
            const course = `${prereq.subject} ${prereq.courseNumber}`;

            if (courseFrequencyMap[course]) {

                console.log('prerequisites has overlap: ', course, courseFrequencyMap[course]);

                for (const seenInReq of courseFrequencyMap[course].requirements) {
                    const shared = requirementsWithCombos.find(rwc => {
                        return seenInReq.requirementId === rwc.requirementId;
                    });

                    // make sure it hasn't been added yet
                    if (shared && !shared.selected.includes(course) && shared.numRequired) {
                        hasChanges = true;

                        shared.numRequired = shared.numRequired - 1;
                        shared.selected.push(course);
                        _.remove(shared.uniqueCourses, c => {
                            return c === course;
                        });

                        if (shared.numRequired === 0) {
                            autoCompletedRequirements.push(shared);
                            _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                        }
                    }
                }
            }
        }
        if (hasChanges) {
            console.log('shared prerequisites hasChanges!');
            refreshPendingRequirements();
        }
    }

    // ====== if there's 3 or fewer requirements and at least 1 requires only 1 more course which also overlaps with at least one other requirement
    // then go ahead and select it
    if (requirementsWithCombos.length <= 3) {

        const remainingIds = requirementsWithCombos.map(rwc => rwc.requirementId);

        for (const rwc of requirementsWithCombos) {
            if (rwc.numRequired === 1) {
                // logVerbose('[THREE_OR_LESS] checking for remaining overlaps: ', _.cloneDeep(requirementsWithCombos));

                const onlyOneMoreRequired: RequirementComboInfo = rwc;
                const otherRequirements: RequirementComboInfo[] = requirementsWithCombos.filter(r => r.requirementId !== onlyOneMoreRequired.requirementId);

                let mostFrequentCourseAvailable: CourseFrequency | null = null;
                let highestStillAvailableCount = 0;


                const availableCourses = rwc.uniqueCourses;

                for (const available of availableCourses) {
                    const courseFrequency = courseFrequencyMap[available];

                    try {
                        if (courseFrequency.occurrences <= highestStillAvailableCount) continue;
                    } catch (e) {
                        console.error('available: ', available);
                        console.error('availableCourses: ', availableCourses);
                        console.error('courseFrequencyMap: ', courseFrequencyMap);
                        throw e;
                    }



                    const availableInOthers = otherRequirements.filter(r => {
                        return r.uniqueCourses.includes(available);
                    });

                    if (availableInOthers.length > highestStillAvailableCount) {
                        highestStillAvailableCount = availableInOthers.length;
                        mostFrequentCourseAvailable = {
                            ...courseFrequency,
                            course: available,
                        };
                    }
                }

                if (mostFrequentCourseAvailable) {
                    console.log('[THREE_OR_LESS] Found mostFrequentCourseAvailable: ', mostFrequentCourseAvailable);

                    for (const seenInReq of mostFrequentCourseAvailable.requirements) {
                        const shared = requirementsWithCombos.find(rwc => {
                            return seenInReq.requirementId === rwc.requirementId;
                        });

                        // make sure it hasn't been added yet
                        if (shared && !shared.selected.includes(mostFrequentCourseAvailable.course) && shared.numRequired) {

                            shared.numRequired = shared.numRequired - 1;
                            shared.selected.push(mostFrequentCourseAvailable.course);
                            _.remove(shared.uniqueCourses, c => {
                                return c === mostFrequentCourseAvailable!.course;
                            });

                            if (shared.numRequired === 0) {
                                autoCompletedRequirements.push(shared);
                                _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                            }
                        }
                    }

                    refreshPendingRequirements();

                    // logVerbose('After THREE_OR_LESS requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));
                }
            }
        }
    }

    // if there's still lots of combinations
    const remainingTotalCombinations2 = calculateTotalCombinations(requirementsWithCombos);
    logVerbose('remainingTotalCombinations2: ', remainingTotalCombinations2);
    // logVerbose('Before [HEAP_SELECTION] requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));

    if (remainingTotalCombinations2 > 10000) {

        const heapSelection = findMinimalHeapSelection(requirementsWithCombos);

        console.log('heapSelection: ', heapSelection);

        heapSelection.forEach(course => {

            const courseFrequency = courseFrequencyMap[course];

            for (const seenInReq of courseFrequency.requirements) {
                const shared = requirementsWithCombos.find(rwc => {
                    return seenInReq.requirementId === rwc.requirementId;
                });

                // make sure it hasn't been added yet
                if (shared && !shared.selected.includes(course) && shared.numRequired) {

                    shared.numRequired = shared.numRequired - 1;
                    shared.selected.push(course);
                    _.remove(shared.uniqueCourses, c => {
                        return c === course;
                    });

                    if (shared.numRequired === 0) {
                        autoCompletedRequirements.push(shared);
                        _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                    }
                }
            }
        });
    }
    // logVerbose('After [HEAP_SELECTION] requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));

    // =================================
    //  if there's only 1 requirement left then immediately prioritize the first available course that has zero requisites
    if (requirementsWithCombos.length === 1) {
        const lastRwc = requirementsWithCombos[0];

        const zeroRequisiteCourses = await getCoursesWithZeroRequisites(lastRwc.uniqueCourses, institution);

        let added = 0;
        let hasChanges = false;
        for (let i = 0; i < lastRwc.numRequired; i++) {
            if (zeroRequisiteCourses[i]) {
                hasChanges = true;
                lastRwc.selected.push(zeroRequisiteCourses[i].course);
                _.remove(lastRwc.uniqueCourses, c => c === zeroRequisiteCourses[i].course);
            } else {
                const firstElement = lastRwc.uniqueCourses.shift();
                if (!firstElement) {
                    throw new Error('firstElement is undefined');
                }
                lastRwc.selected.push(firstElement);
            }
            added++;
        }
        lastRwc.numRequired = lastRwc.numRequired - added;

        if (!lastRwc.numRequired) {
            _.remove(requirementsWithCombos, o => o.requirementId === lastRwc.requirementId);
            autoCompletedRequirements.push(lastRwc);
        }
    }


    const remainingTotalCombinations3 = calculateTotalCombinations(requirementsWithCombos);
    logVerbose('remainingTotalCombinations3: ', remainingTotalCombinations3);
    // logVerbose('requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));
    // logVerbose('autoCompletedRequirements: ', _.cloneDeep(autoCompletedRequirements));

    // throw new Error('devv');

    // heuristics #6: if still extreme amount of remaining combos then fetch prereqs for all remaining courses and
    // then select the ones that are immediately available to enroll
    if (remainingTotalCombinations3 > 10000) {
        const allRemainingCourses = requirementsWithCombos.flatMap(r => r.uniqueCourses);

        console.log('allRemainingCourses: ', allRemainingCourses);

        setDevBreakpointTimestamp();

        const extracts = await getCoursesWithAllRequisites(allRemainingCourses, institution, completedCourseNames);

        const coursesWithoutRequisites = extracts.filter(o => !o.requisites || !o.requisites.length);
        logVerbose('coursesWithoutRequisites: ', coursesWithoutRequisites);

        let hasChanges = false;

        for (const noRequisiteCourse of coursesWithoutRequisites) {
            const course = `${noRequisiteCourse.subject} ${noRequisiteCourse.courseNumber}`;

            if (courseFrequencyMap[course]) {

                logVerbose('noRequisiteCourse has overlap: ', course, courseFrequencyMap[course]);

                for (const seenInReq of courseFrequencyMap[course].requirements) {
                    const shared = requirementsWithCombos.find(rwc => {
                        return seenInReq.requirementId === rwc.requirementId;
                    });

                    // make sure it hasn't been added yet
                    if (shared && !shared.selected.includes(course) && shared.numRequired) {
                        hasChanges = true;

                        shared.numRequired = shared.numRequired - 1;
                        shared.selected.push(course);
                        _.remove(shared.uniqueCourses, c => {
                            return c === course;
                        });

                        if (shared.numRequired === 0) {
                            autoCompletedRequirements.push(shared);
                            _.remove(requirementsWithCombos, o => o.requirementId === shared.requirementId);
                        }
                    }
                }
            }
        }
        if (hasChanges) {
            console.log('noRequisiteCourse hasChanges!');
            refreshPendingRequirements();
        }
    }






    logVerbose('onlyOneChoiceRequirements: ', onlyOneChoiceRequirements);
    logVerbose('noSharedRequirements: ', noSharedRequirements);
    logVerbose('autoCompletedRequirements: ', autoCompletedRequirements);
    // logVerbose('updated requirementsWithCombos: ', _.cloneDeep(requirementsWithCombos));


    let globalBest: Set<string> | null = null;
    let globalBestPath: string[][] | null = null;

    function backtrack(idx: number, currentUnion: Set<string>, path: string[][]) {
        // logVerbose('starting backtrack for idx: ', idx);

        if (globalBest && currentUnion.size >= globalBest.size) {
            // logVerbose('backtrack already have better. pruning!');
            return;
        }
        if (idx === requirementsWithCombos.length) {
            // logVerbose('backtrack covered all requirements!');
            globalBest = new Set(currentUnion);
            globalBestPath = [ ...path ];
            return;
        }

        const { requirementId, numRequired, uniqueCourses, combos } = requirementsWithCombos[idx];

        // logVerbose('combos: ', combos);

        // If the number of combos is below threshold, enumerate them directly
        if (combos.length <= LARGE_COMBO_THRESHOLD) {
            for (const combo of combos) {
                const newUnion = new Set([ ...currentUnion, ...combo ]);
                backtrack(idx + 1, newUnion, [ ...path, combo ]);
            }
            return;
        }

        console.error('should not be reachable');
        // =================================== UNREACHABLE =================================================

        // Otherwise, do partial coverage logic:
        // 1) Count how many are already in currentUnion
        let alreadyCovered = 0;
        for (const c of uniqueCourses) {
            if (currentUnion.has(c)) {
                alreadyCovered++;
            }
        }

        if (alreadyCovered >= numRequired) {
            // Already satisfied
            // console.log('already satisfied, backtracking with nothing new');
            backtrack(idx + 1, currentUnion, [ ...path, [] ]);
            return;
        }

        const needMore = numRequired - alreadyCovered;
        // The leftover set is uniqueCourses minus those in currentUnion
        const leftover = uniqueCourses.filter(c => !currentUnion.has(c));
        // If we can't satisfy needMore, prune
        if (leftover.length < needMore) {
            return;
        }

        // Enumerate only the needed leftover portion
        function chooseNeeded(start: number, chosen: string[]) {
            if (chosen.length === needMore) {
                const newUnion = new Set([ ...currentUnion, ...chosen ]);
                backtrack(idx + 1, newUnion, [ ...path, chosen ]);
                return;
            }
            for (let i = start; i < leftover.length; i++) {
                chosen.push(leftover[i]);
                chooseNeeded(i + 1, chosen);
                chosen.pop();
            }
        }
        chooseNeeded(0, []);
        // =================================== END UNREACHABLE =================================================
    }


    backtrack(0, new Set(), []);

    logVerbose('globalBest: ', globalBest);


    // Build coverage details
    const coverage: Record<string, any> = {};
    if (globalBestPath) {
        for (let i = 0; i < (globalBestPath as string[][]).length; i++) {
            // ================ Original implementation ====================
            // replacing with frequencyMap to handle edge cases where the conversion from required units to courses
            // occasionally results in off by 1 credit

            // const reqId = requirementsWithCombos[i].requirementId;
            // const chosenCombo: string[] = globalBestPath[i];
            // chosenCombo.forEach(course => {
            //     coverage[course] = coverage[course] || [];
            //     coverage[course].push(reqId);
            // });

            // ============================================================

            const chosenCombo: string[] = globalBestPath[i];
            chosenCombo.forEach(course => {
                coverage[course] = coverage[course] || [];
                coverage[course].push(...courseFrequencyMap[course].requirements.map(o => o.requirementId));
            });
        }
    }

    // ====== adding back heuristics
    const prunedRequirements = [ ...onlyOneChoiceRequirements, ...noSharedRequirements, ...autoCompletedRequirements ];
    prunedRequirements.forEach(r => {
        const reqId = r.requirementId;
        for (const course of r.selected) {
            coverage[course] = coverage[course] || [];
            coverage[course].push(reqId);
        }
    });

    // 3) Build the final set of all chosen courses (union of globalBest + pruned selections)
    const finalSet = new Set<string>(globalBest || []);
    for (const prunedReq of prunedRequirements) {
        for (const course of prunedReq.selected) {
            finalSet.add(course);
        }
    }
    // 4) Convert coverage to the final array of { course, satisfies: string[] }
    const coursesArray = Object.entries(coverage).map(([ course, satisfies ]) => {
        return {
            course,
            satisfies: _.uniq<string>(satisfies),
        };
    });

    // logVerbose('coursesArray: ', _.cloneDeep(coursesArray));

    return {
        count: finalSet.size,
        courses: coursesArray
    };
}

export type CoverageInfo = {
    count: number;
    courses: CourseCoverage[];
};

export type CourseCoverage = {
    course: string;
    satisfies: string[];
    courseId?: string;
    units?: number;

    requisites?: Array<AuditedRequisiteExtract>;
    chainInfo?: RequisiteChainInfo;
    totalUnits?: number; // coverage.units + chainInfo.totalUnits
    requiredFor?: string;

};

export type AuditedRequisiteExtract = AuditedCourseRequirement & Partial<CourseExtractWithRequisites>;

export type AuditedRequirementsSequence = Array<Array<AuditedRequisiteExtract>>;

export type CourseCoverageWithCatalogInfo = CourseCoverage;

export type ClassCreditRequirement = {
    requirementId: string;
    numRequired: number;
    courses: string[];
    remainingClasses: number;
    remainingCredits: number;
};

function parseAuditedClassCreditRules(entries: AuditedClassCreditRule[]): ClassCreditRequirement[] {
    return entries.map<ClassCreditRequirement>((audited: AuditedClassCreditRule, i: number) => {
        const rule = audited.rule;
        let numRequired;

        const remainingClasses = audited.requiredClasses - audited.completedClasses;
        const remainingCredits = audited.requiredCredits - audited.completedCredits;

        numRequired = remainingClasses || Math.ceil(remainingCredits / AVG_COURSE_UNITS);

        let courses = audited.rule.course_list.scribed_courses.map(courses => courses.map((s: ScribedCourse) => {
            return formatScribedCourse(s);
        })).flat();

        if (audited.populatedAttributeCourses) {
            for (const attr in audited.populatedAttributeCourses) {
                courses.push(...audited.populatedAttributeCourses[attr]);
            }
        }
        courses = _.uniq(courses);

        return {
            requirementId: `${i + 1}`,
            numRequired,
            courses,
            remainingClasses,
            remainingCredits,
        };
    });
}


// handle only for CREDIT type rules
export function meetsAllCreditRequirements(chosenCourses: CourseCoverage[], requirements: ClassCreditRequirement[]) {
    // For each requirement
    for (const req of requirements) {
        if (!req.remainingCredits) continue;

        // Sum credits from all chosen courses that belong to this requirement
        let fulfilledCredits = 0;
        chosenCourses.forEach(course => {
            if (req.courses.includes(course.course)) {
                fulfilledCredits += course.units || 0;
            }
        });
        // If we don't meet or exceed neededCredits, fail immediately
        if (fulfilledCredits < req.remainingCredits) {
            return false;
        }
    }
    return true;
}

/**
 * Attempts to remove redundant courses from chosenCourses
 * while keeping all requirements satisfied.
 */
export function finalOptimization(chosenCourses: CourseCoverage[], requirements: ClassCreditRequirement[]) {
    let optimized = true;
    while (optimized) {
        optimized = false;
        // Try removing each course in turn
        for (let i = 0; i < chosenCourses.length; i++) {
            const temp = [ ...chosenCourses ];
            temp.splice(i, 1); // remove course i temporarily

            if (meetsAllCreditRequirements(temp, requirements)) {
                // It's still valid without this course
                chosenCourses.splice(i, 1);
                optimized = true;
                break; // restart from beginning
            }
        }
    }
    return chosenCourses;
}

function factorial(n: number): number {
    return n <= 1 ? 1 : n * factorial(n - 1);
}

function combination(n: number, k: number): number {
    if (k > n) return 0;
    if (k === 0 || k === n) return 1;

    k = Math.min(k, n - k); // Use symmetry property C(n, k) = C(n, n-k)

    let result = 1;
    for (let i = 0; i < k; i++) {
        result = (result * (n - i)) / (i + 1);
    }

    return result;
}

type Constraint = {
    numRequired: number;
    courses: number;
};

export function calculateTotalCombinations(constraints: RequirementComboInfo[]): number {
    if (!constraints.length) return 0;
    return constraints.reduce((total, { numRequired, uniqueCourses }) => {
        return total * combination(uniqueCourses.length, numRequired);
    }, 1);
}
