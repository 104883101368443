import React from 'react';
import { TestPlacement } from '@/api/types';
import {StickyTableStyle, TableStyle} from '@/components/enrollment-history/styled';
import tw from 'twin.macro';

interface Props {
    placements: TestPlacement[];
}

export default ({ placements }: Props) => {
    if (!placements.length) {
        return <p css={tw`ml-4`}>None</p>;
    }

    return (
        <StickyTableStyle>
            <thead>
                <tr>
                    <th>Test Id</th>
                    <th>Component</th>
                    <th>Date</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>

                {
                    placements.map((placement, i) => (
                        <tr key={i}>
                            <td>{placement.testId}</td>
                            <td>{placement.testComponent}</td>
                            <td>{placement.date}</td>
                            <td>{placement.score}</td>
                        </tr>
                    ))
                }

            </tbody>
        </StickyTableStyle>
    );
};
