import React, { useEffect, useState } from 'react';
import getAccounts, { AccountRaw } from '@/api/getAccounts';
import { Spinner } from '../common/Spinner';
import tw from 'twin.macro';
import {AccountsTableStyle, BaseButtonStyle, ResultsTableStyle, TableContainerStyle} from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import updateAccount, {UpdateAccountProps} from '@/api/updateAccount';
import {AccountType} from '@/api/types';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {clearResultsById} from '@/api/simulation/ResultsAPI';
import deleteAccount from '@/api/deleteAccount';
import {PageContainerStyle} from '@/components/degree/styled';
import _ from 'lodash';
import createAccount, {CreateAccountProps} from '@/api/createAccount';

const emptyAccountInput: CreateAccountProps = {
    username: '',
    password: '',
    accountType: 'tester'
};
export default () => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ accounts, setAccounts ] = useState<AccountRaw[]>([]);

    const [ selectedId, setSelectedId ] = useState<string>('');
    const [ editPassword, setEditPassword ] = useState<string>('');
    const [ editAccountType, setEditAccountType ] = useState<AccountType | undefined>(undefined);

    const [ showNewAccountForm, setShowNewAccountForm ] = useState<boolean>(false);
    const [ newAccountInput, setNewAccountInput ] = useState<CreateAccountProps>({
        ...emptyAccountInput
    });

    const fetchAccounts = () => {
        setLoading(true);
        getAccounts()
            .then(results => {
                setAccounts(results);
            })
            .catch(err => console.error(err))
            .then(() => setLoading(false));
    };

    useEffect(() => {
        fetchAccounts();
    }, []);


    const onEdit = (user: AccountRaw) => {
        setSelectedId(user.username);
        setEditAccountType(user.accountType);
        setEditPassword('');
    };

    // quick and dirty implementation. if we need to add more functionality or fields, then create standalone UserEditForm component
    const onUpdate = () => {
        if (!editPassword || !selectedId) return;

        setLoading(true);
        updateAccount({username: selectedId, password: editPassword, accountType: editAccountType})
            .catch(err => console.error(err))
            .then(() => {
                clearEditForm();
                setLoading(false);
            });
    };

    const onCreateAccount = () => {
        if (!newAccountInput.username || !newAccountInput.password) return;

        setLoading(true);
        createAccount(newAccountInput)
            .catch(err => console.error(err))
            .then(() => {
                clearEditForm();
                fetchAccounts();
                // setLoading(false);
            });

    };

    const onDeleteAccount = (username: string) => {
        const r = confirm(`Are you sure want to delete the account: ${username}?`);
        if (r) {
            setLoading(true);
            deleteAccount(username)
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    clearEditForm();
                    fetchAccounts();
                    // setLoading(false);
                });
        }
    };

    const clearEditForm = () => {
        setSelectedId('');
        setEditAccountType(undefined);
        setEditPassword('');
        setNewAccountInput({
            ...emptyAccountInput
        });
        setShowNewAccountForm(false);
    };

    const renderUserRow = (o: AccountRaw, i: number) => {
        return (
            <tr key={o.username}>
                <td><span css={[ tw`text-right` ]}>{i + 1}.</span></td>
                <td>
                    <span>{o.username}</span>
                </td>
                <td>
                    <span>{_.upperFirst(o.accountType)}</span>
                </td>
                <td>
                    <span css={[ tw`block` ]}>•••••••••••</span>
                </td>
                <td>
                    <div css={[ tw` flex flex-row justify-around` ]}>
                        <a onClick={() => onEdit(o)} css={[ tw`mx-3 cursor-pointer text-gray-900 hover:opacity-75` ]}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </a>

                        <a css={[ tw`mx-3 text-red-500 cursor-pointer hover:opacity-75` ]} onClick={() => onDeleteAccount(o.username)}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </a>
                    </div>

                </td>
            </tr>
        );
    };

    const renderUserEditRow = (o: AccountRaw, i: number) => {
        return (
            <tr key={o.username}>
                <td><span css={[ tw`text-right` ]}>{i + 1}.</span></td>
                <td>
                    <span>{o.username}</span>
                </td>
                <td>
                    <select name="userType" value={editAccountType} onChange={e => setEditAccountType(e.target.value as AccountType)}>
                        <option value="admin">Admin</option>
                        <option value="tester">Tester</option>
                    </select>
                </td>
                <td>
                    <input type='password' value={editPassword} onChange={e => setEditPassword(e.target.value)}/>
                </td>
                <td>
                    <a
                        css={[ tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-gray-700` ]}
                        onClick={() => clearEditForm()}
                    >
                        Cancel
                    </a>

                    <a
                        css={[ tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-link` ]}
                        onClick={() => {
                            onUpdate();
                        }}
                    >
                        Update
                    </a>
                </td>
            </tr>
        );
    };

    const renderNewAccountForm = (o: UpdateAccountProps) => {
        return (
            <tr>
                <td></td>
                <td>
                    <input type='text' value={newAccountInput.username}
                        onChange={e => setNewAccountInput({
                                   ...newAccountInput,
                                   username: e.target.value,
                               }
                           )}
                    />
                </td>
                <td>
                    <select
                        name="userType"
                        value={newAccountInput.accountType}
                        onChange={e => setNewAccountInput({
                                ...newAccountInput,
                                accountType: e.target.value as AccountType,
                            }
                        )}
                    >
                        <option value="admin">Admin</option>
                        <option value="tester">Tester</option>
                    </select>
                </td>
                <td>
                    <input type='password' value={newAccountInput.password}
                        onChange={e => setNewAccountInput({
                                   ...newAccountInput,
                                   password: e.target.value,
                               }
                           )}
                    />
                </td>
                <td>
                    <a
                        css={[ tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-gray-700` ]}
                        onClick={() => clearEditForm()}
                    >
                        Cancel
                    </a>

                    <a
                        css={[ tw`mx-3 hover:opacity-75 hover:underline cursor-pointer text-link` ]}
                        onClick={() => {
                            onCreateAccount();
                        }}
                    >
                        Submit
                    </a>
                </td>
            </tr>
        );
    };

    return (
        <PageContainerStyle css={[ tw` ` ]}>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <TableContainerStyle>

                {
                    showNewAccountForm ?
                        <div css={[ tw`flex flex-row mb-4` ]}>
                            <AccountsTableStyle>
                                <tbody>
                                    {renderNewAccountForm(newAccountInput)}
                                </tbody>
                            </AccountsTableStyle>
                        </div>
                        :
                        <div css={[ tw`flex flex-row mb-4` ]}>
                            <BaseButtonStyle type={'button'} onClick={() => setShowNewAccountForm(true)}>
                                Create New Account
                            </BaseButtonStyle>
                        </div>
                }




                <AccountsTableStyle css={[ tw`flex-shrink-0` ]}>
                    <thead>
                        <tr>
                            <th><span>#</span></th>
                            <th>
                                <span>Username</span>
                            </th>
                            <th style={{width: '200px'}}>
                                <span>User Type</span>
                            </th>

                            <th style={{width: '180px'}}>
                                <span>Password</span>
                            </th>

                            <th style={{width: '175px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((o, i) => {
                                if (o.username === selectedId) {
                                    return renderUserEditRow(o, i);
                                } else {
                                    return renderUserRow(o, i);
                                }
                            })
                        }
                    </tbody>
                </AccountsTableStyle>
            </TableContainerStyle>
        </PageContainerStyle>
    );
};
