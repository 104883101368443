import {
    AcademicPlanInfo,
    AcademicSubplanInfo, ConditionMilestone,
    CourseSubjectAndNumber, Milestone, RequirementCondition,
    StudentEnrollment,
    StudentInfo
} from '@/api/types';
import _ from 'lodash';
import {
    CourseList, RuleBlockType,
    RuleClassCredit,
    RuleEntry,
    RuleType,
    ScribedCourse
} from '@/degrees/rules';
import {BlockType} from '@/degrees/common';
import {
    parseCourseSubjectAndNumber,
    parseDegreeProgram,
    parseLatestDeclaredPlanFromStudentInfo,
    parseLatestDeclaredSubplanFromStudentInfo,
} from '@/api/transformers';
import {AuditedRuleEntry, DegreeInfo, StudentInfoWithDegree} from '@/degrees/audit/types';
import {RequirementBlock} from '@/degrees/types';
import {termMappings} from '@/constants/terms';
import {isEnrollmentWritingIntensive} from '@/utils/sectionUtils';
import {RequirementDesignationName} from '@/constants/requirementDesignations';
import {PathStep} from '@/degrees/audit/auditPathRequisite';

export function parseAttributeValue(expression: string): string {
    const value = _.last(expression.split('='))!.trim().replace(/\)$/, '');
    return value;
}

export function requiresAttribute(scribedCourse: ScribedCourse): boolean {
    const expression = scribedCourse[2];
    return !!(expression && expression.match(/attribute/i));
}


export function isWildcardWithAttribute(scribedCourse: ScribedCourse): boolean {
    const expression = scribedCourse[2];
    return !!(scribedCourse[0] === '@' && scribedCourse[1] === '@' && expression && expression.match(/attribute/i));
}

export function isAnyWritingIntensive(scribedCourse: ScribedCourse): boolean {
    return !!(scribedCourse[0] === '@' && scribedCourse[1] === '@' && (scribedCourse[2] || '').match(/(?:wric|writ)/i));
}


export function compareWithAttribute(expression: string, studentEnrollment: StudentEnrollment): boolean {
    // TODO: most likely need to QA to know if this actually works
    const cleaned = expression.replace(/\(with/i, '').replace(/\)/, '');

    const requiredValue = parseAttributeValue(expression);


    // also (WITH WRIT=W)
    const writingIntensiveAttribute = cleaned.match(/(?:wric|writ)/i);
    if (writingIntensiveAttribute) {
        // console.log('writingIntensiveAttribute isEnrollmentWritingIntensive(studentEnrollment): ', isEnrollmentWritingIntensive(studentEnrollment), 'enrollment: ', studentEnrollment);
        return isEnrollmentWritingIntensive(studentEnrollment);
    }

    if (requiredValue === studentEnrollment.requirementDesignation) {
        return true;
    }

    // console.warn(`compareWithAttribute not implemented. cleaned expression: ${cleaned}`);
    return false;
}

function parseCourseNumber(courseNumber: string) {
    const match = courseNumber.match(/^([A-Za-z]*)(\d+)([A-Za-z]*)$/);
    if (!match) throw new Error(`Invalid course number format: ${courseNumber}`);

    const [ _, prefix, number, suffix ] = match;
    return {
        prefix, // Optional letters before the number (e.g., IM)
        number,
        suffix // Optional letters after the number (e.g., H, L)
    };
}

export function isRange(scribeCourseNumber: string): boolean {
    return scribeCourseNumber.indexOf(':') !== -1;
}

export function isPartialWildcard(courseNumber: string) {
    return courseNumber.indexOf('@') !== -1;
}

export function comparePartialWildcard(requiredCourseNumber: string, actualCourseNumber: string): boolean {
    for (let i = 0; i < requiredCourseNumber.length; i++) {

        const requiredPos = requiredCourseNumber[i];
        const actualPos = actualCourseNumber[i];

        if (requiredPos === '@') continue;

        if (requiredPos !== actualPos) return false;
    }
    return true;
}

function scribedComparator(rawScribed: ScribedCourse, courseSubjectAndNumber: CourseSubjectAndNumber, studentEnrollment: StudentEnrollment): boolean {
    const requiredCourse = formatScribedCourse(rawScribed);

    let requiredSubject: string, requiredCourseNumber: string, expression: string;
    try {
        const scribedCourse = parseScribedCourse(requiredCourse);
        requiredSubject = scribedCourse[0];
        requiredCourseNumber = scribedCourse[1];
        expression = scribedCourse[2] || '';

    } catch (e) {
        console.error('rawScribed: ', rawScribed);
        throw e;
    }

    if (expression) {
        const isQualifyingAttribute = compareWithAttribute(expression, studentEnrollment);
        if (!isQualifyingAttribute) return false;
    }

    if (!isRange(requiredCourseNumber)) {
        return wildcardMatch(`${courseSubjectAndNumber.subject} ${courseSubjectAndNumber.courseNumber}`, requiredCourse);
    } else {
        try {

            const [ min, max ] = requiredCourseNumber.split(':');

            const { number: minNum } = parseCourseNumber(min);
            const lowerBound = parseInt(minNum.replace('/@/g', '0'));

            const { number: maxNum } = parseCourseNumber(max);
            const upperBound = parseInt(maxNum.replace('/@/g', '9'));

            const actualCourseNumber = parseInt(courseSubjectAndNumber.courseNumber);
            return actualCourseNumber >= lowerBound && actualCourseNumber <= upperBound;
        } catch (err) {
            console.error('rawScribed: ', rawScribed);
            console.error('courseSubjectAndNumber: ', courseSubjectAndNumber);
            throw err;
        }

    }
}

export function compareQualifyingConstraint(rawScribed: ScribedCourse, studentEnrollment: StudentEnrollment): boolean {
    const scribedCourse = rawScribed;

    const compare: CourseSubjectAndNumber[] = studentEnrollment.transferredAs?.length ? studentEnrollment.transferredAs : [ studentEnrollment.class ];

    for (let i = 0; i < compare.length; i++) {
        const selectedClass = compare[i];
        const isQualifying = scribedComparator(scribedCourse, selectedClass, studentEnrollment);
        if (isQualifying) return true;
    }

    return false;
}

export function getQualifyingEnrollments(courseList: Pick<CourseList, 'except_courses' | 'scribed_courses' | 'list_type'>, enrollments: StudentEnrollment[]): StudentEnrollment[] {

    const { except_courses, scribed_courses } = courseList;

    const qualifyingEnrollments: StudentEnrollment[] = [];


    const qualifiedMap: Record<string, StudentEnrollment> = {};

    _.forEach(enrollments, (enrollment: StudentEnrollment) => {
        _.forEach(scribed_courses, (scribed_list: ScribedCourse[]) => {

            let matched = false;

            _.forEach(scribed_list, (scribedCourse: ScribedCourse) => {
                if (!scribedCourse[0]) return;

                const isQualifying = compareQualifyingConstraint(scribedCourse, enrollment);
                if (isQualifying) {
                    const key = parseCourseSubjectAndNumber(enrollment.transferredAs?.length ? enrollment.transferredAs[0] : enrollment.class);

                    if (!qualifiedMap[key]) {
                        qualifyingEnrollments.push(enrollment);
                        qualifiedMap[key] = enrollment;

                        matched = true;
                    }
                    // return false;
                }
            });

            if (matched) {
                // EDIT: may not necessarily want to exit early because this would likely break wildcard courses such as "9 credits in MAT @"
                // which would imply we want to check the course against multiple enrollments
                // exit out and continue to next enrollment
                // return false;
            }
        });
    });


    _.forEach(except_courses, exclude => {
        _.remove(qualifyingEnrollments, enrollment => {
            try {
                const found = compareQualifyingConstraint(exclude, enrollment);
                return found;
            } catch (e) {
                console.error('courseList: ', courseList);
                throw e;
            }

        });
    });

    return qualifyingEnrollments;
}


export function parseDegreeInfo(studentInfo: StudentInfo): DegreeInfo {
    console.log('parseDegreeInfo studentInfo: ', studentInfo);

    const degreeProgram = parseDegreeProgram(studentInfo);
    console.log('degreeProgram: ', degreeProgram);

    if (!degreeProgram) {
        throw new Error(`Unable to find degreeProgram for student ${studentInfo.studentId}.`);
    }

    const activePlans = _.filter(studentInfo.academicPlans, planInfo => {
        return planInfo.effdt === degreeProgram.effdt && planInfo.studentCareerNum === degreeProgram.studentCareerNum;
    });


    const degreePlan = activePlans.find(planInfo => {
        const split = planInfo.academicPlan.split('-');
        const suffix = _.last(split);
        return suffix && suffix !== 'MIN';
    });

    console.log('degreePlan: ', degreePlan);

    if (!degreePlan) {
        console.warn('Unable to find degreePlan from degreeProgram. Fallback to finding latest declared plan');
    }


    const academicPlan: AcademicPlanInfo | null = degreePlan || parseLatestDeclaredPlanFromStudentInfo(studentInfo);

    const degreeInfo: DegreeInfo = {
        institution: degreeProgram.institution,
        degree: '',
        major: '',
        conc: '',
        latestDeclaredPlan: academicPlan
    };

    if (academicPlan) {

        const split = academicPlan.academicPlan.split('-');
        degreeInfo.degree = split.length > 1 ? (_.last(split) || '') : '';
        degreeInfo.major = split[0];
    }

    const academicSubplan: AcademicSubplanInfo | null = parseLatestDeclaredSubplanFromStudentInfo(studentInfo);
    if (academicSubplan) {
        degreeInfo.conc = academicSubplan.academicSubplan;

        const subplanProgram = studentInfo.academicPrograms.find(p => p.effdt === academicSubplan.effdt);
        degreeInfo.concInstitution = subplanProgram?.institution;
    }

    return degreeInfo;
}

export function formatMajorDescription(academicPlan: AcademicPlanInfo | null):string {
    if (!academicPlan?.plan?.transcriptDescription) return '';
    return `${academicPlan.plan.transcriptDescription} (${academicPlan.academicPlan})`;
}

export function formatDegreeMajor(degreeInfo: DegreeInfo): string {
    return `${degreeInfo.major}-${degreeInfo.degree}`;
}

export function parseDeclaredStudy(blockType: BlockType, studentInfo: StudentInfoWithDegree): string {
    switch (blockType) {
        case "MAJOR":
            return formatDegreeMajor(studentInfo);
        case "CONC":
            return studentInfo.conc;
        // case "OTHER":
        //     return;
        case "MINOR":
            return studentInfo.conc;
        case "DEGREE":
            return studentInfo.degree;
        default:
            throw new Error('unhandled declaredStudy ' + blockType);
    }
}

export function getRuleType(ruleEntry: RuleEntry): keyof RuleEntry {
    const ruleTypes: RuleType[] = [ 'class_credit', 'subset', 'conditional', 'remark', 'group_requirement', 'remark_str', 'block', 'copy_rules', 'blocktype', 'noncourse', 'course_list_rule', 'rule_complete' ];
    for (const ruleType of ruleTypes) {
        if (ruleType in ruleEntry) {
            return ruleType as keyof RuleEntry;
        }
    }
    throw new Error('Invalid RuleEntry: no known rule type found');
}

export function formatRuleEntryLabel(entry: RuleEntry): string {
    const ruleType = getRuleType(entry);
    const rule = (entry as any)[ruleType];

    let labelStr = '';

    if (rule.label) {
        if (typeof rule.label === 'string') {
            labelStr = rule.label;
        }

        if (rule.label.label_str) {
            labelStr = rule.label.label_str;
        }
    }

    return `${labelStr}`;
}

export function formatBlockExtract(block: RequirementBlock): string {
    return `${block.blockType}: ${block.blockValue} (${block.requirementId})`;
}

export function formatScribedCourse(scribed: ScribedCourse): string {
    return scribed.join(' ').replace(/__hidden__/g, '').trim();
}

export function parseScribedCourse(str: string): ScribedCourse {
    try {
        let [ subject, courseNumber, ...rest ] = str.split(' ');
        // strip closing bracket due to parsing bug in requirement blocks

        if (courseNumber[courseNumber.length - 1] === ']') {
            courseNumber = courseNumber.slice(0, -1);
        }

        const expression = rest.join(' ');
        return [ subject, courseNumber, expression ];

    } catch (e) {
        console.error('parseScribedCourse str: ', str);
        throw e;
    }
}


export function stripIgnoredAttributes(scribeCourse: ScribedCourse): ScribedCourse {
    const str = formatScribedCourse(scribeCourse);
    const normalized = parseScribedCourse(str);

    const [ subject, courseNumber, expression ] = normalized;

    let cleanedExpression = expression;

    if (cleanedExpression) {
        cleanedExpression = cleanedExpression
            // .replace(/DWRESIDENT\b.*?\bAND\b\s*/i, '')

            // .replace(/\(with\s*(?:hide\s*)?(?:dwresident|dwterm|dwcredits?|DWGrade(?:Letter|Number|Type)?|dwtransfer(?:Course|School)?)\s*(?:=|<|>|<=|>=|<>)\s*[a-zA-Z0-9]*\s*\)/i, '')
        // .replace(/\(with\s*(?:hide\s*)?(?:DW(?:Age|CourseNumber|Credits?|CreditType|Discipline|Grade(?:Letter|Number|Type)?|Inprogress|Location|Pass(?:Fail)?|Preregistered|Resident|School|Section|Term?|TermType|Title|Transfer(?:Course|School)?))\s*(?:=|<|>|<=|>=|<>)\s*[a-zA-Z0-9]*\s*\)/i, '')


            .replace(/\(with\s*(?:hide\s*)?(?:DW(?:Age|CourseNumber|Credits?|CreditType|Discipline|Grade(?:Letter|Number|Type)?|Inprogress|Location|Pass(?:Fail)?|Preregistered|Resident|School|Section|Term?|TermType|Title|Transfer(?:Course|School)?))\s*(?:=|<|>|<=|>=|<>)\s*[(?:\s*and\s*)a-zA-Z0-9<>=\.]*\s*\)/i, '')
            .replace(/(?:hide\s*)?(?:DW(?:Age|CourseNumber|Credits?|CreditType|Discipline|Grade(?:Letter|Number|Type)?|Inprogress|Location|Pass(?:Fail)?|Preregistered|Resident|School|Section|Term?|TermType|Title|Transfer(?:Course|School)?))\s*(?:=|<|>|<=|>=|<>)\s*[(?:\s*and\s*)a-zA-Z0-9<>=\.]*\s*/i, '')

        ;
    }

    return [ subject, courseNumber, cleanedExpression ];
}



export function formatCourseList(courseList: CourseList): string {

    const exclude = courseList.except_courses.map(scribed => {
        return formatScribedCourse(scribed);
    }).join(', ');

    const courses = courseList.scribed_courses.map((courses, _index) => {

        const courseSet = courses.map(scribed => {
            const courseStr = formatScribedCourse(scribed);
            return courseStr;
        }).join(', ');

        return `${courseSet}`;
    }).join('\n');

    let message = `${courses}`;
    if (exclude) {
        message += `\nExclude: ${exclude}`;
    }
    return message;
}

export function hasPriorDegrees(studentInfo: StudentInfo): boolean {
    return !!getPriorDegrees(studentInfo);
}

export function getPriorDegrees(studentInfo: StudentInfo): AcademicPlanInfo[] {
    return studentInfo.academicPlans.filter(plan => plan.completionTerm);
}

export function formatNumberRange(min: any, max: any): string {
    if (min === max) {
        return min;
    } else if (min && max && min !== max) {
        return `${min}:${max}`;
    } else if (min) {
        return min;
    } else {
        return max;
    }
}

export function formatCompletedDegree(plan: AcademicPlanInfo): string {
    return `${plan.academicPlan} (Completed: ${(termMappings[plan.completionTerm] || '').replace(/term/i, '').trim()})`;
}

export function parseAuditedClassCreditRule(rule: RuleClassCredit): string {
    const messages: string[] = [];

    if (rule.mingpa) {
        messages.push(`Minimum GPA: ${rule.mingpa.number}`);
    }
    if (rule.mingrade) {
        messages.push(`Minimum Grade: ${rule.mingrade.number}`);
    }
    if (rule.minclass) {
        const s = `Min Class: ${rule.minclass.number}\n\tCourse List:\t\n${formatCourseList(rule.minclass.course_list)}`;
        messages.push(s);
    }
    if (rule.mincredit) {
        const s = `Min Credit: ${rule.mincredit.number}\n\tCourse List:\t\n${formatCourseList(rule.mincredit.course_list)}`;
        messages.push(s);
    }
    if (rule.min_credits && rule.max_credits) {
        messages.push(`CREDITS: ${formatNumberRange(rule.min_credits, rule.max_credits)}`);
    }
    if (rule.min_classes && rule.max_classes) {
        messages.push(`CLASSES: ${formatNumberRange(rule.min_classes, rule.max_classes)}`);
    }
    if (rule.course_list) {
        messages.push(`\t IN\t${formatCourseList(rule.course_list)}`);
    }
    const s = messages.join('\n');
    return s;
}

export function ignoreBlockRule(rule: RuleBlockType): AuditedRuleEntry {
    return {
        blocktype: rule,
        completed: true,
        message: '',
        ignored: true,
        requiredClasses: 0,
        requiredCredits: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };
}


 // Utility function to escape special characters in a string so it can be used as a literal in a regex pattern.
export function escapeForRegex(str: string): string {
    if (!str) return '';
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function normalizeDegreeBlockValue(blockValue: string): string {
    if (blockValue === 'BT') return 'BTECH';
    return blockValue;
}

export function formatAttributeKey(attr: string): string {
    return RequirementDesignationName[attr] || attr;
}


export function parseCourse(course: string) {
    const parts: string[] = course.split(' ');
    const subject = parts[0];
    const number = parts[1];
    const attributes = parts.slice(2).join(' ');
    return {
        subject,
        number,
        attributes
    };
}

export function isIdentifiable(course: string): boolean {
    return !course.includes('@') || !!parseCourse(course).attributes;
}

// Checks whether a single knownCourse (like "MAT 101") matches a wildcard (like "@ 1@" or "MAT 1@")
export function wildcardMatch(knownCourse: string, wildcard: string): boolean {
    if (wildcard.toLowerCase().includes('pseudo')) return true;

    const {
        subject: knownSubj,
        number: knownNum
    } = parseCourse(knownCourse);
    const {
        subject: wcSubj,
        number: wcNum
    } = parseCourse(wildcard);

    // Match subject
    // '@' means any subject; otherwise must match exactly (case-insensitive due to inconsistencies in scribe blocks)
    if (wcSubj !== '@' && wcSubj.toUpperCase() !== knownSubj.toUpperCase()) return false;

    // Match course number
    // Replace '@' in the wildcard number with a regex equivalent, then test
    const regexPattern = wcNum.replace(/@/g, '.*'); // e.g. "1@" => "1.*"
    const regex = new RegExp(`^${regexPattern}$`);

    return regex.test(knownNum);
}

// Expand any wildcard courses into actual course strings (based on the full set of knownCourses)
export function expandWildcardCourses(courses: string[], allKnownCourses: string[]) {
    const expanded: string[] = [];
    courses.forEach((c: string) => {
        if (isIdentifiable(c)) {
            expanded.push(c);
        } else {

            const matches = allKnownCourses.filter((kc: string) => wildcardMatch(kc, c));
            if (matches.length) {
                expanded.push(...matches);
            } else {
                expanded.push(c);
            }

        }
    });
    // Remove duplicates
    return [ ...new Set(expanded) ];
}

export function formatChainSequence(chain: PathStep[]): string {
    const s = [ ...chain.map(c => c.course) ].reverse().join(' → ');
    return s;
}

export function sortAndFormatChains(chains: PathStep[][]): string[] {
    const lines = chains.filter(o => o.length >= 2).map(o => formatChainSequence(o));
    return _.uniq(lines).sort();
}

