import React, {useEffect, useState} from 'react';
import {CardBodyStyle, CardHeaderStyle, CardStyle} from '@/components/dashboard/styled';
import tw from 'twin.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';

export type CardWidgetProps = {
    children: React.ReactNode;
    title: string;
    collapsable?: boolean;
    defaultShow?: boolean;
    show?: boolean;
};

const CardWidget = ({ children, title, collapsable, show = true }: CardWidgetProps) => {
    const [ showContent, setShowContent ] = useState<boolean>(collapsable ? !!show : true);

    useEffect(() => {
        setShowContent(!!show);
    }, [ show ]);

    const renderHeader = () => {
        if (collapsable) {
            return (
                <CardHeaderStyle css={[ tw`cursor-pointer` ]} onClick={() => setShowContent(!showContent)}>
                    {title}
                    <a css={[ tw`mx-2` ]} style={{float: 'right'}}>
                        <FontAwesomeIcon icon={showContent ? faAngleUp : faAngleDown } />
                    </a>

                </CardHeaderStyle>
            );
        } else {
            return (
                <CardHeaderStyle>
                    {title}
                </CardHeaderStyle>
            );
        }
    };

    return (
        <CardStyle>
            { renderHeader() }
            <CardBodyStyle
                style={{
                    display: showContent ? 'block' : 'none'
                }}
            >
                {children}
            </CardBodyStyle>
        </CardStyle>
    );
};

export { CardWidget };
