class MaxHeap<T> {
    public heap: { key: T; priority: number }[] = [];

    insert(key: T, priority: number) {
        this.heap.push({ key, priority });
        this.bubbleUp();
    }

    extractMax(): T | null {
        if (this.heap.length === 0) return null;
        if (this.heap.length === 1) return this.heap.pop()!.key;

        const max = this.heap[0].key;
        this.heap[0] = this.heap.pop()!;
        this.bubbleDown();
        return max;
    }

    isEmpty(): boolean {
        return this.heap.length === 0;
    }

    private bubbleUp() {
        let index = this.heap.length - 1;
        while (index > 0) {
            const parentIndex = Math.floor((index - 1) / 2);
            if (this.heap[parentIndex].priority >= this.heap[index].priority) break;
            [ this.heap[parentIndex], this.heap[index] ] = [ this.heap[index], this.heap[parentIndex] ];
            index = parentIndex;
        }
    }

    private bubbleDown() {
        let index = 0;
        const length = this.heap.length;
        while (true) {
            let left = 2 * index + 1;
            let right = 2 * index + 2;
            let largest = index;

            if (left < length && this.heap[left].priority > this.heap[largest].priority) {
                largest = left;
            }
            if (right < length && this.heap[right].priority > this.heap[largest].priority) {
                largest = right;
            }
            if (largest === index) break;

            [ this.heap[index], this.heap[largest] ] = [ this.heap[largest], this.heap[index] ];
            index = largest;
        }
    }
}

export default MaxHeap;
