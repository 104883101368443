import { Term } from '@/api/types';
export const termMappings: {[key:string]: string} = {
    '0386': '1938 Summer Term',
    '0389': '1938 Fall Term',
    '0392': '1939 Spring Term',
    '0396': '1939 Summer Term',
    '0399': '1939 Fall Term',
    '0402': '1940 Spring Term',
    '0406': '1940 Summer Term',
    '0409': '1940 Fall Term',
    '0412': '1941 Spring Term',
    '0416': '1941 Summer Term',
    '0419': '1941 Fall Term',
    '0422': '1942 Spring Term',
    '0426': '1942 Summer Term',
    '0429': '1942 Fall Term',
    '0432': '1943 Spring Term',
    '0436': '1943 Summer Term',
    '0439': '1943 Fall Term',
    '0442': '1944 Spring Term',
    '0446': '1944 Summer Term',
    '0449': '1944 Fall Term',
    '0452': '1945 Spring Term',
    '0456': '1945 Summer Term',
    '0459': '1945 Fall Term',
    '0462': '1946 Spring Term',
    '0466': '1946 Summer Term',
    '0469': '1946 Fall Term',
    '0472': '1947 Spring Term',
    '0476': '1947 Summer Term',
    '0479': '1947 Fall Term',
    '0482': '1948 Spring Term',
    '0486': '1948 Summer Term',
    '0489': '1948 Fall Term',
    '0492': '1949 Spring Term',
    '0496': '1949 Summer Term',
    '0499': '1949 Fall Term',
    '0502': '1950 Spring Term',
    '0506': '1950 Summer Term',
    '0509': '1950 Fall Term',
    '0512': '1951 Spring Term',
    '0516': '1951 Summer Term',
    '0519': '1951 Fall Term',
    '0522': '1952 Spring Term',
    '0526': '1952 Summer Term',
    '0529': '1952 Fall Term',
    '0532': '1953 Spring Term',
    '0536': '1953 Summer Term',
    '0539': '1953 Fall Term',
    '0542': '1954 Spring Term',
    '0546': '1954 Summer Term',
    '0549': '1954 Fall Term',
    '0552': '1955 Spring Term',
    '0556': '1955 Summer Term',
    '0559': '1955 Fall Term',
    '0562': '1956 Spring Term',
    '0566': '1956 Summer Term',
    '0569': '1956 Fall Term',
    '0572': '1957 Spring Term',
    '0576': '1957 Summer Term',
    '0579': '1957 Fall Term',
    '0582': '1958 Spring Term',
    '0586': '1958 Summer Term',
    '0589': '1958 Fall Term',
    '0592': '1959 Spring Term',
    '0596': '1959 Summer Term',
    '0599': '1959 Fall Term',
    '0602': '1960 Spring Term',
    '0606': '1960 Summer Term',
    '0609': '1960 Fall Term',
    '0612': '1961 Spring Term',
    '0616': '1961 Summer Term',
    '0619': '1961 Fall Term',
    '0622': '1962 Spring Term',
    '0626': '1962 Summer Term',
    '0629': '1962 Fall Term',
    '0632': '1963 Spring Term',
    '0636': '1963 Summer Term',
    '0639': '1963 Fall Term',
    '0642': '1964 Spring Term',
    '0646': '1964 Summer Term',
    '0649': '1964 Fall Term',
    '0652': '1965 Spring Term',
    '0656': '1965 Summer Term',
    '0659': '1965 Fall Term',
    '0662': '1966 Spring Term',
    '0666': '1966 Summer Term',
    '0669': '1966 Fall Term',
    '0672': '1967 Spring Term',
    '0676': '1967 Summer Term',
    '0679': '1967 Fall Term',
    '0682': '1968 Spring Term',
    '0686': '1968 Summer Term',
    '0689': '1968 Fall Term',
    '0692': '1969 Spring Term',
    '0696': '1969 Summer Term',
    '0699': '1969 Fall Term',
    '0702': '1970 Spring Term',
    '0706': '1970 Summer Term',
    '0709': '1970 Fall Term',
    '0712': '1971 Spring Term',
    '0716': '1971 Summer Term',
    '0719': '1971 Fall Term',
    '0722': '1972 Spring Term',
    '0726': '1972 Summer Term',
    '0729': '1972 Fall Term',
    '0732': '1973 Spring Term',
    '0736': '1973 Summer Term',
    '0739': '1973 Fall Term',
    '0742': '1974 Spring Term',
    '0746': '1974 Summer Term',
    '0749': '1974 Fall Term',
    '0752': '1975 Spring Term',
    '0756': '1975 Summer Term',
    '0759': '1975 Fall Term',
    '0762': '1976 Spring Term',
    '0766': '1976 Summer Term',
    '0769': '1976 Fall Term',
    '0772': '1977 Spring Term',
    '0776': '1977 Summer Term',
    '0779': '1977 Fall Term',
    '0782': '1978 Spring Term',
    '0786': '1978 Summer Term',
    '0789': '1978 Fall Term',
    '0792': '1979 Spring Term',
    '0796': '1979 Summer Term',
    '0799': '1979 Fall Term',
    '0802': '1980 Spring Term',
    '0806': '1980 Summer Term',
    '0809': '1980 Fall Term',
    '0812': '1981 Spring Term',
    '0816': '1981 Summer Term',
    '0819': '1981 Fall Term',
    '0822': '1982 Spring Term',
    '0826': '1982 Summer Term',
    '0829': '1982 Fall Term',
    '0832': '1983 Spring Term',
    '0836': '1983 Summer Term',
    '0839': '1983 Fall Term',
    '0842': '1984 Spring Term',
    '0846': '1984 Summer Term',
    '0849': '1984 Fall Term',
    '0852': '1985 Spring Term',
    '0856': '1985 Summer Term',
    '0859': '1985 Fall Term',
    '0862': '1986 Spring Term',
    '0866': '1986 Summer Term',
    '0869': '1986 Fall Term',
    '0872': '1987 Spring Term',
    '0876': '1987 Summer Term',
    '0879': '1987 Fall Term',
    '0882': '1988 Spring Term',
    '0886': '1988 Summer Term',
    '0889': '1988 Fall Term',
    '0892': '1989 Spring Term',
    '0896': '1989 Summer Term',
    '0899': '1989 Fall Term',
    '0902': '1990 Spring Term',
    '0906': '1990 Summer Term',
    '0909': '1990 Fall Term',
    '0912': '1991 Spring Term',
    '0916': '1991 Summer Term',
    '0919': '1991 Fall Term',
    '0922': '1992 Spring Term',
    '0926': '1992 Summer Term',
    '0929': '1992 Fall Term',
    '0932': '1993 Spring Term',
    '0936': '1993 Summer Term',
    '0939': '1993 Fall Term',
    '0942': '1994 Spring Term',
    '0946': '1994 Summer Term',
    '0949': '1994 Fall Term',
    '0952': '1995 Spring Term',
    '0956': '1995 Summer Term',
    '0959': '1995 Fall Term',
    '0962': '1996 Spring Term',
    '0966': '1996 Summer Term',
    '0969': '1996 Fall Term',
    '0972': '1997 Spring Term',
    '0976': '1997 Summer Term',
    '0979': '1997 Fall Term',
    '0982': '1998 Spring Term',
    '0986': '1998 Summer Term',
    '0989': '1998 Fall Term',
    '0992': '1999 Spring Term',
    '0996': '1999 Summer Term',
    '0999': '1999 Fall Term',
    1002: '2000 Spring Term',
    1006: '2000 Summer Term',
    1009: '2000 Fall Term',
    1012: '2001 Spring Term',
    1016: '2001 Summer Term',
    1019: '2001 Fall Term',
    1022: '2002 Spring Term',
    1026: '2002 Summer Term',
    1029: '2002 Fall Term',
    1032: '2003 Spring Term',
    1036: '2003 Summer Term',
    1039: '2003 Fall Term',
    1042: '2004 Spring Term',
    1046: '2004 Summer Term',
    1049: '2004 Fall Term',
    1052: '2005 Spring Term',
    1056: '2005 Summer Term',
    1059: '2005 Fall Term',
    1062: '2006 Spring Term',
    1066: '2006 Summer Term',
    1069: '2006 Fall Term',
    1072: '2007 Spring Term',
    1076: '2007 Summer Term',
    1079: '2007 Fall Term',
    1082: '2008 Spring Term',
    1086: '2008 Summer Term',
    1089: '2008 Fall Term',
    1092: '2009 Spring Term',
    1096: '2009 Summer Term',
    1099: '2009 Fall Term',
    1102: '2010 Spring Term',
    1106: '2010 Summer Term',
    1109: '2010 Fall Term',
    1112: '2011 Spring Term',
    1116: '2011 Summer Term',
    1119: '2011 Fall Term',
    1122: '2012 Spring Term',
    1126: '2012 Summer Term',
    1129: '2012 Fall Term',
    1132: '2013 Spring Term',
    1136: '2013 Summer Term',
    1139: '2013 Fall Term',
    1142: '2014 Spring Term',
    1146: '2014 Summer Term',
    1149: '2014 Fall Term',
    1152: '2015 Spring Term',
    1156: '2015 Summer Term',
    1159: '2015 Fall Term',
    1162: '2016 Spring Term',
    1166: '2016 Summer Term',
    1169: '2016 Fall Term',
    1172: '2017 Spring Term',
    1176: '2017 Summer Term',
    1179: '2017 Fall Term',
    1182: '2018 Spring Term',
    1186: '2018 Summer Term',
    1189: '2018 Fall Term',
    1192: '2019 Spring Term',
    1196: '2019 Summer Term',
    1199: '2019 Fall Term',
    1202: '2020 Spring Term',
    1206: '2020 Summer Term',
    1209: '2020 Fall Term',
    1212: '2021 Spring Term',
    1216: '2021 Summer Term',
    1219: '2021 Fall Term',
    1222: '2022 Spring Term',
    1226: '2022 Summer Term',
    1229: '2022 Fall Term',
    1232: '2023 Spring Term',
    1239: '2023 Fall Term',
    1242: '2024 Spring Term',
    1249: '2024 Fall Term',
    1252: '2025 Spring Term',
    1256: '2025 Summer Term',
    1259: '2025 Fall Term',
    1262: '2026 Spring Term',
    1266: '2026 Summer Term',
    1269: '2026 Fall Term',
    1272: '2027 Spring Term',
    1279: '2027 Fall Term',
    1282: '2028 Spring Term',
    1289: '2028 Fall Term',
    1292: '2029 Spring Term',
    1299: '2029 Fall Term',

};

export const summerTerm = {
    id: '1226',
    year: '2023'
};

export const termOverrideName = '2023 Fall Term';

// currentTerm may or may not be the same as activeTerm. Active term is for the semester the simulation will offer courses.
// Current term is the real life current term.
export const currentTerm = {
    id: '1239',
};

export const activeTerm = {
    name: termOverrideName,
    season: 'Fall',
    year: '2023',
    id: '1239',
};

export const termNameYearLowercase = `${activeTerm.season.toLowerCase()} ${activeTerm.year} term`;

export const defaultTerm: Term = {
    // id: '1239', // this is for Fall 2023
    id: '1229', // we are now overriding this to be Fall 2022
    name: termOverrideName,
};

export const isSummerTerm = (strm: string): boolean => strm[3] === '6';
