import { StudentInfoWithDegree } from './types';
import {
    HeaderClassCredit,
    HeaderEntry,
    HeaderMinRes,
} from '../headers';
import {parseHeaderConditional} from '@/degrees/audit/parseHeaderConditional';
import _ from 'lodash';

export type NormalizedHeaderWithMinCredits = {
    min_credits: number;
    source: HeaderClassCredit | HeaderMinRes;
};

function collectNormalizedMinCredits(
    entries: HeaderEntry[],
    degreeInfo: StudentInfoWithDegree
): NormalizedHeaderWithMinCredits[] {
    const result: NormalizedHeaderWithMinCredits[] = [];

    for (const entry of entries) {
        if ('header_class_credit' in entry) {
            const normalized = normalizeHeaderMinCredits(entry.header_class_credit);
            if (normalized) result.push(normalized);
        } else if ('header_minres' in entry) {
            const normalized = normalizeHeaderMinCredits(entry.header_minres);
            if (normalized) result.push(normalized);
        } else if ('conditional' in entry) {
            const branch = parseHeaderConditional(entry.conditional, degreeInfo);
            const nested = collectNormalizedMinCredits(branch, degreeInfo);
            result.push(...nested);
        }
        // ignore other header types
    }

    return result;
}

function normalizeHeaderMinCredits(
    header: HeaderClassCredit | HeaderMinRes
): NormalizedHeaderWithMinCredits | null {
    if ('min_credits' in header && typeof header.min_credits === 'number') {
        return {
            min_credits: header.min_credits,
            source: header
        };
    } else if (
        'minres' in header &&
        header.minres &&
        typeof header.minres.min_credits === 'number'
    ) {
        return {
            min_credits: header.minres.min_credits,
            source: header
        };
    }
    return null;
}

export function parseMinCreditsHeaders(headers: HeaderEntry[], degreeInfo: StudentInfoWithDegree): {
    minCreditsHeaders: NormalizedHeaderWithMinCredits[],
    largest: NormalizedHeaderWithMinCredits | undefined
} {
    // Headers that contain the property `min_credits`. Not to be confused with HeaderMinCredit.
    const minCreditsHeaders: NormalizedHeaderWithMinCredits[] = collectNormalizedMinCredits(headers, degreeInfo);

    const largestMinCreditHeader: NormalizedHeaderWithMinCredits | undefined = _.maxBy(minCreditsHeaders, o => o.min_credits);

    return {
        minCreditsHeaders,
        largest: largestMinCreditHeader
    };
}
