import {ConditionPlacement, RequirementCondition, RequirementConditionPlacement, TestPlacement} from '@/api/types';
import {StudentInfoWithDegree} from '@/degrees/audit/types';
import _ from 'lodash';

export const PLACEMENT_PREFIX = 'PLACEMENT';
export const PLACEMENT_COMPOSITE_KEY_DELIMITER = '__';

export enum ConditionPlacementKeyIndex {
    TestId = 0,
    Component = 1,
    Score = 2,
    Operator = 3
}

export enum StudentPlacementKeyIndex {
    TestId = 0,
    Component = 1,
    Score = 2,
}

export function serializeStudentPlacement(placement: TestPlacement): string {
    return [ PLACEMENT_PREFIX, placement.testId, placement.testComponent, placement.score, '' ].join(PLACEMENT_COMPOSITE_KEY_DELIMITER);
}

export function deserializeStudentPlacement(s: string): TestPlacement {
    const [
        _placementPrefix,
        testId,
        testComponent,
        score,
    ] = s.split(PLACEMENT_COMPOSITE_KEY_DELIMITER);

    const test: TestPlacement = {
        studentId: '',
        date: '',
        letterScore: '',
        testComponent,
        testId,
        score: parseFloat(score),
    };
    return test;
}

export function isRequirementConditionPlacement(rc: RequirementCondition): rc is RequirementConditionPlacement {
    return !!(rc.conditionCode === 'TST' && rc.testId);
}

// condition placements always have an "equals" (EQ) `condition_operator`
export function getQualifyingPlacement(condition: ConditionPlacement, studentPlacements: TestPlacement[]) {
    const samePlacements: TestPlacement[] = _.filter(studentPlacements, o => {
        return o.testId === condition.testId && o.testComponent === condition.testComponent;
    });

    switch (condition.conditionOperator) {
        case "EQ":
            return samePlacements.find(o => o.score === condition.score);
        case "GE":
            return samePlacements.find(o => o.score >= condition.score);
        case "NE":
            return samePlacements.find(o => o.score !== condition.score);
        case "LT":
            return samePlacements.find(o => o.score < condition.score);
        case "LE":
            return samePlacements.find(o => o.score <= condition.score);
        case "GT":
            return samePlacements.find(o => o.score > condition.score);
        default:
            throw new Error(`getQualifyingPlacements: invalid conditionOperator: ${condition.conditionOperator}`);
    }
}
