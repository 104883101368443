import {RequirementGroupDetails} from '@/api/types';
import {
    isRequirementConditionMilestone,
    serializeMilestoneCondition
} from '@/degrees/audit/milestoneUtils';
import _ from 'lodash';
import {
    deserializeStudentPlacement,
    getQualifyingPlacement,
    isRequirementConditionPlacement, PLACEMENT_PREFIX, serializeStudentPlacement
} from '@/degrees/audit/placementUtils';
import AuditTracker from '@/degrees/audit/AuditTracker';
type CourseOffer = {
    requirementGroupDetails: Exclude<RequirementGroupDetails, null>[];
};
export type CourseToken = {
    type: 'COURSE';
    possibleCourses: string[]; // sorted ascending
    neededCourses: number;
};

// Our token for the shunting yard:
export type Token =
    | { type: 'PAREN_OPEN' }
    | { type: 'PAREN_CLOSE' }
    | { type: 'OPERATOR'; op: 'AND' | 'OR' }
    | CourseToken
    | { type: 'NON_COURSE' };

// Our final AST node type:
type AstNode =
    | {
    kind: 'OP';
    op: 'AND' | 'OR';
    left: AstNode;
    right: AstNode;
}
    | {
    kind: 'COURSE';
    // e.g. ["MAT 100", "MAT 101 OR MAT 101H"]
    // or for a CRSW, you might have just ["MAT 2@@"]
    // or you might break it out differently if you expand wildcards
    possibleCourses: string[];
    neededCourses: number;
}
    | { kind: 'NON_COURSE' };


// The result from evaluating the AST:
export interface RequirementsEvalResult {
    totalCount: number; // how many new courses still needed
    selectedCourses: string[]; // which ones we choose
}

export function findMinimalCourseRequirements(
    courseOffer: CourseOffer,
    completedCourses: string[] = []
): RequirementsEvalResult {
    // 1) Gather tokens from the requirement groups (and lines),
    //    using the "nth group’s rqConnect is for connecting to (n−1)th group" rule:
    const tokens = gatherAllTokens(courseOffer, completedCourses);

    // 2) Convert tokens into a full AST
    const fullAst = tokensToAst(tokens);
    if (!fullAst) {
        return {
            totalCount: 0,
            selectedCourses: []
        };
    }

    // 3) Prune subtrees that are purely NON_COURSE
    const prunedAst = pruneNonCourseSubtrees(fullAst);

    if (!prunedAst) {
        return {
            totalCount: 0,
            selectedCourses: []
        };
    }

    // 4) Evaluate the pruned AST, factoring in "completedCourses"
    return evaluatePrunedAst(prunedAst, completedCourses);
}

// ------------------------------------------------
// (1) Gather Tokens
// ------------------------------------------------

function gatherAllTokens(offer: CourseOffer, completedCourses: string[] = []): Token[] {
    const tokens: Token[] = [];

    const studentPlacements = completedCourses
        .filter(s => s.includes(PLACEMENT_PREFIX))
        .map(s => deserializeStudentPlacement(s));

    let isFirstGroup = true;

    for (const gd of offer.requirementGroupDetails) {
        // If not the first group, push gd.connect as an operator
        if (!isFirstGroup && (gd.connect === 'AND' || gd.connect === 'OR')) {
            tokens.push({
                type: 'OPERATOR',
                op: gd.connect
            });
        }

        // If group has an opening parenthesis '('
        if (gd.parenthesis === '(') {
            tokens.push({type: 'PAREN_OPEN'});
        }

        switch (gd.groupLineType) {
            case 'CRSE': {
                if (gd.requisiteType === 'CO') {
                    console.warn('Unhandled "CO" types');
                    // TODO: not handling this for now. may need to revisit
                    tokens.push({type: 'NON_COURSE'});
                    break;
                }

                // Single course => needed=1, possibleCourses=[courseName]
                const cname = gd.courseName || 'Unknown CRSE';
                tokens.push({
                    type: 'COURSE',
                    possibleCourses: [ cname ],
                    neededCourses: 1,
                });
                break;
            }
            case 'CRSW': {
                // wildcard => neededCourses = max( minCoursesRequired, ceil(minUnitsRequired/3) )
                const needed = Math.max(
                    gd.minCoursesRequired || 0,
                    Math.ceil((gd.minUnitsRequired || 0) / 3)
                );
                const cname = gd.courseName || 'Unknown CRSW';
                tokens.push({
                    type: 'COURSE',
                    possibleCourses: [ cname ], // or expand wildcard if you have a known list
                    neededCourses: needed,
                });
                break;
            }
            case 'RQ': {
                // For an RQ group, we parse each requirementLine similarly:
                let isFirstLine = true;

                // safe wrapping requirements array
                tokens.push({type: 'PAREN_OPEN'});

                for (const rl of gd.requirements) {
                    // If not first line, push operator from this line’s rqConnect
                    if (!isFirstLine && (rl.connect === 'AND' || rl.connect === 'OR')) {
                        tokens.push({
                            type: 'OPERATOR',
                            op: rl.connect
                        });
                    }

                    // If line has '(', push PAREN_OPEN
                    if (rl.parenthesis === '(') {
                        tokens.push({type: 'PAREN_OPEN'});
                    }

                    if (rl.lineDetailType === 'CLST' && rl.antirequisite !== 'Y') {
                        // valid course line => gather needed
                        let needed = Math.max(
                            rl.minCoursesRequired || 0,
                            Math.ceil((rl.minUnitsRequired || 0) / 3)
                        );

                        // if both fields happen to be 0 then assume every course in the list is required
                        if (!needed) {
                            needed = rl.coursesNames.length;
                        }


                        // The line can have multiple courses in courses_names,
                        // meaning "X OR Y OR Z"
                        // We sort them ascending so we can pick "lowest" first.
                        const sortedNames = rl.coursesNames.slice();
                        sortedNames.sort();
                        tokens.push({
                            type: 'COURSE',
                            possibleCourses: sortedNames,
                            neededCourses: needed,
                        });
                    } else if (isRequirementConditionMilestone(rl)) {
                        const requiredMilestones: string[] = rl.milestones.map(o => serializeMilestoneCondition(o));
                        const needed: number = rl.conditionConnect === 'AND' ? requiredMilestones.length : 1;

                        const completedMilestones: string[] = _.intersection(requiredMilestones, completedCourses);
                        console.log('completed required milestones: ', completedMilestones);

                        // conditions should only be serialized into possible courses if the student actually meets the condition
                        if (completedMilestones.length >= needed) {
                            const courseToken: CourseToken = {
                                type: 'COURSE',
                                possibleCourses: requiredMilestones,
                                neededCourses: needed,
                            };
                            tokens.push(courseToken);
                            AuditTracker.addSatisfiedConditionToken(courseToken, {
                                requirementGroup: gd.requirementGroup,
                                type: 'Milestone'
                            });

                        } else {
                            tokens.push({type: 'NON_COURSE'});
                        }
                    } else if (isRequirementConditionPlacement(rl)) {
                        // for requiredPlacement substitute with the student placement object so that it matches identically when evaluating AST
                        const qualifyingPlacement = getQualifyingPlacement(rl, studentPlacements);
                        if (qualifyingPlacement) {
                            const courseToken: CourseToken = {
                                type: 'COURSE',
                                possibleCourses: [ serializeStudentPlacement(qualifyingPlacement) ],
                                neededCourses: 1,
                            };
                            tokens.push(courseToken);
                            AuditTracker.addSatisfiedConditionToken(courseToken, {
                                requirementGroup: gd.requirementGroup,
                                type: 'Placement'
                            });

                        } else {
                            tokens.push({type: 'NON_COURSE'});
                        }
                    } else {
                        // either non-CLST or antireq => treat as NON_COURSE
                        tokens.push({type: 'NON_COURSE'});
                    }

                    // If line has ')', push PAREN_CLOSE
                    if (rl.parenthesis === ')') {
                        tokens.push({type: 'PAREN_CLOSE'});
                    }

                    isFirstLine = false;
                }

                // safe wrapping requirements array
                tokens.push({type: 'PAREN_CLOSE'});

                break;
            }
            case 'COND': {
                if (isRequirementConditionMilestone(gd)) {
                    const requiredMilestones = gd.milestones.map(o => serializeMilestoneCondition(o));
                    const needed = gd.conditionConnect === 'AND' ? requiredMilestones.length : 1;


                    const completedMilestones: string[] = _.intersection(requiredMilestones, completedCourses);
                    console.log('completed required milestones: ', completedMilestones);

                    // conditions should only be serialized into possible courses if the student actually meets the condition
                    if (completedMilestones.length >= needed) {
                        const courseToken: CourseToken = {
                            type: 'COURSE',
                            possibleCourses: requiredMilestones,
                            neededCourses: needed,
                        };
                        tokens.push(courseToken);
                        AuditTracker.addSatisfiedConditionToken(courseToken, {
                            requirementGroup: gd.requirementGroup,
                            type: 'Milestone'
                        });

                    } else {
                        tokens.push({type: 'NON_COURSE'});
                    }

                } else if (isRequirementConditionPlacement(gd)) {
                    // for requiredPlacement substitute with the student placement object so that it matches identically when evaluating AST
                    const qualifyingPlacement = getQualifyingPlacement(gd, studentPlacements);
                    if (qualifyingPlacement) {
                        const courseToken: CourseToken = {
                            type: 'COURSE',
                            possibleCourses: [ serializeStudentPlacement(qualifyingPlacement) ],
                            neededCourses: 1,
                        };
                        tokens.push(courseToken);
                        AuditTracker.addSatisfiedConditionToken(courseToken, {
                            requirementGroup: gd.requirementGroup,
                            type: 'Placement'
                        });

                    } else {
                        tokens.push({type: 'NON_COURSE'});
                    }

                } else {
                    // purely non-course
                    tokens.push({type: 'NON_COURSE'});
                }


                break;
            }
        }

        // If group has ')', push PAREN_CLOSE
        if (gd.parenthesis === ')') {
            tokens.push({type: 'PAREN_CLOSE'});
        }

        isFirstGroup = false;
    }

    return tokens;
}

// ------------------------------------------------
// (2) Convert Tokens -> AST (Shunting Yard)
// ------------------------------------------------
function tokensToAst(tokens: Token[]): AstNode | null {
    const outputQueue: Token[] = [];
    const opStack: Token[] = [];

    function popUntilOpenParen() {
        while (
            opStack.length &&
            opStack[opStack.length - 1].type !== 'PAREN_OPEN'
            ) {
            outputQueue.push(opStack.pop()!);
        }
        if (
            opStack.length &&
            opStack[opStack.length - 1].type === 'PAREN_OPEN'
        ) {
            opStack.pop(); // remove '('
        }
    }

    for (const tk of tokens) {
        switch (tk.type) {
            case 'COURSE':
            case 'NON_COURSE':
                outputQueue.push(tk);
                break;
            case 'OPERATOR': {
                // left-associative AND/OR with same precedence
                while (opStack.length) {
                    const top = opStack[opStack.length - 1];
                    if (top.type !== 'OPERATOR') break;
                    outputQueue.push(opStack.pop()!);
                }
                opStack.push(tk);
                break;
            }
            case 'PAREN_OPEN':
                opStack.push(tk);
                break;
            case 'PAREN_CLOSE':
                popUntilOpenParen();
                break;
        }
    }

    while (opStack.length) {
        outputQueue.push(opStack.pop()!);
    }

    // Build the AST from RPN
    const nodeStack: AstNode[] = [];

    for (const tk of outputQueue) {
        if (tk.type === 'COURSE') {
            nodeStack.push({
                kind: 'COURSE',
                neededCourses: tk.neededCourses,
                possibleCourses: tk.possibleCourses,
            });
        } else if (tk.type === 'NON_COURSE') {
            nodeStack.push({kind: 'NON_COURSE'});
        } else if (tk.type === 'OPERATOR') {
            const right = nodeStack.pop();
            const left = nodeStack.pop();
            if (!left || !right) return null; // malformed
            nodeStack.push({
                kind: 'OP',
                op: tk.op,
                left,
                right
            });
        }
    }

    if (!nodeStack.length) return null;
    return nodeStack.pop()!;
}

// ------------------------------------------------
// (3) Prune Non-Course Subtrees
// ------------------------------------------------
function pruneNonCourseSubtrees(node: AstNode): AstNode | null {
    if (node.kind === 'COURSE') return node; // keep
    if (node.kind === 'NON_COURSE') return null; // discard

    // node.kind === "OP"
    const L = pruneNonCourseSubtrees(node.left);
    const R = pruneNonCourseSubtrees(node.right);
    if (!L && !R) return null;
    if (!L) return R;
    if (!R) return L;
    return {
        kind: 'OP',
        op: node.op,
        left: L,
        right: R
    };
}

// ------------------------------------------------
// (4) Evaluate Pruned AST, factoring "completedCourses"
// ------------------------------------------------
function evaluatePrunedAst(
    node: AstNode,
    completedCourses: string[]
): RequirementsEvalResult {
    switch (node.kind) {
        case 'COURSE': {
            // Count how many are already completed
            const completedSet = new Set(completedCourses.map(c => c.toUpperCase()));
            const possible = node.possibleCourses; // typically already sorted
            const alreadyCompletedCount = possible.filter(
                c => completedSet.has(c.toUpperCase())
            ).length;

            if (alreadyCompletedCount >= node.neededCourses) {
                // This requirement is already fully satisfied => no new courses
                return {
                    totalCount: 0,
                    selectedCourses: []
                };
            }
            // Otherwise, we need (neededCourses - alreadyCompletedCount)
            const stillNeeded = node.neededCourses - alreadyCompletedCount;
            // Pick from the uncompleted portion, in ascending order
            const uncompleted = possible.filter(
                c => !completedSet.has(c.toUpperCase())
            );
            const chosen = uncompleted.slice(0, stillNeeded);
            return {
                totalCount: chosen.length,
                selectedCourses: chosen
            };
        }

        case 'NON_COURSE':
            // Should not happen after pruning
            return {
                totalCount: 0,
                selectedCourses: []
            };

        case 'OP': {
            const left = evaluatePrunedAst(node.left, completedCourses);
            const right = evaluatePrunedAst(node.right, completedCourses);
            if (node.op === 'AND') {
                return {
                    totalCount: left.totalCount + right.totalCount,
                    selectedCourses: [ ...left.selectedCourses, ...right.selectedCourses ],
                };
            } else {
                // OR => pick minimal
                if (left.totalCount < right.totalCount) {
                    return left;
                } else if (right.totalCount < left.totalCount) {
                    return right;
                } else {
                    // tie => pick lexicographically smaller set
                    const leftStr = left.selectedCourses.join(',');
                    const rightStr = right.selectedCourses.join(',');
                    return (leftStr < rightStr) ? left : right;
                }
            }
        }
    }
}
