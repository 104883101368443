import {RuleClassCredit} from '@/degrees/rules';
import {StudentEnrollment, StudentInfo} from '@/api/types';
import {getQualifyingEnrollments, stripIgnoredAttributes} from '@/degrees/audit/utils';
import _ from 'lodash';
import {AuditedRuleEntry, AuditStatus} from '@/degrees/audit/types';

export default (rule: RuleClassCredit, { enrollments }: StudentInfo): AuditedRuleEntry => {


    rule.course_list.scribed_courses.forEach(courses => {
        for (let i = courses.length - 1; i >= 0; i--) {

            if (!courses[i][0]) {
                // if empty string then remove
                courses.splice(i, 1);

            } else {
                courses[i] = stripIgnoredAttributes(courses[i]);
            }
        }
    });

    const qualifyingEnrollmentsUncapped = getQualifyingEnrollments(rule.course_list, enrollments);

    let qualifyingEnrollments = _.cloneDeep(qualifyingEnrollmentsUncapped);


    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        enrollments: [],
        requiredCredits: 0,
        requiredClasses: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };


    const minGrade = _.toNumber(rule.mingrade);
    if (minGrade) {
        qualifyingEnrollments = qualifyingEnrollments.filter(o => o.gradePoints && o.gradePoints > minGrade);

    }

    const minGpa = _.toNumber(rule.mingpa);
    if (minGpa) {
        const avgGpa = _.meanBy(qualifyingEnrollments, 'gradePoints');
        if (avgGpa < minGpa) {
            auditStatus.message = 'Did not meet minimum gpa';
            auditStatus.completed = false;
        }
    }


    if (minGrade) {
        const lowestGrade = _.minBy<StudentEnrollment>(qualifyingEnrollments, o => o.gradePoints!)!.gradePoints!;
        if (lowestGrade < minGrade) {
            auditStatus.message = 'Did not meet minimum grade for all qualifying courses';
            auditStatus.completed = false;
        }
    }


    const maxCredits = rule.max_credits;
    const minCredits = rule.min_credits;

    if (rule.max_classes) {
        // console.warn('limiting max classes qualifying enrollments');
        // prioritize grabbing courses with the most units to allow handling the min/max credit checks, which we'll then
        // reduce down later.
        qualifyingEnrollments = _.orderBy<StudentEnrollment>(qualifyingEnrollments, [ 'unitsEarned' ], [ 'desc' ]).slice(0, rule.max_classes);
    }

    if (maxCredits) {
        let qualifyingCredits = 0;

        const allowedWithinCreditsMax = qualifyingEnrollments.filter(o => {

            if (minCredits && qualifyingCredits < minCredits) {
                qualifyingCredits = qualifyingCredits + o.unitsEarned;
                return true;

            } else if (qualifyingCredits < maxCredits) {
                qualifyingCredits = qualifyingCredits + o.unitsEarned;
                return true;
            }

            return false;
        });
        qualifyingEnrollments = allowedWithinCreditsMax;
    }

    if (rule.min_classes && rule.min_classes > qualifyingEnrollments.length) {
        auditStatus.message = 'Did not meet minimum classes';
        auditStatus.completed = false;
    } else if (minCredits && minCredits > _.sumBy(qualifyingEnrollments, 'unitsEarned')) {
        auditStatus.message = 'Did not meet minimum credits';
        auditStatus.completed = false;
    }

    auditStatus.enrollments = qualifyingEnrollments;
    auditStatus.requiredClasses = rule.min_classes || 0;
    auditStatus.requiredCredits = rule.min_credits || 0;
    auditStatus.completedClasses = _.clamp(qualifyingEnrollments.length, 0, auditStatus.requiredClasses);
    // auditStatus.completedCredits = _.clamp(_.sumBy(qualifyingEnrollments, 'unitsEarned') || 0, 0, auditStatus.requiredCredits);
    auditStatus.completedCredits = _.sumBy(qualifyingEnrollments, 'unitsEarned');

    auditStatus.requiredClassCreditRules.push({
        rule,
        completed: !!(auditStatus.completed),
        requiredClasses: auditStatus.requiredClasses,
        requiredCredits: auditStatus.requiredCredits,
        completedClasses: auditStatus.completedClasses,
        completedCredits: auditStatus.completedCredits,
    });
    return {
        class_credit: rule,
        ...auditStatus
    };
};
