import {gql} from '@apollo/client';
import graphClient from '@/api/graph';
import {RequirementBlock} from '@/degrees/types';
import {BlockType} from '@/degrees/common';
import * as fragments from '@/api/graphql/fragments';
import {convertToSnakeCase} from '@/api/graphUtils';


export const QUERY_BLOCK = gql`
query getRequirementBlocksByTypeValue(
    $filter: requirement_blocks_bool_exp,
) {
    items:requirement_blocks(
        where:$filter,
        order_by: {title:asc}
    ) {
        ...requirementBlock
    }
}
${fragments.requirementBlock}
`;

export const GET_BLOCK_BY_TYPE_VALUE = gql`
query getRequirementBlocksByTypeValue($institution: String!, $blockType:String!, $blockValue:String!) {
    items:requirement_blocks(where:{
        institution:{
            _eq:$institution
        }
      	block_type:{
          _eq:$blockType
        }
      	block_value:{
          _eq:$blockValue
        }
        _or: [
          	{active_valid: {_eq:true}},
          	{allow_expired: {_eq:true}}
        ]
        
    }, order_by: {title:asc}) {
        ...requirementBlock
    }
}
${fragments.requirementBlock}
`;

export const GET_BLOCK_BY_ID = gql`
query getRequirementBlocksById($institution: String!, $id:String!, $onlyActiveValid:Boolean!) {
    items:requirement_blocks(where:{
        institution:{
            _eq:$institution
        }
      	requirement_id:{
          _eq:$id
        }
        _or: [
          	{active_valid: {_eq:$onlyActiveValid}},
          	{allow_expired: {_eq:true}}
        ]
        
    }, order_by: {title:asc}) {
        ...requirementBlock
    }
}
${fragments.requirementBlock}
`;

interface Props {
    institution: string;
    blockType: BlockType,
    blockValue: string;
}


export const queryRequirementBlock = async (props: Record<string, any>): Promise<RequirementBlock> => {
    // props.
    //
    //     "institution": {"_eq": "MEC01"}

    let filter: Record<string, any> = {};
    Object.entries(props).forEach(([ key, value ]) => {
        filter[key] = {
            _eq: value
        };
    });


    filter = convertToSnakeCase(filter);
    console.log('filter: ', filter);
    const results = await graphClient.query({
        query: QUERY_BLOCK,
        variables: {
            filter,
        },
        fetchPolicy: 'network-only'
    });

    return results.data.items[0];
};
export const getRequirementBlock = async (props: Props): Promise<RequirementBlock> => {
    const results = await graphClient.query({
        query: GET_BLOCK_BY_TYPE_VALUE,
        variables: props,
        fetchPolicy: 'network-only'
    });

    const block = results.data.items[0] as RequirementBlock;
    return block;
};

interface ByIdProps {
    institution: string;
    id: string;
}

export const getRequirementBlockById = async (props: ByIdProps, onlyActiveValid = true): Promise<RequirementBlock> => {
    const results = await graphClient.query({
        query: GET_BLOCK_BY_ID,
        variables: {
            ...props,
            onlyActiveValid
        },
        fetchPolicy: 'network-only'
    });

    return results.data.items[0];
};

