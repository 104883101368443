import React from 'react';
import { parseClassFromStudentEnrollmentHistory } from '@/api/transformers';
import { termMappings } from '@/constants/terms';
import { InstitutionName } from '@/constants/institutions';
import tw from 'twin.macro';
import { CourseComponentName } from '@/constants/courseComponents';
import { StudentEnrollment } from '@/api/types';
import { StickyTableStyle } from '@/components/enrollment-history/styled';
import {isEnrollmentWritingIntensive} from '@/utils/sectionUtils';

interface Props {
    enrollments?: StudentEnrollment[];
}

export default ({ enrollments = [] }: Props) => {
    if (!enrollments.length) {
        return <p css={[ tw`ml-4` ]}>None</p>;
    }
    return (
        <StickyTableStyle>
            <thead>
                <tr>
                    <th>Term</th>
                    <th>Institution</th>
                    <th>Course</th>
                    <th>Attributes</th>
                    <th>Component</th>
                    {/* <th>Units Taken</th> */}
                    <th>Units Earned</th>
                    <th>Grade</th>
                    <th>Grade Points</th>
                    <th>Sim</th>
                </tr>
            </thead>
            <tbody>
                {enrollments.map((en: StudentEnrollment) => {
                    try {
                        return (
                            <tr key={en.institution + en.strm + en.classId}>

                                <td><span>{termMappings[en.strm].replace(' Term', '')}</span></td>

                                <td><span css={[ tw`` ]}>{InstitutionName[en.institution]}</span></td>

                                <td>
                                    <div>
                                        <div>
                                            <span>{parseClassFromStudentEnrollmentHistory(en)}</span>
                                            {
                                                en.transferredAs ?
                                                    <span css={[ tw`ml-2 font-medium text-green-600` ]}>({en.transferredAs.map((o: any) => `${o.subject} ${o.courseNumber}`).join(', ')})</span>
                                                    : null
                                            }
                                            <span css={[ tw`mx-1 text-xs text-gray-300` ]}>{en.class.courseId} {en.class.classId}</span>
                                        </div>
                                        <div>
                                            {/* <span css={[ tw`mx-1 text-xs text-gray-300` ]}>{en.class.courseId} {en.class.classId}</span> */}
                                        </div>



                                    </div>

                                </td>
                                <td>
                                    {
                                        isEnrollmentWritingIntensive(en) ?
                                            <span className={'tag-block'}>Writing Intensive</span> : null
                                    }

                                    <span className={en.requirementDesignation ? 'tag-block' : ''}>{en.requirementDesignation}</span>
                                    {/* {en.class.catalog?.requirementDesignation ? <span className={'tag-block'}>{en.class.catalog.requirementDesignation}</span> : null} */}
                                </td>

                                <td><span>{CourseComponentName[en.class.courseComponent]}</span></td>


                                {/* <td css={[ tw`text-center` ]}><span>{en.unitsTaken ? en.unitsTaken.toFixed(2) : ''}</span></td> */}

                                <td css={[ tw`text-center` ]}><span>{en.earnCredit === 'Y' && en.unitsEarned ? en.unitsEarned.toFixed(2) : ''}</span></td>
                                <td css={[ tw`text-left` ]}><span css={[ tw`pl-2` ]}>{en.grade}</span></td>
                                <td css={[ tw`text-center` ]}><span>{en.gradePoints ? en.gradePoints.toFixed(2) : ''}</span></td>

                                <td><span css={[ tw`text-center` ]}>{en.simulated ? 'Y' : ''}</span></td>


                            </tr>
                        );
                    } catch (err) {
                        console.error('en: ', en);
                        throw err;
                    }

                })}
            </tbody>
        </StickyTableStyle>
    );
};
