import http from '@/api/http';
import {AccountType} from '@/api/types';

export type AccountRaw = {
    username: string;
    accountType: AccountType;
};

export default async (simOrTTD: 'ttd' | 'sim' = 'ttd'): Promise<AccountRaw[]> => {

    const endpoint = simOrTTD === 'ttd' ? '/ttd/accounts' : '/accounts';
    const { data } = await http.get(endpoint);
    console.log('data: ', data);
    return data.results || [];
};
