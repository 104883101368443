import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import * as fragments from '@/api/graphql/fragments';
import _ from 'lodash';
import { RequirementGroupDetails } from '@/api/types';
import {findMinimalCourseRequirements, RequirementsEvalResult} from '@/degrees/audit/findMinimumCourseRequirements';
import {isRange} from '@/degrees/audit/utils';
import { InstitutionName } from '@/constants/institutions';
import {MILESTONE_PREFIX} from '@/degrees/audit/milestoneUtils';
import {PLACEMENT_PREFIX} from '@/degrees/audit/placementUtils';

export const GET_COURSES = gql`
query getCourses(
    $filter: custom_crse_offer_view_bool_exp,
) {
    items: custom_crse_offer_view(
        where:$filter,
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        institution
        units: units_acad_prog
        academicGroup: acad_group
    }
}
`;

export const GET_COURSES_WITH_REQUISITES = gql`
query getCourses(
    $filter: custom_crse_offer_view_bool_exp,
    $limit: Int
) {
    items: custom_crse_offer_view(
        where:$filter,
        limit:$limit,
    ) {
        courseId: crse_id
        subject
        courseNumber: catalog_nbr
        institution
        units: units_acad_prog
        academicGroup: acad_group
        
        requirementGroupDetails: requirement_group_details(
            order_by: {rq_grp_line_nbr: asc}
         ){
             requirementGroup: rqrmnt_group
            requirementId: requirement
            requisiteType: requisite_type
            groupLineType: rq_grp_line_type
            groupLineNumber: rq_grp_line_nbr
            lineKeyNumber: rq_line_key_nbr
            connect: rq_connect
            parenthesis
            description: descr
            
            minUnitsRequired: min_units_reqd
            minCoursesRequired: min_crses_reqd
            courseName: course_name
            milestones
            conditionConnectType: condition_connect_type
            conditionCode: condition_code
            conditionOperator: condition_operator
            conditionData: condition_data
            testId: test_id
            testComponent: test_component
            score
            
            requirements(order_by: {rq_line_key_nbr: asc}) {
                requirementId: requirement
                effdt
                lineNumber: line_number
                lineKeyNumber: rq_line_key_nbr
                lineDetailType: rq_line_det_type
                description: descr
                connect: rq_connect
                parenthesis
                minCoursesRequired: min_crses_reqd
                minUnitsRequired: min_units_reqd
                coursesNames: courses_names
                antirequisite: anti_requisite
                milestones
                conditionConnectType: condition_connect_type
                conditionCode: condition_code
                conditionOperator: condition_operator
                conditionData: condition_data
                testId: test_id
                testComponent: test_component
                score
            }
        }

        requirementGroup: rqrmnt_group
        courseComponent: ssr_component
        requirementDesignation: rqmnt_designtn
    }
}
${fragments.requirementGroupDetails}
`;

export type CourseExtract = {
    units: number;
    courseId: string;
    subject: string;
    courseNumber: string;
    institution: string;
    academicGroup: string;

    // if transferring in
    sendingCourseId?: string;

    courseComponent: string;
    requirementDesignation: string;

};

export type CourseExtractWithRequisites = CourseExtract & {
    requirementGroupDetails: Exclude<RequirementGroupDetails, null>[];
    requirementGroup: string;
    courseTitle?: string;

    requisites?: CourseExtractWithRequisites[];

    flattenRequisites?: string[];
    totalRequisiteUnits?: number;


    // after auditing
    courseName?: string;
    completed?: boolean;
    longestSequences?: string[];
};

export const getCoursesByIds = async (courseIds: string[]): Promise<CourseExtract[]> => {
    if (!courseIds?.length) {
        return [];
    }
    const results = await graphClient.query({
        query: GET_COURSES,
        variables: {
            filter: {
                crse_id: {
                    _in: courseIds
                }
            }
        },
        fetchPolicy: 'cache-first'
    });

    return results.data.items || [];
};

export const getCoursesByScribedFormat = async (_courses: string[], institution: string): Promise<CourseExtractWithRequisites[]> => {
    const courses = _.filter(_courses, c => !c.includes(MILESTONE_PREFIX) && !c.includes(PLACEMENT_PREFIX));

    if (!courses?.length) return [];

    const j = courses.join('');
    let hasWildcard = j.includes('@');
    let hasRange = j.includes(':');

    const filter = {
        _or: courses.map(course => {
            const [ subject, courseNumber ] = course.split(' ');

            let subjectFilter = {};
            if (subject !== '@') {
                subjectFilter = {
                    subject: {_ilike: subject}
                };
            }

            let courseNumberFilter = {};
            if (isRange(courseNumber)) {
                const [ gte, lte ] = courseNumber.split(':');
                courseNumberFilter = {
                    catalog_nbr: {
                        _gte: gte,
                        _lte: lte
                    },
                };
                console.warn('isRange, normalized courseNumberFilter: ', courseNumberFilter);
            } else {
                courseNumberFilter = courseNumber.includes('@')
                    ? {
                        catalog_nbr: {_like: courseNumber.replace('@', '%')}
                    }
                    : {
                        catalog_nbr: {_eq: courseNumber}
                    };
            }

            const _andFilter = [ subjectFilter, courseNumberFilter, { institution: { _eq: institution } } ];
            _.remove(_andFilter, _.isEmpty);

            return {
                _and: _andFilter,
            };
        }),
    };

    const results = await graphClient.query({
        query: GET_COURSES_WITH_REQUISITES,
        variables: {
            filter,
            limit: hasWildcard || hasRange ? 500 : courses.length,
        },
        fetchPolicy: 'cache-first'
    });

    return _.cloneDeep(results.data.items) || [];
};

const CALL_LIMIT = 5; // Maximum allowed calls for the same arguments
const TIME_FRAME = 10 * 1000; // Time frame in milliseconds (e.g., 10 seconds)
const callHistory = new Map<string, { count: number, timestamp: number }>();

let DEV_BREAKPOINT: number;
let BREAKPOINT_THRESHOLD = 5 * 1000;

export function setDevBreakpointTimestamp() {
    DEV_BREAKPOINT = Date.now();
    console.warn('Called setDevBreakpointTimestamp! DEV_BREAKPOINT: ', DEV_BREAKPOINT);

}

export function isBreakpointExpired(): boolean {
    const now = Date.now();
    return !!(DEV_BREAKPOINT && now - DEV_BREAKPOINT > BREAKPOINT_THRESHOLD);
}


const courseCache: {
    [institution: string] : {
        [courseName: string]: CourseExtractWithRequisites;
    }
} = {};

const wildcardCache: {
    [institution: string] : {
        [courseName: string]: string[];
    }
} = {};

Object.keys(InstitutionName).forEach(institution => {
   courseCache[institution] = {};
   wildcardCache[institution] = {};
});

let cacheReady: boolean = false;
export const prefetchCourseCache = async () => {
    if (cacheReady) return;

    const courses = await getCoursesByScribedFormat([ 'MATH 12400' ], 'HTR01');
    for (const course of courses) {
        const key: string = `${course.subject} ${course.courseNumber}`;
        courseCache[course.institution][key] = course;
    }
    cacheReady = true;
};

export const getCoursesWithAllRequisites = async (courses: string[], institution: string, completedCourseNames: string[]): Promise<CourseExtractWithRequisites[]> => {
    // console.log('getCoursesWithAllRequisites completedCourseNames: ', completedCourseNames);

    if (isBreakpointExpired()) {
        throw new Error('dev_breakpoint expired');
    }

    const courseExtracts: CourseExtractWithRequisites[] = [];
    const coursesToFetch: string[] = [];

    for (const courseName of courses) {
        try {
            if (courseCache[institution][courseName]) {
                // console.log(`[Cache]: retrieving existing cache for ${courseName}`)
                courseExtracts.push(courseCache[institution][courseName]);
            } else if (wildcardCache[institution][courseName]) {
                const subject = courseName.split(' ')[0];

                const existingKeys: string[] = Object.keys(courseCache[institution]).filter(name => name.includes(subject));
                const cacheExtracts: CourseExtractWithRequisites[] = existingKeys.map(key => {

                    // console.log(`[Wildcard Cache]: retrieving existing wildcard cache for ${courseName}`)
                    return courseCache[institution][key];
                });

                if (cacheExtracts.length) {
                    courseExtracts.push(...cacheExtracts);
                } else {
                    coursesToFetch.push(courseName);
                }
            } else {
                coursesToFetch.push(courseName);
            }
        } catch (err) {
            console.error('institution: ', institution);
            console.error('courseName: ', courseName);
            throw err;
        }

    }

    if (coursesToFetch.length) {
        const key = coursesToFetch.join();
        const now = Date.now();

        // Cleanup old entries
        for (const [ arg, record ] of callHistory.entries()) {
            if (now - record.timestamp > TIME_FRAME) {
                callHistory.delete(arg);
            }
        }
        // Check call history
        if (callHistory.has(key)) {
            const record = callHistory.get(key)!;
            if (record.count >= CALL_LIMIT) {
                console.warn(`Aborting: ${key} exceeded ${CALL_LIMIT} calls within timeframe`);
                console.log('wildcardCache: ', wildcardCache);
                return Promise.reject(new Error("Too many recursive calls detected."));
            }
            record.count += 1;
        } else {
            callHistory.set(key, { count: 1, timestamp: now });
        }

        const fetchedCourses: CourseExtractWithRequisites[] = await getCoursesByScribedFormat(coursesToFetch, institution);

        courseExtracts.push(...fetchedCourses);
    }

    const wildcardSubjects = coursesToFetch.filter(c => c.split(' ')[1] === '@');

    for (let extract of courseExtracts) {
        const extractName = `${extract.subject} ${extract.courseNumber}`;

        courseCache[institution][extractName] = extract;

        const coveredByWildcard = wildcardSubjects.find(wc => {
            return wc.split(' ')[0] === extract.subject;
        });

        if (coveredByWildcard) {
            if (wildcardCache[institution][coveredByWildcard]) {
                wildcardCache[institution][coveredByWildcard].push(extractName);
            } else {
                wildcardCache[institution][coveredByWildcard] = [ extractName ];
            }
        }

        // console.log('courseCache: ', courseCache);

        const overridingCorequisites = _.remove(extract.requirementGroupDetails, o => {
           return o.groupLineType && o.requisiteType === 'CO' && (o.courseName || '').split(' ')[0] === extract.subject;
        });
        if (overridingCorequisites.length) {
            console.log('overridingCorequisites: ', overridingCorequisites);
        }

        const courseRequirements: RequirementsEvalResult = findMinimalCourseRequirements(extract, completedCourseNames);
        const selected = courseRequirements.selectedCourses.join(', ');

        if (courseRequirements.selectedCourses.length) {
            console.log(`${extractName} courseRequirements: ${selected}`);
        }

        if (selected.includes(`${extract.subject} ${extract.courseNumber}`)) {
            console.warn(`Self referencing requisites: ${extractName} lists itself among requirement groups. Ignoring all requisites for this course.`);
            courseRequirements.selectedCourses = [];
        }

        if (extractName === 'CHEM. 2200') {
            _.remove(courseRequirements.selectedCourses, o => {
                return o === 'CHEM. 2201';
            });
        }

        if (courseRequirements.selectedCourses.length) {
            const requisites: CourseExtractWithRequisites[] = [];
            const results = await getCoursesWithAllRequisites(courseRequirements.selectedCourses, institution, completedCourseNames);
            requisites.push(...results);
            extract.requisites = requisites;
        }

        courseCache[institution][extractName] = extract;
    }

    return courseExtracts;
};

export type CourseSequence = CourseExtractWithRequisites & {
    sequenceLength: number;
    requisites: CourseSequence[];
};
