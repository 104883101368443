import React, { useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import ClassDetailsContainer from '@/components/class-detail/ClassDetailsContainer';
import DegreeContainerV2 from '@/components/degree/DegreeContainerV2';
import VersionInfo from '@/components/VersionInfo';

export default () => {
    const prevLocation = useRef<string>('');
    const location = useLocation();

    useEffect(() => {
        if (prevLocation.current !== location.pathname) {
            window.scrollTo(0, 0);
            prevLocation.current = location.pathname;
        }

    }, [ location ]);

    return (
        <div>
            <VersionInfo/>

            <Switch>
                <Route path="/class/:classId" exact component={ClassDetailsContainer} />
                <Route path='/' component={DegreeContainerV2}/>

                <Redirect to={'/'} />
            </Switch>
        </div>
    );
};
