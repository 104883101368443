import React from 'react';
import { Milestone } from '@/api/types';
import { InstitutionName } from '@/constants/institutions';
import {StickyTableStyle} from '@/components/enrollment-history/styled';
import tw from 'twin.macro';

interface Props {
    milestones: Milestone[];
}

export default ({ milestones }: Props) => {
    if (!milestones.length) {
        return <p css={[ tw`ml-4` ]}>None</p>;
    }

    return (
        <StickyTableStyle>
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Milestone</th>
                    <th>Description</th>
                    <th>Level</th>
                </tr>
            </thead>
            <tbody>

                {
                    milestones.map((milestone, i) => (
                        <tr key={i}>
                            <td>{InstitutionName[milestone.institution]}</td>
                            <td>{milestone.milestoneName}</td>
                            <td>{milestone.description}</td>
                            <td>{milestone.level}</td>
                        </tr>
                    ))
                }

            </tbody>
        </StickyTableStyle>
    );
};
