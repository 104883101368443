import { ConditionalRuleEntry, ConditionalRules, RuleConditional } from '@/degrees/rules';
import { StudentInfo } from '@/api/types';
import _ from 'lodash';
import {
    AuditedRuleEntry,
    AuditStatus,
    RuleEntryAuditor,
} from '@/degrees/audit/types';
import {evaluateNode, parseConditionExpression} from '@/degrees/audit/logiparUtils';
import { evaluateConditions, stripIgnoredLogic } from '@/degrees/audit/conditionalUtils';

export default async function auditConditionalRule(rule: RuleConditional, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {

    const { condition_str, if_true, if_false } = rule;

    const cleaned = stripIgnoredLogic(condition_str);

    const tree = parseConditionExpression(cleaned);
    let isConditionTrue: boolean | string = false;

    try {
        isConditionTrue = evaluateNode(tree, studentInfo, evaluateConditions);
    } catch (err) {
        console.error('error in auditConditionalRule. rule: ', rule);
        throw new Error(err);
    }

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        satisfiedConditionStr: isConditionTrue,
        requiredClasses: 0,
        requiredCredits: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };

    const auditedRule = _.cloneDeep(rule);


    let branch: ConditionalRules;
    try {
        // @ts-ignore
        branch = isConditionTrue.toString().indexOf('true') !== -1 || eval(isConditionTrue) ? if_true : (rule.if_false || []);
    } catch (err) {
        console.error('err in eval(isConditionTrue). isConditionTrue: ', isConditionTrue);
        console.error('rule: ', rule);

        throw err;
    }


    const auditedBranch: AuditedRuleEntry<ConditionalRuleEntry>[] = [];

    for (const rule of branch) {
        const audited = await auditRuleEntry(rule, studentInfo) as AuditedRuleEntry<ConditionalRuleEntry>;

        if (audited.requiredClasses || audited.requiredCredits) {
            auditStatus.requiredClasses += audited.requiredClasses;
            auditStatus.requiredCredits += audited.requiredCredits;

            auditStatus.completedClasses += audited.completedClasses;
            auditStatus.completedCredits += audited.completedCredits;

            auditStatus.requiredClassCreditRules = auditStatus.requiredClassCreditRules.concat(audited.requiredClassCreditRules);

            auditedBranch.push(audited);

            if (!audited.completed) {
                auditStatus.completed = false;
                auditStatus.message = 'One or more rules not met.';
            }
        }
    }

    // flatten nested branches
    if (auditedBranch.length && 'conditional' in auditedBranch[0]) {
        auditedRule.auditedBranch = auditedBranch[0].conditional.auditedBranch;
    } else {
        auditedRule.auditedBranch = auditedBranch;
    }


    const result = {
        conditional: auditedRule,
        ...auditStatus,
    };
    return result;
}

