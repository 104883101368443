import { gql } from '@apollo/client';
import * as fragments from '@/api/graphql/fragments';

export default gql`
fragment requirementLine on custom_rq_ln_detl_tbl {
    requirementId: requirement
    effdt
    lineNumber: line_number
    lineKeyNumber: rq_line_key_nbr
    lineDetailType: rq_line_det_type
    isAntirequisite
    description: descr
    connect: rq_connect
    parenthesis
    institution
    
    maxUnitsAllowed: max_units_allowd
    maxCoursesAllowed: max_crses_allowd
    minCoursesRequired: min_crses_reqd
    minUnitsRequired: min_units_reqd
    minGradePoints: grade_points_min
    gpaRequired: gpa_required
    maxGpa: gpa_maximum
    
    testId: test_id
    testComponent: test_component
    score
    conditionCode: condition_code
    conditionOperator: condition_operator
    conditionData: condition_data
    planDescription: plan_descr
    subplanDescription: subplan_descr

    conditionSpec: condition_spec {
        ...conditionSpec
    }    
    conditions {
        ...conditionLine
    }
    conditionDetails: condition_details {
        ...conditionLineDetails
    }

    courseListId: course_list
    courseListInfo: course_list_info {
        description: descr254a
    }
    
    courses {
        isWildCard: wildcard_ind
        minGradePoints: grade_points_min 
        minUnits: units_minimum
        subject
        courseNumber: catalog_nbr
    
        courseId: crse_id
        courseOffer: course_offer {
            subject
            courseNumber: catalog_nbr
        }
    }
    
    coursesNames: courses_names
    milestones
    conditionConnectType: condition_connect_type
    
    antirequisite: anti_requisite
    
    entityGroup:entity_group
    entityGroupDetails:entity_group_details {
      ...entityGroupDetails
    }
}
${fragments.conditionLine}
${fragments.conditionLineDetails}
${fragments.conditionSpec}
${fragments.entityGroupDetails}
`;
