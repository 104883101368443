import {EvaluatedExpression, StudentInfoWithDegree} from '@/degrees/audit/types';
import {ConditionOperator, ScribeCode} from './constants';
import {
    formatCompletedDegree,
    formatDegreeMajor,
    getPriorDegrees,
    getQualifyingEnrollments
} from './utils';
import {AcademicPlanInfo} from '@/api/types';
import _ from 'lodash';
import {ScribedCourse} from '@/degrees/rules';
import {parseClassFromStudentEnrollmentHistory} from '@/api/transformers';

const withAttributeRegex = /\(\s*WITH[^()]*\)/ig;

export function stripIgnoredLogic(input: string) {
    let cleaned = input.replace(withAttributeRegex, '');
    return cleaned;
}

function parseConditionValue(input: string) {
    if (input.match(/\bY\b/i)) return true;
    if (input.match(/\bN\b/i)) return false;

    return input.toUpperCase();
}

export function parseConditionOperator(input: string): ConditionOperator {
    const regex = /(?:\s*(<>|>=|>|<=|<|=|\bis\b|\bisnt\b|\bwas\b|\bwasnt\b)\s*)/i;
    const matches = input.match(regex);
    if (!matches) {
        throw new Error('parseConditionOperator unable to parse input: ' + input);
    }
    return matches[1] as ConditionOperator;
}

function comparator(conditionValue: string | boolean, test: string, operator: ConditionOperator): boolean {
    switch (operator) {
        case ConditionOperator.Equal:
        case ConditionOperator.Is:
        case ConditionOperator.Was:
            return test === conditionValue;

        case ConditionOperator.GreaterThan:
            return test > conditionValue;

        case ConditionOperator.LessThan:
            return test < conditionValue;

        case ConditionOperator.GreaterOrEqual:
            return test >= conditionValue;

        case ConditionOperator.LessOrEqual:
            return test <= conditionValue;

        case ConditionOperator.NotEqual:
        case ConditionOperator.Isnt:
        case ConditionOperator.Wasnt:
            return test !== conditionValue;

        default:
            throw new Error(`Unhandled ConditionOperator: ${operator}`);
    }
}

function parseConditionStr(rawInput: string): EvaluatedExpression[] {
    let trimmed = rawInput.trim();
    if (rawInput[0] === '(' && rawInput[rawInput.length - 1] === ')') {
        trimmed = trimmed.slice(1, -1);
    }

    const conditions = trimmed.split(/\band\b/ig);

    const evaluatedExpressions = conditions.map(condition => {
        const operator = parseConditionOperator(trimmed);

        const split = trimmed.split(operator);
        if (split.length !== 2) {
            throw new Error('unhandled conditionStr. expected exactly two components after splitting on \'=\'. instead received: ' + rawInput);
        }


        return {
            key: split[0].trim(),
            value: split[1].trim(),
            operator,
        };
    });
    return evaluatedExpressions;
}

function evaluateExpression(condition: EvaluatedExpression, studentInfo: StudentInfoWithDegree) {
    const { key, value, operator } = condition;

    const normalizedKey = key.toUpperCase();
    const normalizedValue = parseConditionValue(value);

    if (normalizedKey === ScribeCode.PriorDegree) {

        const priorDegrees = getPriorDegrees(studentInfo).map(plan => {
            return formatCompletedDegree(plan);
        });
        if (!normalizedValue && priorDegrees.length) return false;
        return `true: ${priorDegrees}`;

    } else if (normalizedKey === ScribeCode.PriorDegreeBA) {
        const priorDegrees = getPriorDegrees(studentInfo);

        const match: AcademicPlanInfo | undefined = _.find<AcademicPlanInfo>(priorDegrees, (o) => !!(o.academicPlan.match(/-BA\s*$/i)));

        if (normalizedValue) {
            const result = match ? `true: ${formatCompletedDegree(match)}` : false;
            return result;
            // if normalizedValue is false then that implies exclude condition
        } else {
            return !match;
        }

    } else if (normalizedKey === ScribeCode.PriorDegreeBS) {
        const priorDegrees = getPriorDegrees(studentInfo);

        const match: AcademicPlanInfo | undefined = _.find<AcademicPlanInfo>(priorDegrees, (o) => !!(o.academicPlan.match(/-BS\s*$/i)));

        if (normalizedValue) {
            const result = match ? `true: ${formatCompletedDegree(match)}` : false;
            // if normalizedValue is false then that implies exclude condition
        } else {
            return !match;
        }

    } else if (normalizedKey === ScribeCode.AUDITACTION) {
        return false;
    } else if (normalizedKey === ScribeCode.Major) {
        return comparator(normalizedValue, formatDegreeMajor(studentInfo), operator);

    } else if (normalizedKey === ScribeCode.Concentration) {
        return comparator(normalizedValue, studentInfo.conc, operator);

        // (BIOL. @W IS PASSED)
    } else if (normalizedValue === 'PASSED') {
        const requiredCourse: ScribedCourse = normalizedKey.split(/\s+/) as ScribedCourse;

        const nonCourseRegex = /(.*TEST|.*-SCH)/i;
        if (nonCourseRegex.test(normalizedKey)) {
            return false;
        }


        const qualifying = getQualifyingEnrollments({
            except_courses: [],
            list_type: 'OR',
            scribed_courses: [ [ requiredCourse ] ]}, studentInfo.enrollments);

        return qualifying.length ? `true: ${qualifying.map(o => parseClassFromStudentEnrollmentHistory(o))}` : false;
    }

    return false;
}

export function evaluateConditions(input: string, studentInfo: StudentInfoWithDegree) {
    const conditions: EvaluatedExpression[] = parseConditionStr(input);
    const results = conditions.map(condition => evaluateExpression(condition, studentInfo));

    const anyFalse = results.find(o => !o);
    return anyFalse ? false : results.join('\n');
}
