import {CourseExtractWithRequisites} from '@/api/graphql/queries/getCourses';
import {RequirementsEvalResult} from '@/degrees/audit/findMinimumCourseRequirements';

export type AuditedCourseRequirement = {
    courseName?: string;
    completed?: boolean;
};

export function auditCourseRequirements(evalResult: RequirementsEvalResult, completedCourseNames: string[]): AuditedCourseRequirement[] {
    const courseRequirements: Array<AuditedCourseRequirement> = [];

    evalResult.selectedCourses.forEach(s => {
        courseRequirements.push({
            courseName: s,
            completed: !!completedCourseNames.find(c => c === s),
        });
    });
    return courseRequirements;
}

export function auditPathRequisite(course: CourseExtractWithRequisites, completedCourseNames: string[]): CourseExtractWithRequisites {

    const courseName = `${course.subject} ${course.courseNumber}`;
    const longestSequence = [];

    if (course.requisites?.length) {

        const firstRequisiteName = `${course.requisites[0].subject} ${course.requisites[0].courseNumber}`;
        longestSequence.push(firstRequisiteName);

        for (let requisite of course.requisites) {


            requisite.completed = !!completedCourseNames.find(c => c === `${requisite.subject} ${requisite.courseNumber}`);


            if (!requisite.completed) {
                const audited: CourseExtractWithRequisites = auditPathRequisite(requisite, completedCourseNames);
                requisite = {
                    ...audited
                };

                if (requisite.longestSequences?.length) {

                }
            }

        }
    }

    return {
        ...course,
        courseName,
        longestSequences: longestSequence
    };
}

/**
 * Recursively finds the longest path (depth) from a single root node.
 * Returns an object with { depth: number, path: string[] }.
 */
function findLongestPathFromNode(
    node: CourseExtractWithRequisites
): { depth: number; path: string[] } {
    const nodeName = `${node.subject} ${node.courseNumber}`;

    if (!node.requisites || node.requisites.length === 0) {
        return {
            depth: 1,
            path: [ nodeName ],
        };
    }

    let best = {
        depth: 1,
        path: [ nodeName ]
    };

    for (const child of node.requisites) {
        const childResult = findLongestPathFromNode(child);
        const totalDepth = 1 + childResult.depth;

        if (totalDepth > best.depth) {
            best = {
                depth: totalDepth,
                path: [ nodeName, ...childResult.path ],
            };
        }
    }

    return best;
}

/**
 * Scans an array of `CourseExtractWithRequisites` items to find the single deepest chain,
 * outputting both the maximum depth found (longest "sequence") and the names in that path.
 */
export function findLongestRequisitesSequence(
    courses: CourseExtractWithRequisites[]
): { longestDepth: number; longestPath: string[] } {
    let overallBest = {
        depth: 0,
        path: [] as string[]
    };

    for (const course of courses) {
        const currentResult = findLongestPathFromNode(course);
        if (currentResult.depth > overallBest.depth) {
            overallBest = currentResult;
        }
    }

    return {
        longestDepth: overallBest.depth,
        longestPath: overallBest.path,
    };
}

export type RequisiteChainInfo = {
    chains: PathStep[][];
    longestLength: number;
    allCourses: string[];
    totalUnits: number;
};

export function auditPrerequisiteChains(
    course: CourseExtractWithRequisites,
    path: PathStep[] = []
): RequisiteChainInfo {
    const chains: PathStep[][] = gatherPrerequisiteChains(course, path);
    const longestLength: number = findLongestChain(chains);

    const {
        visitedCourses,
        totalUnits
    } = gatherAllCoursesAndUnits(course);

    return {
        chains,
        longestLength,
        allCourses: Array.from(visitedCourses),
        totalUnits
    };
}

export type PathStep = {
    course: string;
    units: number;
};

export function gatherPrerequisiteChains(
    course: CourseExtractWithRequisites & Partial<{ course: string }>,
    path: PathStep[] = []
): PathStep[][] {
    const courseName = course.course || course.courseName || `${course.subject} ${course.courseNumber}`;
    const units = course.units;

    const currentStep = {
        course: courseName,
        units,
    };

    // Build path down to this course
    const currentPath = [ ...path, currentStep ];
    // If no requisites, return this path as a complete chain
    if (!course.requisites || course.requisites.length === 0) {
        return [ currentPath ];
    }
    // Otherwise, recurse for each requisite and collect the resulting chains
    let allChains: PathStep[][] = [];
    for (const req of course.requisites) {
        const childChains = gatherPrerequisiteChains(req, currentPath);
        allChains = allChains.concat(childChains);
    }
    return allChains;
}

export function findLongestChain(chains: PathStep[][]): number {
    let longestLength = 0;
    for (const chain of chains) {
        if (chain.length > longestLength) {
            longestLength = chain.length;
        }
    }
    return longestLength;
}

export function gatherAllCoursesAndUnits(
    course: CourseExtractWithRequisites & Partial<{ course: string }>,
    visited: Set<string> = new Set()
): { visitedCourses: Set<string>; totalUnits: number } {
    // Determine the canonical name for this course
    const courseName = course.course || course.courseName || `${course.subject} ${course.courseNumber}`;

    // If we've already visited this course, skip it to avoid duplicates
    if (visited.has(courseName)) {
        return {
            visitedCourses: visited,
            totalUnits: 0
        };
    }
    visited.add(courseName);

    // Add this course's units (if present)
    let sumUnits = course.units || 0;

    // Recurse to gather from any requisites
    if (course.requisites && course.requisites.length > 0) {
        for (const req of course.requisites) {
            const {totalUnits: childUnits} = gatherAllCoursesAndUnits(req, visited);
            sumUnits += childUnits;
        }
    }

    return {
        visitedCourses: visited,
        totalUnits: sumUnits
    };
}

export function printPrerequisiteInfo(course: CourseExtractWithRequisites): void {
    const allChains = gatherPrerequisiteChains(course);

    // Print out each chain so it's clear how distant requisites connect
    for (const chain of allChains) {
        console.log(chain.join(' => '));
    }

    // Compute longest sequence length
    const longestLength = findLongestChain(allChains);
    console.log(`Longest chain length: ${longestLength}`);
}

// This will list all prerequisite chains, showing each path in "earliest => ... => final" form
// printPrerequisiteInfo(example);

/*
Possible output:

BPL 5100 => ACC 2203
BPL 5100 => FIN 3000 => MKT 2500 => ECO 101
BPL 5100 => FIN 3000 => MKT 2500 => ECO 102
BPL 5100 => FIN 3000 => FIN 1000
BPL 5100 => OPM 3000
Longest chain length: 4
*/
