import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import { CourseClassQueryResult, CourseClassWithGroupData } from '@/api/types';
import * as fragments from '@/api/graphql/fragments';
import { convertQueryResultToClassWithGroupData } from '@/api/transformers';

// get class by class_nbr, which is unique
const GET_BY_ID = gql`
query($classNumber: Int!) {
  items: ps_class_tbl(
    where:{
      class_nbr: {_eq:$classNumber}
    }
  ) {
    attributes {
        courseAttribute: crse_attr
        courseAttributeValue: crse_attr_value
    }
    meetings {
        ...meetingPatterns
    }

    courseId:crse_id
    subject
    courseNumber:catalog_nbr
    institution
    academicCareer: acad_career
    academicGroup: acad_group

    courseOffer: course_offer {
        requirementGroupDetails: requirement_group_details(
            order_by: {rq_grp_line_nbr: asc}
         ){
            ...requirementGroupDetails
        }

      requirementGroup: rqrmnt_group
      
      ...requirementGroupFields
      ...catalogFields
    }

    classId: class_nbr

    classSection: class_section
    
    startDate: start_dt
    endDate: end_dt
    instructionMode: instruction_mode,
    enrollmentStatus: enrl_stat,
    sessionCode: session_code
    enrollmentCap: enrl_cap
    waitCap: wait_cap
    minEnrollment: min_enrl
    enrollmentTotal: enrl_tot
    waitTotal: wait_tot

    
    courseComponent: ssr_component

    classType: class_type
  }
}
${fragments.requirementGroupDetails}
${fragments.requirementGroupFields}
${fragments.meetingPatterns}
${fragments.catalogFields}
`;

export const getClassById = async (classNumber: number): Promise<CourseClassWithGroupData | null> => {
    const results = await graphClient.query({
        query: GET_BY_ID,
        variables: { classNumber }
    });

    console.log('results: ', results);
    if (results.data.items.length) {
        const classRecord: CourseClassQueryResult = results.data.items[0];
        const courseClassWithGroupData = convertQueryResultToClassWithGroupData(classRecord);

        return courseClassWithGroupData;
    }
    return null;
};
