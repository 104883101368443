import React, {useMemo, useState} from 'react';
import {
    formatGPA,
} from '@/api/transformers';
import tw from 'twin.macro';
import { Spinner } from '../common';
import EnrollmentHistoryTable from '@/components/enrollment-history/EnrollmentHistoryTable';
import RuleStatus from '@/components/degree/RuleStatus';
import {
    BaseButtonStyle, GalleryGridItemStyle, GalleryGridStyle,
} from '@/components/dashboard/styled';
import {
    AuditedClassCreditRule,
    AuditedRequirementBlock,
    AuditedRuleEntry,
    StudentInfoWithDegree
} from '@/degrees/audit/types';
import {
    formatAttributeKey,
    formatBlockExtract,
    formatCourseList, formatMajorDescription,
    formatRuleEntryLabel,
    getRuleType, parseAuditedClassCreditRule, sortAndFormatChains,
} from '@/degrees/audit/utils';
import {
    RuleConditional,
    RuleGroupRequirement,
    RuleSubset,
    SubsetRuleEntry
} from '@/degrees/rules';
import {
    AuditedBlockStyle,
    AuditedRuleEntryStyle,
    DegreeRequirementsStyle, PageContainerStyle,
    StudentFormStyle
} from '@/components/degree/styled';
import {InstitutionName} from '@/constants/institutions';
import {auditStudent} from '@/components/degree/auditStudent';
import _ from 'lodash';
import {
    CourseCoverage,
    CoverageInfo,
} from '@/degrees/audit/findMinimumCourses';
import {termMappings} from '@/constants/terms';
import {AVG_COURSE_LOAD} from '@/degrees/audit/constants';
import MilestoneTable from '@/components/enrollment-history/MilestoneTable';
import TestPlacementTable from '@/components/enrollment-history/TestPlacementTable';
import {CardWidget} from '@/components/common/CardWidget';

let defaultStudentId = '';


// student w/ 2nd degree
// defaultStudentId = '23317041';
// same as above but with no enrollment data:
// defaultStudentId = '923317041';


// defaultStudentId = '14121086';

// transfer student
// defaultStudentId = '23977875';

// MAT student with overlapping requirements
// has a chain that branches into multiple sub-chains
// defaultStudentId = '13158212';

// defaultStudentId = '23034588';

// defaultStudentId = '23951648';
defaultStudentId = '';

const dummyInfo = {
    studentId: '923317041',
    degree: 'BS',
    institution: 'BKL01',
    major: 'COMPSC',
    conc: 'N/A',
    cumulativeGpa: 2.72,
    totalUnits: 106,
    latestDeclaredPlan: {
        "__typename": "ps_acad_plan",
        "effdt": "2021-03-04",
        "academicPlan": "COMPSC-BS",
        "declaredDate": "2020-12-05",
        "reqTerm": "1219",
        "completionTerm": "",
        "plan": {
            "__typename": "ps_acad_plan_tbl",
            "transcriptDescription": "Computer and Information Science",
            "institution": "BKL01"
        }
    }
} as any;

export default () => {
    const [ showRemainingClassCreditRules, setShowRemainingClassCreditRules ] = useState(false);
    const [ error, setError ] = useState<string>('');
    const [ studentId, setStudentId ] = useState<string>(defaultStudentId);
    const [ studentInfo, setStudentInfo ] = useState<StudentInfoWithDegree | null>();

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ auditedBlock, setAuditedBlock ] = useState<AuditedRequirementBlock | null>(null);
    const [ auditErrors, setAuditErrors ] = useState<string[]>([]);


    const missingClassCreditRules = useMemo<AuditedClassCreditRule[]>(() => {
        return auditedBlock?.missingClassCreditRules || [];
    }, [ auditedBlock ]);

    const shortestPathRemaining = useMemo<CoverageInfo | null>(() => {
        return auditedBlock?.shortestPathRemaining || null;
    }, [ auditedBlock ]);

    const onClearStudent = () => {
        setStudentId('');
        setStudentInfo(null);
        setAuditedBlock(null);
    };

    const onSubmitStudentId = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const submittingStudentId = studentId;

        setLoading(true);
        onClearStudent();
        setError('');

        auditStudent(studentId)
            .then((results) => {
                setStudentInfo(results.studentInfo);
                setAuditedBlock(results.auditedBlock);
                setAuditErrors(_.cloneDeep(results.errors));
                setShowRemainingClassCreditRules(!!(results.auditedBlock && !results.auditedBlock.completed));
            })
            .catch(err => {
                console.error(err);
                setError(`studentId: ${submittingStudentId} ${err.toString()}`);
            })
            .then(() => setLoading(false));
    };

    function renderSubsetRule(entry: AuditedRuleEntry<{subset: RuleSubset}>) {
        return (
            <div>
                <p>Requirements:</p>
                <div css={[ tw`mx-4` ]}>
                    {entry.subset.requirements.map((req: AuditedRuleEntry<SubsetRuleEntry>, index) => {
                        return (
                            <div key={index}>
                                {renderAuditedRuleEntry(req)}
                            </div>

                        );
                    })}
                </div>
            </div>
        );
    }

    function renderConditionalRuleAudited(entry: AuditedRuleEntry<{conditional: RuleConditional}>) {
        const rule = entry.conditional;
        if (rule.auditedBranch) {
            return (
                <div css={[ tw`mx-4` ]}>
                    {rule.auditedBranch.map((entry, index) => {
                        return (
                            <div key={index}>
                                {renderAuditedRuleEntry(entry as AuditedRuleEntry)}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            throw new Error('RuleConditional missing audited branch!');
        }
    }


    // function renderConditionalRule(entry: AuditedRuleEntry<{conditional: RuleConditional}>) {
    //     const rule = entry.conditional;
    //
    //     const branch = eval(entry.satisfiedConditionStr) ? rule.if_true : (rule.if_false || []);
    //     console.log('typeof entry.satisfiedConditionStr: ', typeof entry.satisfiedConditionStr);
    //
    //     console.log('rule.if_false: ', rule.if_false);
    //     console.log('renderConditionalRule branch: ', branch);
    //
    //     return (
    //       <div>
    //           <p>IF {rule.condition_str}: {(entry.satisfiedConditionStr!).toString()}</p>
    //
    //           {
    //               !entry.satisfiedConditionStr && rule.if_false?.length ?
    //                   <p>ELSE</p>
    //                   : null
    //           }
    //
    //           <div css={[ tw`mx-4` ]}>
    //               {branch.map((entry, index) => {
    //                   return (
    //                       <div key={index}>
    //                           {renderAuditedRuleEntry(entry as AuditedRuleEntry)}
    //                       </div>
    //                   );
    //               })}
    //           </div>
    //       </div>
    //     );
    // }

    function renderGroupRule(entry: AuditedRuleEntry<{group_requirement: RuleGroupRequirement}>) {
        const rule = entry.group_requirement;
        return (
            <div>
                {rule.auditedMinEntries.map((o, index) => {
                    return (
                        <div key={index}>
                            {renderAuditedRuleEntry(o as AuditedRuleEntry)}
                        </div>
                    );
                })}

                {/* <p>{rule.number} Groups in</p> */}

                {/* {rule.auditedSubGroups.map((subgroup: { */}
                {/*     rules: SubGroupRuleEntry[] */}
                {/* }, index: number) => { */}
                {/*     return ( */}
                {/*         <div key={index}> */}
                {/*             <p>Group #{index + 1}</p> */}
                {/*             <div css={[ tw`mx-8` ]}> */}
                {/*                 {subgroup.rules.map((entry, index) => { */}
                {/*                     return ( */}
                {/*                         <div key={index}> */}
                {/*                             {renderAuditedRuleEntry(entry as AuditedRuleEntry)} */}
                {/*                         </div> */}
                {/*                     ); */}
                {/*                 })} */}
                {/*             </div> */}

                {/*         </div> */}
                {/*     ); */}

                {/* })} */}
            </div>
        );
    }

    function renderEntryDetails(entry: AuditedRuleEntry) {
        if (entry.ignored) return null;

        if ('class_credit' in entry) {
            return renderClassCreditEntry(entry);
        }
        if ('subset' in entry) {
            return renderSubsetRule(entry);
        }
        if ('conditional' in entry) {
            return renderConditionalRuleAudited(entry);
        }
        if ('group_requirement' in entry) {
            return renderGroupRule(entry);
        }
        if ('remark' in entry) {
            return <p>{entry.remark}</p>;
        }
        if ('remark_str' in entry) {
            return <p>{entry.remark_str}</p>;
        }
        if ('block' in entry) {
            if (!entry.block.populatedBlock) {
                console.error('block entry has no populated block. entry: ', entry);
                throw new Error('no populated block');
            }
            return renderAuditedBlock(entry.block.populatedBlock!);
        }
        if ('copy_rules' in entry) {
            // console.log('copy rules entry: ', entry);
            return renderAuditedBlock(entry.copy_rules.populatedBlock!);
            // throw new Error('   unhandled copy_rules');
        }
        if ('blocktype' in entry) {
            return renderAuditedBlock(entry.blocktype.populatedBlock!);
        }
        if ('noncourse' in entry) {
            return (
                <p>{entry.noncourse.expression}</p>
            );
        }
        if ('proxy_advice' in entry) {
            return (
                <p>{entry.proxy_advice.advice_str}</p>
            );
        }
        if ('course_list_rule' in entry) {
            return (
                <div>
                    <p>minarea: {entry.course_list_rule.minarea}</p>
                    <p>Courses: {formatCourseList(entry.course_list_rule.course_list)}</p>
                </div>
            );
        }
        if ('rule_complete' in entry) {
            return (
                <p>Rule Complete</p>
            );
        }
    }


    function renderClassCreditEntry(wrappedEntry: AuditedRuleEntry) {
        const entry = wrappedEntry.requiredClassCreditRules[0];

        const rule = entry.rule || (wrappedEntry as any).class_credit;

        const msg = parseAuditedClassCreditRule(rule);

        const attributeGroups = [];

        if (entry.populatedAttributeCourses) {
            for (const attr in entry.populatedAttributeCourses) {
                const group = <span key={attr} css={[ tw`` ]}>{formatAttributeKey(attr)}: {entry.populatedAttributeCourses[attr].join(', ')}</span>;
                attributeGroups.push(group);
            }
        }

        return (
            <p>
                {msg}
                {
                    attributeGroups.map(group => group)
                }
            </p>
        );
    }

    function renderAuditedStatus(entry: AuditedRuleEntry) {
        return (
            <div className={'audited-status-container'} css={[ tw`` ]}>
                {
                    entry.requiredClasses ?
                        <div>
                            <p>Completed {entry.completedClasses} of {entry.requiredClasses} classes</p>
                        </div>
                        : null
                }
                {
                    entry.requiredCredits ?
                        <div>
                            <p>Completed {entry.completedCredits} of {entry.requiredCredits} credits</p>
                        </div>
                        : null
                }
            </div>
        );
    }

    function renderAuditedRuleEntry(entry: AuditedRuleEntry, isRoot: boolean = false) {
        return (
            <AuditedRuleEntryStyle isRoot={isRoot}>
                {renderAuditedStatus(entry)}

                <div>
                    <span css={[ tw`font-normal uppercase text-gray-500` ]}>Rule - {getRuleType(entry)}</span>
                    <p>{formatRuleEntryLabel(entry)}</p>
                </div>
                <RuleStatus entry={entry}/>
                {/* <p css={[ tw`underline font-medium` ]}>{formatRuleEntryLabel(entry)}</p> */}

                <div css={[ tw`mt-3` ]}>
                    <div css={[ tw`ml-4` ]}>
                        {
                            renderEntryDetails(entry)
                        }
                    </div>
                </div>
            </AuditedRuleEntryStyle>
        );
    }

    function renderAuditedBlock(block: AuditedRequirementBlock) {
        return (
            <AuditedBlockStyle className={'audited-block-container'}>
                <div>
                    {/* <span css={[tw`italic`]}>Block - {formatBlockExtract(block)}</span> */}
                    <p css={[ tw`font-medium text-xl` ]}>{block.title}</p>
                </div>
                {/* <p css={[ tw`underline font-medium` ]}>{block.title}</p> */}

                <div css={[ ]}>
                    <p css={[ tw`font-light text-gray-500` ]}>BLOCK - {formatBlockExtract(block)}</p>
                    <RuleStatus entry={block}/>
                    <div css={[ tw`mx-8` ]}>
                        {
                            block.parseTree.body_list.map((entry, i) => (
                                <div key={i}>
                                    {renderAuditedRuleEntry(entry as AuditedRuleEntry, true)}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </AuditedBlockStyle>
        );
    }

    function renderRemainingClassCreditRuleItem(entry: AuditedClassCreditRule) {
        let requiresCredits, requiresClasses, inCourses;

        if (entry.requiredCredits) {
            requiresCredits = `${entry.requiredCredits - entry.completedCredits} Credits`;
        }

        if (entry.requiredClasses) {
            const remainingClasses = entry.requiredClasses - entry.completedClasses;
            requiresClasses = `${remainingClasses} Class${remainingClasses > 1 ? 'es' : ''}`;
        }

        const amountAndType = [ requiresCredits, requiresClasses ].filter(s => !!s).join(' and ').trim();

        if (entry.rule.course_list) {
            inCourses = `${formatCourseList(entry.rule.course_list)}`;
        }

        const attributeGroups = [];

        if (entry.populatedAttributeCourses) {
            for (const attr in entry.populatedAttributeCourses) {
                if (entry.populatedAttributeCourses[attr].length) {
                    const group = <div key={attr} css={[ tw`` ]}>
                        <span className={'attribute-line'}><strong>{formatAttributeKey(attr)}:</strong> {entry.populatedAttributeCourses[attr].join(', ')}</span>
                                  </div>;
                    attributeGroups.push(group);
                }
            }
        }

        return (
            <div css={[ tw`px-2` ]}>

                <strong className={'amount-and-type'}>{amountAndType}</strong>
                <span css={[ tw`whitespace-pre` ]}>  in: </span>
                <span className={'in-course-list-string'}>{inCourses}</span>
                {
                    attributeGroups.length ?
                        <div className={'from-attributes-container'} css={[ tw`ml-4` ]}>
                            <p css={[ tw`underline font-light` ]}>Attribute Lists:</p>
                            {attributeGroups.map(group => group)}
                        </div>
                        : null
                }
            </div>
        );
    }

    function renderRemainingClassCreditRules() {

        if (auditedBlock?.remaining) {

        }

        return (
            <ol className={'remaining-rules-list'} css={[ tw`px-4 m-0` ]}>
                {
                    missingClassCreditRules.map((o: AuditedClassCreditRule, i) => {
                        return (
                            <li css={[ tw` py-5` ]} key={i}>
                                {renderRemainingClassCreditRuleItem(o)}
                            </li>
                        );
                    })
                }

                {
                    auditedBlock?.remaining?.remainingAnyCourseCredits ?
                        <li css={[ tw` py-5` ]}>
                            <div css={[ tw`px-2` ]}>
                                <strong className={'amount-and-type'}>{auditedBlock.remaining.remainingAnyCourseCredits} discretionary credits</strong>
                                {/* <span css={[ tw`whitespace-pre` ]}></span> */}
                            </div>
                        </li>
                        : null
                }
            </ol>
        );
    }

    return (
        <PageContainerStyle css={[ tw`max-w-screen-xl` ]}>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <StudentFormStyle onSubmit={onSubmitStudentId}>
                {/* <div className={'header'}> */}
                {/*     <h3>Search</h3> */}
                {/* </div> */}

                <div css={[ tw`mt-4 mb-8` ]}>
                    {/* <label css={[ tw`mb-2 block font-medium text-xl` ]}>Search Student</label> */}
                    <div css={[ tw`flex` ]}>
                        <input placeholder={'Enter Student ID'} type={'text'} value={studentId} onChange={e => setStudentId(e.target.value)} required/>
                        <BaseButtonStyle css={[ tw`mx-4 bg-indigo-700 text-white` ]} onClick={onSubmitStudentId} disabled={!studentId}>Submit</BaseButtonStyle>
                    </div>
                </div>

                {
                    error ?
                        <div>
                            <p css={[ tw`text-red-400` ]}>{error}</p>
                        </div>
                        : null
                }


                {
                    studentInfo ?
                        <div>
                            <div className={'header'} css={[ tw`flex justify-between items-center` ]}>
                                <h3>Student Details</h3>
                                <span onClick={() => onClearStudent()} css={[ tw`cursor-pointer hover:underline text-indigo-500` ]}>Clear Student</span>
                            </div>

                            <div>
                                {
                                    auditErrors.length ?
                                        auditErrors.map(error => (
                                            <p key={error} className={'error'} css={[ tw`font-medium text-red-500 mb-4` ]}>{error}</p>
                                        ))
                                        : null
                                }
                            </div>

                            <div className={'details-container'}>

                                <div>
                                    <div className={'field'}>
                                        <label>Student ID:</label>
                                        <span>{studentInfo.studentId}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Degree:</label>
                                        <span>{studentInfo.degree ? `${studentInfo.degree} (${InstitutionName[studentInfo.institution] || studentInfo.institution})` : 'N/A'}</span>
                                    </div>
                                    <div className={'field'}>
                                        <label>Major:</label>
                                        <span>{formatMajorDescription(studentInfo.latestDeclaredPlan) || 'N/A'}</span>
                                    </div>
                                </div>

                                <div>
                                    <div className={'field'}>
                                        <label>Concentration:</label>
                                        <span>{studentInfo.conc || 'N/A'}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Cumulative GPA: </label>
                                        <span>{formatGPA(studentInfo.cumulativeGpa)}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Total Units Earned: </label>
                                        <span>{studentInfo.totalUnits}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : null

                }


            </StudentFormStyle>

            {
                auditedBlock?.remaining?.remainingSemesters ?
                    <div className={'remaining-semesters-container'} css={[ tw`mb-4` ]}>
                        <h4>Remaining Semesters: {auditedBlock.remaining.remainingSemesters || '-'}</h4>
                        {/* <p css={[ tw`font-normal` ]}>Total Units Remaining: {auditedBlock.remaining.remainingTotalCredits || '-'}</p> */}
                    </div>
                    : null
            }

            {
                auditedBlock?.remaining?.remainingSemesters === 0 && auditedBlock.completed ?
                    <div className={'remaining-semesters-container'} css={[ tw`mb-4` ]}>
                        <h4>Remaining Semesters: 0 (Completed)</h4>
                    </div>
                    : null
            }

            {
                auditedBlock ?
                    <DegreeRequirementsStyle>
                        <div className={'header'}>
                            <h3>Degree Requirements</h3>
                        </div>

                        <div>
                            {
                                missingClassCreditRules.length ?
                                    <>

                                        <div css={[ tw`mb-4` ]} style={{
                                            border: '8px solid rgba(0, 0, 0, 0)'
                                        }}
                                        >
                                            <CardWidget
                                                title={'Remaining Requirements'}
                                                collapsable={true}
                                                show={showRemainingClassCreditRules}
                                            >
                                                <div css={[ tw`` ]}>
                                                    {renderRemainingClassCreditRules()}
                                                </div>
                                            </CardWidget>
                                        </div>

                                        <div css={[ tw`mb-4` ]} style={{
                                            border: '8px solid rgba(0, 0, 0, 0)'
                                        }}
                                        >
                                            <CardWidget title={'Shortest Path'}>
                                                <div className={''}>
                                                    <div
                                                        css={[ tw` px-4 py-2` ]}
                                                        className={'minimum-courses-container'}
                                                    >
                                                        <p css={[ tw`text-xl mb-2` ]}>Minimum course load to satisfy all remaining requirements:</p>
                                                        <table css={[ tw`w-full` ]}>
                                                            <thead>
                                                            <tr>
                                                                <th css={[ tw`text-left font-medium px-2` ]}>
                                                                    <span>Course</span>
                                                                </th>
                                                                <th css={[ tw`font-medium px-2` ]}>
                                                                    <span>Units</span>
                                                                </th>
                                                                <th css={[ tw`font-medium px-2` ]}>
                                                                    <span>Satisfies #</span>
                                                                </th>

                                                                <th
                                                                    css={[ tw`text-left font-medium px-2` ]}
                                                                >
                                                                    <span>Required For</span>
                                                                </th>


                                                                <th css={[ tw`text-left font-medium px-2` ]}>
                                                                    <span>Associated Sequences</span>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {shortestPathRemaining?.courses && shortestPathRemaining.courses.map((courseCoverage: CourseCoverage) => {
                                                                return (
                                                                    <tr css={[ tw`` ]} key={courseCoverage.course}>
                                                                        <td css={[ tw`px-2 py-1` ]}>
                                                                            <span>{courseCoverage.course}</span>
                                                                        </td>

                                                                        <td css={[ tw`text-center px-2 py-1` ]}>
                                                                            <span>{courseCoverage.units?.toFixed(2)}</span>
                                                                        </td>

                                                                        <td css={[ tw`text-center px-2 py-1` ]}>
                                                                            <span>{courseCoverage.satisfies.join(', ')}</span>
                                                                        </td>

                                                                        <td
                                                                            css={[ tw`text-left px-2 py-1` ]}
                                                                        >
                                                                            <span>{courseCoverage.requiredFor}</span>
                                                                        </td>

                                                                        <td css={[ tw`text-center px-2 py-1` ]}>
                                                                            <ul css={[ tw` m-0 p-0` ]}>
                                                                                {
                                                                                    courseCoverage.chainInfo?.chains ?
                                                                                        sortAndFormatChains(courseCoverage.chainInfo?.chains).map((s, index) => (
                                                                                            <li css={[ tw`text-left` ]} key={index}>
                                                                                                <span>{s}</span>
                                                                                            </li>
                                                                                        ))
                                                                                        : null
                                                                                }
                                                                            </ul>

                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {
                                                        auditedBlock.remaining?.remainingSemesters ?
                                                            // remainingSemesters ?
                                                            <div className={'minimum-courses-summary'} css={[ tw` px-4 py-2` ]}>

                                                                <div css={[ tw`mb-1` ]}>
                                                                    <div css={[ tw`` ]}>
                                                                        <span css={[ tw`font-medium` ]}>Total Units Remaining: {auditedBlock.remaining.remainingTotalCredits || '-'}</span>
                                                                    </div>

                                                                    {
                                                                        auditedBlock.remaining.remainingCourseRuleCredits ?
                                                                            <div css={[ tw`ml-4 ` ]}>
                                                                                <span css={[ tw` font-normal` ]}>From Required Courses: {auditedBlock.remaining.remainingCourseRuleCredits} units</span>
                                                                            </div> : null
                                                                    }

                                                                    {
                                                                        auditedBlock.remaining.remainingAnyCourseCredits ?
                                                                            <div css={[ tw`ml-4 ` ]}>
                                                                                <span css={[ tw` font-normal` ]}>From Additional Degree Credits: {auditedBlock.remaining.remainingAnyCourseCredits} units</span>
                                                                            </div> : null
                                                                    }

                                                                </div>


                                                                {
                                                                    studentInfo?.mostRecentUnits ?
                                                                        <div css={[ tw`whitespace-pre mb-1` ]}>
                                                                            <span css={[ tw`font-normal` ]}>Recent Course Load: {studentInfo!.mostRecentUnits || '-'}</span>
                                                                            <span css={[ tw`px-1 italic` ]}>({termMappings[studentInfo!.mostRecentSemester || ''].replace(' Term', '')})</span>
                                                                        </div>
                                                                        :
                                                                        <div css={[ tw`whitespace-pre mb-1` ]}>
                                                                            <span css={[ tw`font-normal` ]}>Recent Course Load: {studentInfo!.mostRecentUnits || '-'}</span>
                                                                            <span css={[ tw`px-1 italic` ]}>(Estimating an average {AVG_COURSE_LOAD} units)</span>
                                                                        </div>
                                                                }


                                                                <div css={[ tw`my-8` ]}></div>

                                                                <div css={[ tw`whitespace-pre mb-1` ]}>
                                                                    <span css={[ tw`font-normal` ]}>Longest Sequence: {auditedBlock.longestSequence}</span>
                                                                    <span css={[ tw`px-1 italic` ]}>({[ ...auditedBlock.longestChain.map(c => c.course) ].reverse().join(' -> ')})</span>
                                                                </div>

                                                                <div css={[ tw`whitespace-pre` ]}>
                                                                    <span css={[ tw`font-medium` ]}>Remaining Semesters: {auditedBlock.remaining.remainingSemesters || '-'}</span>
                                                                </div>

                                                                <div css={[ tw`whitespace-pre` ]}>
                                                                    <span css={[ tw`font-normal italic` ]}></span>
                                                                </div>


                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                            </CardWidget>
                                        </div>
                                    </>

                                    : null

                            }
                        </div>

                        {
                            auditedBlock ?
                                <div style={{
                                        border: '8px solid rgba(0, 0, 0, 0)'
                                }}
                                >
                                    <CardWidget
                                        title={'Degree Block'}
                                        collapsable={true}
                                        show={false}
                                    >
                                        <div css={[ tw`px-4 flex text-lg font-normal` ]}>
                                            <p css={[ tw` mr-2 ` ]}>{auditedBlock.requirementId}</p>
                                            <p css={[ tw` mx-2 ` ]}>Period Start: {auditedBlock.periodStart}</p>
                                            <p css={[ tw` mx-2 ` ]}>Period Stop: {auditedBlock.periodStop.indexOf('9') !== 0 ? auditedBlock.periodStop : '-'}</p>
                                        </div>

                                        {
                                            auditedBlock.minCreditsHeaders.largest?.min_credits ?
                                                <div css={[ tw`px-4 mt-4` ]}>
                                                    <p css={[ tw`` ]}>Minimum Credit Requirement: {auditedBlock.minCreditsHeaders.largest?.min_credits} units</p>
                                                </div>
                                                : null
                                        }

                                        <div css={[ tw`px-4` ]}>
                                            {renderAuditedBlock(auditedBlock)}
                                        </div>

                                    </CardWidget>
                                </div>


                                : null
                        }
                    </DegreeRequirementsStyle>
                    : null
            }

            {
                studentInfo && !loading ?
                    <GalleryGridStyle gridTemplateColumns={'repeat(auto-fill, 50%)'}>
                        <GalleryGridItemStyle
                            rowSpan={1}
                            colSpan={1}
                        >
                            <CardWidget title={'Milestones'}>
                                <MilestoneTable milestones={studentInfo.milestones || []}/>
                            </CardWidget>
                        </GalleryGridItemStyle>

                        <GalleryGridItemStyle
                            rowSpan={1}
                            colSpan={1}
                        >
                            <CardWidget title={'Test Placements'}>
                                <TestPlacementTable placements={studentInfo.placements || []}/>
                            </CardWidget>
                        </GalleryGridItemStyle>


                        <GalleryGridItemStyle
                            rowSpan={2}
                            colSpan={4}
                        >
                            <CardWidget title={'Enrollment History'}>
                                <EnrollmentHistoryTable enrollments={studentInfo.enrollments}/>
                            </CardWidget>
                        </GalleryGridItemStyle>

                        {/* <EnrollmentHistoryContainerStyle> */}
                        {/*     <div className={'header'}> */}
                        {/*         <h3>Enrollment History</h3> */}
                        {/*     </div> */}
                        {/*     <EnrollmentHistoryTable enrollments={studentInfo.enrollments}/> */}
                        {/* </EnrollmentHistoryContainerStyle> */}

                    </GalleryGridStyle>

                    :
                    null
            }



        </PageContainerStyle>
    );
};
