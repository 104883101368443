import MaxHeap from '@/degrees/audit/MaxHeap';

type CourseRequirement = {
    numRequired: number;
    uniqueCourses: string[];
};

export function findMinimalHeapSelection(courseRequirements: CourseRequirement[]): string[] {
    const courseToLists = new Map<string, Set<number>>();
    const remainingSelections = new Map<number, number>(); // Track how many courses we still need per list
    const uncoveredLists = new Set<number>();

    // Step 1: Build a map of which courses belong to which lists
    courseRequirements.forEach((req, listIndex) => {
        uncoveredLists.add(listIndex);
        remainingSelections.set(listIndex, req.numRequired); // Store how many picks are required for this list

        for (const course of req.uniqueCourses) {
            if (!courseToLists.has(course)) {
                courseToLists.set(course, new Set());
            }
            courseToLists.get(course)!.add(listIndex);
        }
    });

    // Step 2: Insert courses into a max heap (priority queue) based on coverage
    const maxHeap = new MaxHeap<string>();
    for (const [ course, lists ] of courseToLists.entries()) {
        maxHeap.insert(course, lists.size);
    }

    const selectedCourses = new Set<string>();

    // Step 3: Greedily pick courses that maximize list coverage
    while (uncoveredLists.size > 0 && !maxHeap.isEmpty()) {
        const bestCourse = maxHeap.extractMax();
        if (!bestCourse) break;

        selectedCourses.add(bestCourse);

        // Remove covered lists & decrement their required selections
        for (const listIndex of courseToLists.get(bestCourse) ?? []) {
            if (remainingSelections.has(listIndex)) {
                const remaining = remainingSelections.get(listIndex)! - 1;
                if (remaining <= 0) {
                    uncoveredLists.delete(listIndex); // Mark list as fully satisfied
                }
                remainingSelections.set(listIndex, remaining);
            }
        }

        // Recalculate priorities and reinsert into heap
        const newHeap = new MaxHeap<string>();
        for (const [ course, lists ] of courseToLists.entries()) {
            const remainingCoverage = new Set([ ...lists ].filter(l => uncoveredLists.has(l))).size;
            if (remainingCoverage > 0) {
                newHeap.insert(course, remainingCoverage);
            }
        }
        maxHeap.heap = newHeap.heap;
    }

    return Array.from(selectedCourses);
}
