import _ from 'lodash';
import {AuditedRequirementBlock, AuditedRuleEntry, AuditStatus, StudentInfoWithDegree} from '@/degrees/audit/types';
import {RequirementBlock} from '@/degrees/types';
import auditRuleEntry from '@/degrees/audit/auditRuleEntry';
import AuditTracker from '@/degrees/audit/AuditTracker';

export default async function auditRequirementBlock(requirementBlock: RequirementBlock, studentInfo: StudentInfoWithDegree): Promise<AuditedRequirementBlock> {
    console.log(`auditRequirementBlock ${requirementBlock.requirementId} (${requirementBlock.institution})`);

    const errors: string[] = [];
    const { parseTree: { header_list, body_list } } = requirementBlock;

    AuditTracker.init(requirementBlock);

    // TODO: parse/audit header_list


    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        requiredClasses: 0,
        requiredCredits: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,

    };

    const auditedBodyList: AuditedRuleEntry[] = [];
    for (let i = 0; i < body_list.length; i++) {
        const audited = await auditRuleEntry(body_list[i], studentInfo);
        if ('block' in audited && !audited.block.populatedBlock) {
            console.error('audited block entry but did not include populated block');
            throw new Error('audited block entry but did not include populated block');
        }

        if (audited.errors && audited.errors.length) {
            errors.push(...audited.errors);
        }

        if (audited.requiredClasses || audited.requiredCredits) {

            console.log('auditRequirementBlock audited: ', audited);

            auditStatus.requiredClasses += audited.requiredClasses;
            auditStatus.requiredCredits += audited.requiredCredits;

            auditStatus.completedClasses += audited.completedClasses;
            auditStatus.completedCredits += audited.completedCredits;

            auditStatus.requiredClassCreditRules = auditStatus.requiredClassCreditRules.concat(audited.requiredClassCreditRules);

            auditedBodyList.push(audited);

            if (!audited.completed) {
                auditStatus.completed = false;
                auditStatus.message = 'One or more rules unmet';
            }
        }
    }

    const auditedBlock = _.cloneDeep(requirementBlock);

    auditedBlock.parseTree.body_list = auditedBodyList;

    const auditedRequirementBlock: AuditedRequirementBlock = {
        minCreditsHeaders: {
            largest: undefined,
            minCreditsHeaders: []
        },
        longestChain: [],
        longestSequence: 0,
        ...auditedBlock,
        ...auditStatus,
        errors
    };

    console.log(`auditRequirementBlock audited ${requirementBlock.requirementId} (${requirementBlock.institution})`, auditedRequirementBlock);

    const missingClassCreditRules = auditedRequirementBlock.requiredClassCreditRules.filter(o => !o.completed);
    console.log('missingClassCreditRules: ', missingClassCreditRules);


    AuditTracker.logEncounteredBlocks();

    return auditedRequirementBlock;
}
