import {StudentInfoWithDegree} from '@/degrees/audit/types';
import {ConditionalHeaderEntry, HeaderConditional} from '@/degrees/headers';
import {evaluateConditions, stripIgnoredLogic} from './conditionalUtils';
import {evaluateNode, parseConditionExpression} from '@/degrees/audit/logiparUtils';

export function parseHeaderConditional(header: HeaderConditional, studentInfo: StudentInfoWithDegree): ConditionalHeaderEntry[] {
    const { condition_str, if_true, if_false } = header;

    const cleaned = stripIgnoredLogic(condition_str);

    const tree = parseConditionExpression(cleaned);
    let isConditionTrue: boolean | string = false;

    try {
        isConditionTrue = evaluateNode(tree, studentInfo, evaluateConditions);

    } catch (err) {
        console.error('error in parseHeaderConditional. header: ', header);
        throw new Error(err as any);
    }

    let branch: ConditionalHeaderEntry[];
    try {

        branch = isConditionTrue.toString().indexOf('true') !== -1 || eval(isConditionTrue as any) ? if_true : (if_false || []);
    } catch (err) {
        console.error('err in eval(isConditionTrue). isConditionTrue: ', isConditionTrue);
        console.error('header: ', header);

        throw err;
    }

    return branch;
}
