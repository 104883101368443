import _ from 'lodash';
import {AuditedRequirementBlock, StudentInfoWithDegree} from '@/degrees/audit/types';
import {AVG_COURSE_LOAD} from '@/degrees/audit/constants';

export interface RemainingSemestersResults {
    averageCourseLoad: number;
    minDegreeCredits?: number;
    remainingAnyCourseCredits: number;
    remainingCourseRuleCredits: number;
    remainingTotalCredits: number;
    remainingSemesters: number;
}
export function calculateRemainingSemesters(auditedBlock: AuditedRequirementBlock, studentInfo: StudentInfoWithDegree): RemainingSemestersResults {

    const minCreditsHeaders = auditedBlock.minCreditsHeaders;
    const minDegreeCredits = minCreditsHeaders.largest?.min_credits;

    const remainingCourseRuleCredits = auditedBlock?.shortestPathTotalUnits || 0;
    let remainingAnyCourseCredits: number = 0;

    if (minDegreeCredits) {
        remainingAnyCourseCredits = _.max([ minDegreeCredits - studentInfo.totalUnits - remainingCourseRuleCredits, 0 ]) as number;
    }


    let averageCourseLoad: number;
    if (studentInfo?.mostRecentUnits) {
        averageCourseLoad = studentInfo.mostRecentUnits;
    } else {
        averageCourseLoad = AVG_COURSE_LOAD;
    }

    const remainingTotalCredits = remainingCourseRuleCredits + remainingAnyCourseCredits;

    const remainingSemesters = Math.ceil(remainingTotalCredits / averageCourseLoad);


    const withLongestChain = _.max([ remainingSemesters, auditedBlock?.longestSequence ]);


    const results: RemainingSemestersResults = {
        averageCourseLoad,
        minDegreeCredits,
        remainingAnyCourseCredits,
        remainingCourseRuleCredits,
        remainingTotalCredits,
        remainingSemesters: withLongestChain || 0,
    };

    return results;
}
