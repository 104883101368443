import {RequirementBlock} from '@/degrees/types';
import {BlockType} from '@/degrees/common';
import {
    RequirementCondition,
    RequirementConditionOperator,
    RequirementGroupDetails,
    RequirementLine,
    TestPlacement
} from '@/api/types';
import {CourseToken, Token} from '@/degrees/audit/findMinimumCourseRequirements';
import _ from 'lodash';

type BlockInfo = {
    requirementId: string;
    institution: string;
    blockType: BlockType;
    blockValue: string;
    title: string;
};

type SatisfiedConditionInfo = {
    requirementGroup: string;
    type: 'Milestone' | 'Placement';
    criteria: string;
};

// singleton pattern
class AuditTracker {

    satisfiedConditions: SatisfiedConditionInfo[] = [];
    encounteredBlocks: BlockInfo[] = [];
    institution: string = '';

    init(block: RequirementBlock) {
        this.encounteredBlocks = [];
        this.institution = block.institution;
        this.addBlock(block);
    }

    addBlock(block: RequirementBlock) {
        const info: BlockInfo = {
            requirementId: block.requirementId,
            institution: block.institution,
            blockType: block.blockType,
            blockValue: block.blockValue,
            title: block.title
        };
        this.encounteredBlocks.push(info);
    }

    logEncounteredBlocks() {
        let s = '';
        for (const block of this.encounteredBlocks) {
            s += `${block.requirementId}: ${block.blockType} - ${block.blockValue} (${block.title})\n`;
        }
        console.log(`[AuditTracker] Encountered blocks (${this.institution}):\n${s}`);
        console.log(this.encounteredBlocks);
    }

    addSatisfiedConditionToken(token: CourseToken, meta: any = {}) {
        const props = {
            criteria: token.possibleCourses.join(', '),
            ...meta
        };
        this.satisfiedConditions.push(props);
    }

    logSatisfiedConditions() {
        let lines: string[] = [];
        for (const c of this.satisfiedConditions) {
            lines.push(`\t${c.requirementGroup}: ${c.criteria}\n`);
        }
        const s: string = _.uniq(lines).join('');
        console.log(`[AuditTracker] Satisfied conditions (${this.institution}):\n${s}`);
    }
}

export default new AuditTracker();
