import http from '@/api/http';
import {AccountType} from '@/api/types';


export type CreateAccountProps = {
    username: string;
    password: string;
    accountType: AccountType
};

export default async (props: CreateAccountProps): Promise<void> => {
    const endpoint = '/account';
    await http.post(endpoint, props);
};
