import styled from 'styled-components/macro';
import tw from 'twin.macro';

export const TableStyle = styled.table`
    ${tw`w-full`}
    position: relative;
    th {
        ${tw`font-medium px-2 pb-2 pt-2 text-left`}
        
          background: white;
        
    }
    td {
        ${tw`px-2 pt-2 pb-2`}
        vertical-align: top;
    }
    tbody tr:first-child td {
        ${tw`pt-4`}
    }

    * {
        ${tw`text-base`}
    }
    // tr:nth-child(even) {background: #eaeaea}
    tbody {
        tr:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
        
    .tag-block {
         ${tw`text-xs`}
        background-color: rgb(74,89,140);
        color: white;
        border-radius: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 2px;
        margin-right: 2px;
    }
    
`;

export const StickyTableStyle = styled(TableStyle)`
    thead tr {
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.6);
    }
`;

