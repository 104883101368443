import {
    Condition,
    ConditionDetail,
    ConditionLine,
    CourseClassExtract,
    CourseClassWithGroupData,
    CourseGroup, CourseInfo,
    CourseRequirement, RequirementConditionOperator,
    RequirementGroupDetails,
    RequirementLine,
    RequisiteCourse,
    RequisiteGrouping,
    RequisiteGroupType,
    RequisiteType,
    StudentEnrollment
} from '@/api/types';
import _ from 'lodash';
import { generateUUID } from '@/utils/utilities';
import { parseStudentGroupDescription, parseStudentGroupDescrptionWithoutInstitution } from '@/constants/studentGroups';
import { parseParentheses } from '@/utils/EnrollmentValidator';

const generateEmptyRequirement = (group?: RequirementGroupDetails): CourseRequirement => {
    if (!group) {
        return {
            _uid: generateUUID(),
            requirementId: '',
            requisiteType: '',
            lineKeyNumber: '',
            courses: []
        };
    }
    return {
        _uid: generateUUID(),
        requirementId: group.requirementId,
        requisiteType: group.requisiteType,
        lineKeyNumber: group.lineKeyNumber,
        courses: []
    };
};

export type CourseRequisites = {
    [key in RequisiteGroupType]: RequisiteGrouping[];
};

// parseCoursesFromRequirementGroupDetails pulls out related courses from requisite line details.
// However it also requires passing in the courseId of the parent course in order to exclude
// requisites that target itself. e.g MAT 206.5 requiring itself as a prerequisite, due to the condition
// of requiring MAT 100 or above
export const parseCoursesFromRequirementGroupDetails = (courseId: string, detail: Exclude<RequirementGroupDetails, null>): RequisiteCourse[] => {
    let courses: RequisiteCourse[] = [];
    if (detail.groupLineType === 'CRSE' && detail.course) {
        courses.push({
            courseId: detail.course.courseId,
            subject: detail.course.subject,
            courseNumber: detail.course.courseNumber,
            requisiteType: detail.requisiteType,
        });

        // if lineType is RQ then this means it points to a requirement row which likely has a course list
    } else if (detail.groupLineType === 'RQ') {
        detail.requirements.forEach(req => {
            if (req.courses.length) {

                // Keep in mind difference in structure due to courseOffer when pulling subject and courseNumber
                const validCourses = _.filter(req.courses, 'courseOffer');

                courses = courses.concat(validCourses.map(c => {
                    return {
                        courseId: c.courseId,
                        // TODO: 01/10/22: discovered course lists can contain wild cards so courseOffer can be null.
                        subject: c.courseOffer!.subject,
                        courseNumber: c.courseOffer!.courseNumber,
                        requisiteType: detail.requisiteType,
                    };
                }));
            }
        });
    }
    // Refer to function annotations. Basically prevent courses from requiring themselves.
    _.remove(courses, { courseId });
    return courses;
};

export interface RequirementGroupDetailsWithDepth extends Exclude<RequirementGroupDetails, null> {
    depth: number;
}

// https://stackoverflow.com/a/50702934
export const parseParenthesesDepth = (courseGroup: CourseInfo): NonNullable<RequirementGroupDetails>[] => {
    return parseParentheses(courseGroup.requirementGroupDetails) as NonNullable<RequirementGroupDetails>[];
};

export const parseRequisiteConditionGroups = (courseGroup: CourseGroup | CourseClassWithGroupData | CourseClassExtract): RequirementGroupDetails[][] => {
    const collectionOfParenthesisGroups: RequirementGroupDetails[][] = [];
    let parenthesisGroup: RequirementGroupDetails[] = [];

    let openedParenthesis = false;
    courseGroup.requirementGroupDetails.forEach((detail) => {
        if (!detail) return;

        switch (detail.parenthesis) {
            case '(':
                if (openedParenthesis) {
                    console.error('parenthesis already opened. Did we forget to close?');
                } else {



                    // 10/13/21: another edge case is when the first line detail item is in it's own parenthesis group
                    // then it might not even have any parenthesis at all. In this case we need to just push everything
                    collectionOfParenthesisGroups.push([ ...parenthesisGroup ]);
                    // this is redundant but leaving this here in case we need to refactor and thus want to make it
                    // clear that we need to empty the array
                    parenthesisGroup = [];
                }
                openedParenthesis = true;
                parenthesisGroup = [];
                parenthesisGroup.push(detail);
                break;
            case ')':
                openedParenthesis = false;
                parenthesisGroup.push(detail);
                collectionOfParenthesisGroups.push([ ...parenthesisGroup ]);
                parenthesisGroup = [];
                break;
            case '':
                if (openedParenthesis) {
                    parenthesisGroup.push(detail);

                    // For courses that only have 1 enrollment requirement group "condition",
                    // it's possible that none of their line detail items will use the parenthesis column so
                    // if the Connector is OR or empty string then we want to combine them
                } else if (detail.connect !== 'AND') {
                    parenthesisGroup.push(detail);
                } else {
                    collectionOfParenthesisGroups.push([ detail ]);
                }
                break;
        }
    });
    // See if/else under the parenthesis switch statement where a single enrollment requirement may not
    // make use of the parenthesis column, which means we'll need to handle pushing it.
    if (parenthesisGroup.length) {
        collectionOfParenthesisGroups.push([ ...parenthesisGroup ]);
        parenthesisGroup = [];
    }

    return collectionOfParenthesisGroups;
};

export const parseRequisites = (courseGroup: CourseGroup | CourseClassWithGroupData | CourseClassExtract): CourseRequisites => {
    const requisites: CourseRequisites = {
        PRE: [],
        CO: [],
        AREQ: [],
    };

    const collectionOfParenthesisGroups: RequirementGroupDetails[][] = parseRequisiteConditionGroups(courseGroup);

    // Concat all antirequisites across all requisite groups
    const antirequisiteGrouping: RequisiteGrouping = {
        _uid: generateUUID(),
        requisiteGroupType: 'AREQ',
        requisiteCourses: [],
    };

    collectionOfParenthesisGroups.forEach((pg) => {
        const withoutAntirequisites: RequisiteGrouping = {
            _uid: generateUUID(),
            requisiteGroupType: undefined,
            requisiteCourses: [],
        };

        _.forEach<RequirementGroupDetails>(pg, (detail: RequirementGroupDetails) => {
            if (!detail?.requisiteType) return;

            // First check if detail is antirequisite
            if (detail.requirements) {
                for (let i = 0; i < detail.requirements.length; i++) {
                    if (detail.requirements[i].isAntirequisite) {

                        const courses = parseCoursesFromRequirementGroupDetails(courseGroup.courseId, detail);
                        antirequisiteGrouping.requisiteCourses = antirequisiteGrouping.requisiteCourses.concat(courses);
                        return;
                    }
                }
            }

            // Basically if ANY of the items are PRE then the entire group will be displayed in the prereqs section.
            // Therefore once pgType is PRE, it will remain PRE for the entire iteration.
            if (withoutAntirequisites.requisiteGroupType !== 'PRE' && detail.requisiteType) {
                withoutAntirequisites.requisiteGroupType = detail.requisiteType;
            }

            withoutAntirequisites.requisiteCourses = withoutAntirequisites.requisiteCourses.concat(parseCoursesFromRequirementGroupDetails(courseGroup.courseId, detail));
        });

        if (withoutAntirequisites.requisiteGroupType) {
            // Important: Sorting by requisiteType to ensure we keep any PreCo hybrids in order to show asterisk
            withoutAntirequisites.requisiteCourses = _.chain(withoutAntirequisites.requisiteCourses)
                .orderBy(c => c.subject + parseInt(c.courseNumber) + c.requisiteType)
                .uniqBy('courseId').value();
            requisites[withoutAntirequisites.requisiteGroupType as RequisiteGroupType].push(withoutAntirequisites);
        }
    });

    // make sure to remove duplicates it antirequisite grouping
    antirequisiteGrouping.requisiteCourses = _.uniqBy(antirequisiteGrouping.requisiteCourses, 'courseId');
    if (antirequisiteGrouping.requisiteCourses.length) {
        requisites.AREQ.push(antirequisiteGrouping);
    }


    // remove any groupings that have empty courses
    for (const key in requisites) {
        _.remove(requisites[key as RequisiteGroupType], r => !r.requisiteCourses.length);
    }

    requisites.PRE.forEach(prerequisiteGrouping => {
        let containsPreCoHybrids = false;
        _.forEach(prerequisiteGrouping.requisiteCourses, requisiteCourse => {
            if (requisiteCourse.requisiteType === 'CO') {
                containsPreCoHybrids = true;
                return false;
            }
        });
        prerequisiteGrouping.containsPreCoHybrids = containsPreCoHybrids;
    });

    return requisites;
};

export const parsePreReqs = (courseGroup: CourseGroup | CourseClassWithGroupData | CourseClassExtract, requisiteType: RequisiteType = 'PRE'): CourseRequirement[] => {
    const requirements: CourseRequirement[] = [];


    let requirement: CourseRequirement = generateEmptyRequirement();
    // ensure groups are ordered according to line key number
    const details: RequirementGroupDetails[] = _.cloneDeep(courseGroup.requirementGroupDetails);
    details?.forEach(detail => {
        // Ignore blank requisite types as these are likely Academic Program conditions and not PRE/CO/AREQ
        if (detail?.requisiteType !== requisiteType) return;

        if (!requirement) {
            requirement = generateEmptyRequirement(detail);
        }

        // as long as rq_connect is not AND, we can append course(s) to the same requirement, otherwise we push everything found so far
        // into requirements, and then create a brand new requirement
        if (detail.connect === 'AND') {
            // but only push if there's actually courses to be found
            if (requirement.courses.length) {
                requirements.push(requirement);
            }
            requirement = generateEmptyRequirement(detail);
        }

        // TODO: handle parenthesis zomg -_-

        if (detail.groupLineType === 'CRSE' && detail.course) {
            requirement.courses.push({
                courseId: detail.course.courseId,
                subject: detail.course.subject,
                courseNumber: detail.course.courseNumber,
            });

            // if lineType is RQ then this means it points to a requirement row which likely has a course list
        } else if (detail.groupLineType === 'RQ') {
            detail.requirements.forEach(req => {


                if (req.courses.length) {

                    // Keep in mind difference in structure due to courseOffer when pulling subject and courseNumber
                    const courses = _.filter(req.courses, 'courseOffer');
                    requirement!.courses = requirement!.courses.concat(courses.map(c => {
                        return {
                            courseId: c.courseId,
                            // TODO: 01/10/22: discovered course lists can contain wild cards so courseOffer can be null.
                            subject: c.courseOffer!.subject,
                            courseNumber: c.courseOffer!.courseNumber,
                        };
                    }));
                }
            });
        }
    });

    // now insert the latest working requirement iteration as it's likely we didn't push it if we never encountered AND
    if (requirement?._uid) {
        const existingIndex = _.findIndex(requirements, {_uid: requirement._uid});
        if (existingIndex === -1) {
            requirements.push(requirement);
        } else {
            requirements[existingIndex] = requirement;
        }
    }

    // Make sure to remove any empty requirements
    _.remove(requirements, r => !r.courses.length);

    requirements.forEach(req => {
        req.courses = _.chain(req.courses).orderBy(c => c.subject + parseInt(c.courseNumber)).uniqBy('courseId').value();
    });
    // console.log('parsePreReqs courseGroup: ', courseGroup);
    // console.log('parsePreReqs requirements: ', requirements);
    // TODO: don't forget to order the courses correctly. example snippet below:
    // const courses = _.chain(req.courses).filter('courseOffer').orderBy(c => c.courseOffer.subject + parseInt(c.courseOffer.courseNumber)).value();
    return requirements;
};



// export const parseCorequisiteGrouping = (corequisiteGrouping: RequisiteCourse[]): string => {
//     let courses: RequisiteCourse[] = [];
//     courses = corequisiteGrouping;
//
//     let message = courses.length > 1 ? 'one of the following: ' : '';
//     const courseNames: string[] = courses.map(parseCourseShortName);
//     message += courseNames.join(', ');
//     return message;
// };
//
// export type CoReqsError = {
//     course: CourseClassExtract;
//     unsatisfiedGroupings: RequisiteGrouping[];
//     message: string;
// };

export const validateRequisites = (courseGroup: CourseGroup | CourseClassWithGroupData | CourseClassExtract, enrollments: StudentEnrollment[], shoppingCart: CourseClassExtract[]): RequisiteGrouping[] => {

    // console.log('validateRequisites enrollments: ', enrollments);
    const courseRequisites = parseRequisites(courseGroup);

    const unsatisfiedGroupings: RequisiteGrouping[] = [];

    _.forEach(courseRequisites.PRE, (requisiteGrouping: RequisiteGrouping) => {
        let satisfied = false;
        // console.log('checking requisiteGrouping: ', requisiteGrouping);
        _.forEach(requisiteGrouping.requisiteCourses, (requisiteCourse: RequisiteCourse) => {

            const match = enrollments.find(o => {
                const alreadyCompleted = o.class.courseId === requisiteCourse.courseId && o.lastActionProcessed === 'G';

                let isPreCoHybridAndInCart = false;
                if (requisiteCourse.requisiteType === 'CO') {
                    isPreCoHybridAndInCart = !!shoppingCart.find(o => o.courseId === requisiteCourse.courseId);
                }

                return alreadyCompleted || isPreCoHybridAndInCart;
            });
            if (match) {
                console.log('requisiteGrouping matched!: ', match);
                satisfied = true;
                return false;
            }
        });
        if (!satisfied) {
            unsatisfiedGroupings.push(requisiteGrouping);
        }
    });

    _.forEach(courseRequisites.CO, (requisiteGrouping: RequisiteGrouping) => {
        let satisfied = false;
        _.forEach(requisiteGrouping.requisiteCourses, (requisiteCourse: RequisiteCourse) => {
            const match = shoppingCart.find(o => o.courseId === requisiteCourse.courseId);
            if (match) {
                console.log('requisiteGrouping matched!: ', match);
                satisfied = true;
                return false;
            }
        });
        if (!satisfied) {
            unsatisfiedGroupings.push(requisiteGrouping);
        }
    });

    let notAllowed = false;
    _.forEach(courseRequisites.AREQ, (requisiteGrouping: RequisiteGrouping) => {
        if (notAllowed) return false;
        _.forEach(requisiteGrouping.requisiteCourses, (antirequisiteCourse: RequisiteCourse) => {
            const match = enrollments.find(o => {
                const alreadyCompleted = o.class.courseId === antirequisiteCourse.courseId && o.lastActionProcessed === 'G';
                return alreadyCompleted;
            });
            if (match) {
                notAllowed = true;
                return false;
            }
        });
    });
    if (notAllowed) {
        unsatisfiedGroupings.push(courseRequisites.AREQ[0]);
    }

    return unsatisfiedGroupings;
};

// export const validateCoReqs = (enrollments: StudentEnrollment[], shoppingCart: CourseClassExtract[]): CoReqsError[] => {
//     const coreqsErrors: CoReqsError[] = [];
//
//     _.forEach(shoppingCart, selectedClass => {
//         const allUnsatisfiedGroupings: RequisiteGrouping[] = [];
//
//         const unsatisfiedRequisiteGroupings: RequisiteGrouping[] = validateRequisites(selectedClass, enrollments!, shoppingCart);
//         const unsatisfiedCorequisites: RequisiteGrouping[] = _.remove<RequisiteGrouping>(unsatisfiedRequisiteGroupings, o => o.requisiteGroupType === 'CO');
//
//         // redundant just for brevity
//         const unsatisfiedRequisiteGroupingsWithoutCoreqs: RequisiteGrouping[] = unsatisfiedRequisiteGroupings;
//
//         const preCoHybridGroupings: RequisiteGrouping[] = _.filter<RequisiteGrouping>(unsatisfiedRequisiteGroupingsWithoutCoreqs, o => !!o.containsPreCoHybrids);
//         // But modify the allowable requisite courses to only allow courses that have the optional corequisite type
//         _.forEach(preCoHybridGroupings, grouping => {
//             _.remove(grouping.requisiteCourses, c => c.requisiteType !== 'CO');
//         });
//
//         const unsatisfiedCorequisitesAndHybrids = _.concat(unsatisfiedCorequisites, preCoHybridGroupings);
//
//         if (unsatisfiedCorequisitesAndHybrids.length) {
//             coreqsErrors.push({
//                 course: selectedClass,
//                 message: '',
//                 unsatisfiedGroupings: unsatisfiedCorequisitesAndHybrids
//
//             });
//         }
//     });
//
//     return coreqsErrors;
// };

// export const parseUnsatisfiedRequisiteReasons = (requisiteGroupings: RequisiteGrouping[]): string[] => {
//     const reasons: string[] = [];
//     requisiteGroupings.forEach((requisiteGrouping) => {
//         let prefix = '';
//         if (requisiteGrouping.requisiteGroupType === 'PRE') {
//             prefix = 'Requires one of the following prerequisites:';
//         } else if (requisiteGrouping.requisiteGroupType === 'AREQ') {
//             prefix = 'Not open to students who have completed:';
//         }
//
//         const msg = `${prefix} ${requisiteGrouping.requisiteCourses.map<string>(c => c.subject + ' ' + c.courseNumber).join(', ')}`;
//         reasons.push(msg);
//     });
//
//     return reasons;
// };

export const formatRequirementConditionOperator = (op: RequirementConditionOperator): string => {
    switch (op) {
        case '':
            return '';
        case 'IN':
            return 'In';
        case 'EQ':
            return 'Equal to';
        case 'GE':
            return 'Greater or Equal to';
        case 'NE':
            return 'Not Equal to';
        case 'LT':
            return 'Less than';
        case 'LE':
            return 'Less or Equal to';
        case 'NI':
            return 'Not in';
        case 'GT':
            return 'Greater than';
    }
};

export const formatStudentGroupCondition = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine): string => {
    if (detail.conditionCode !== 'GRP' && detail.conditionCode !== 'GRS') {
        console.error('Requirement condition invalid!');
        return '';
    }

    let description;
    if ('institution' in detail) {
        description = parseStudentGroupDescription(detail.institution, detail.conditionData);
    } else {
        description = parseStudentGroupDescrptionWithoutInstitution(detail.conditionData);
    }

    return `${[ detail.conditionCode ]} Student Group is ${formatRequirementConditionOperator(detail.conditionOperator)} [${detail.conditionData}] ${description}`;
};

export const formatTestScoreCondition = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine): string => {
    return `[${detail.testId}] ${detail.testComponent} is ${formatRequirementConditionOperator(detail.conditionOperator)} ${detail.score}`;
};

export const formatCumulativeGradeAverageCondition = (detail: RequirementGroupDetailsWithDepth | RequirementLine | ConditionLine): string => {
    return `Cumulative Grade Average is ${formatRequirementConditionOperator(detail.conditionOperator)} ${detail.conditionData}`;
};

export const formatRequirementGroupCondition = (detail: RequirementGroupDetailsWithDepth): string => {
    const s = '';

    if (detail.conditionCode === 'GRP' || detail.conditionCode === 'GRS') {
        return formatStudentGroupCondition(detail);

    } else if (detail.conditionCode === 'TST') {
        return formatTestScoreCondition(detail);
    }

    return s;
};

export const formatMilestoneCondition = (condition: ConditionDetail): string => {
    let s = `[${condition.milestone}] ${condition.milestoneTitle}`;
    if (condition.milestoneLevel) {
        s += `- ${condition.milestoneLevel}`;
    }
    return s;
};

export const parseDynamicConditions = (requirementLine: RequirementLine | Exclude<RequirementGroupDetails, null>): Condition[] => {
    const conditionDetailsSortedUnique: ConditionDetail[] = _.chain<ConditionDetail>(requirementLine.conditionDetails)
        .orderBy([ 'lineSequence', 'lineDetailSequence' ], [ 'asc', 'desc' ])
        .uniqBy('lineSequence')
        .value();

    const result: Condition[] = _.chain<Condition>([])
        .concat(requirementLine.conditions, conditionDetailsSortedUnique)
        .orderBy('lineSequence')
        .value();

    _.remove(result, {processType: 'UPRG'});
    return result;
};
